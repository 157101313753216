import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MarqueeWrapper } from './styles';

export const Marquee = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let marquee = document.querySelectorAll('.clipped-text');

    marquee.forEach(el => {
      let rate = 100;
      let distance = el.clientWidth;
      let style = window.getComputedStyle(el);
      let marginRight = parseInt(style.marginRight) || 0;
      let totalDistance = distance + marginRight;
      let time = totalDistance / rate;
      let container = el.parentElement;

      gsap.to(container, time, {
        repeat: -1,
        x: '-' + totalDistance,
        ease: "none"
      });
    });


  }, []);
  return (
    <MarqueeWrapper>
      <div className="hero-marquee">
        <div className="marquee">
          <span className="clipped-text">
            VISA VISA VISA VISA VISA VISA VISA VISA VISA
          </span>
          <span className="clipped-text">
            VISA VISA VISA VISA VISA VISA VISA VISA VISA
          </span>
        </div>
        <div className="marquee">
          <span className="clipped-text">
            NAVIGATE NAVIGATE NAVIGATE NAVIGATE NAVIGATE
          </span>
          <span className="clipped-text">
            NAVIGATE NAVIGATE NAVIGATE NAVIGATE NAVIGATE
          </span>
        </div>
        <div className="marquee">
          <span className="clipped-text">
            EXPERIENCES EXPERIENCES EXPERIENCES EXPERIENCES
          </span>
          <span className="clipped-text">
            EXPERIENCES EXPERIENCES EXPERIENCES EXPERIENCES
          </span>
        </div>
      </div>
    </MarqueeWrapper>
  )
}

export default Marquee;