import create from 'zustand';

export const [appState] = create(set => ({
  theme: "light",
  overlay: false,
  closeButton: false,
  navigation: false,
  menu: false,
  library: false,
  downloads: false,
  notifications: false,
  profile: false,
  bookmark: false,
  partnerships: false,
  newsroom: false,
  currentArticle: 0,
  setMenuTheme: (theme) =>
    set(state => ({
      theme: theme,
    })),
  showNavigation: () =>
    set(state => ({
      navigation: true,
    })),
  hideNavigation: () =>
    set(state => ({
      navigation: false,
    })),
  showOverlay: () =>
    set(state => ({
      overlay: true,
    })),
  closeOverlay: () =>
    set(state => ({
      overlay: false,
      closeButton: false,
      menu: false,
      library: false,
      downloads: false,
      notifications: false,
      profile: false,
      bookmark: false,
      partnerships: false,
      newsroom: false
    })),
  showMenu: () =>
    set(state => ({
      overlay: true,
      closeButton: true,
      navigation: true,
      menu: true,
      library: false,
      downloads: false,
      notifications: false,
      profile: false,
      bookmark: false,
      partnerships: false,
      newsroom: false
    })),
  showProfile: () =>
    set(state => ({
      overlay: true,
      closeButton: true,
      navigation: true,
      menu: false,
      library: false,
      downloads: false,
      notifications: false,
      profile: true,
      bookmark: false,
      partnerships: false,
      newsroom: false

    })),
  showPartnerships: () =>
    set(state => ({
      overlay: true,
      closeButton: false,
      navigation: false,
      menu: false,
      library: false,
      downloads: false,
      notifications: false,
      profile: false,
      bookmark: false,
      partnerships: true,
      newsroom: false

    })),
  hidePartnerships: () =>
    set(state => ({
      overlay: false,
      closeButton: false,
      navigation: false,
      menu: false,
      library: false,
      downloads: false,
      notifications: false,
      profile: false,
      bookmark: false,
      partnerships: false,
      newsroom: false

    })),
  showNewsroom: () =>
    set(state => ({
      overlay: true,
      closeButton: false,
      navigation: false,
      menu: false,
      library: false,
      downloads: false,
      notifications: false,
      profile: false,
      bookmark: false,
      partnerships: false,
      newsroom: true
    })),
  hideNewsroom: () =>
    set(state => ({
      overlay: false,
      closeButton: false,
      navigation: false,
      menu: false,
      library: false,
      downloads: false,
      notifications: false,
      profile: false,
      bookmark: false,
      partnerships: false,
      newsroom: false
    })),
  setCurrentArticle: (index) =>
    set(state => ({
      currentArticle: index
    }))
}))