import create from 'zustand';

export const [userState] = create( set => ({
	token: false,
	setToken: (token) => 
		set(state =>({
			token: token,
		})),
	idle: false,
	setIdle: (isIdle) => 
		set(state =>({
			idle: isIdle,
		})),
	profile: {},
	setProfile: (profile) => 
		set(state =>({
			profile: profile,
		})),
	bookmarks: {},
	setBookmarks: (bookmarks) => 
		set(state =>({
			bookmarks: bookmarks,
		})),
}))