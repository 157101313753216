import React, { useRef, useEffect } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { productState } from 'Stores/Product';
import { Wrapper, Device, Loop } from './styles';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Reveal from 'Components/Product/Reveal';

import DropZone from './DropZone';
import DropBox from './DropBox';

import phone from './assets/orange-phone-screen.png'
import phoneIncorrect from './assets/red-phone-screen.png';
import phoneCorrect from './assets/green-phone-screen.png';
import issuerLogo from './assets/logo-fdnb.png';

import merchantLogo from './assets/logo-electronic-store.png';

import loop from './assets/loop_all.gif';

function MobilePayments() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const {
    secure3dAuthentication,
    setSecure3dAuthentication
  } = productState();

  useEffect(() => {
    const element = ref.current;
    setSecure3dAuthentication(false);

    gsap.to(
      element.querySelector(".section-1"), {
      scrollTrigger: {
        trigger: element.querySelector(".section-1"),
        start: "top top",
        end: "+=600%",
        pin: true,
        scrub: true
      },
    }
    );

    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true
      }
    })

      .from(".DeviceContainer", { y: "100%" }, "phone-group")
      .from(".risk-reveal", { x: "-300%" }, "phone-group")
      .from(".loop__item", { opacity: 0 })
      .to(".loop__item", { opacity: 0 }, "second-out")
      .to(".risk-reveal", { x: "-300%" }, "second-out")
      .from(".effects", { x: "-300%" }, "third-in")
      .from(".liable", { x: "-300%" }, "third-in")
      .from(".drag-section", { opacity: 0 }, "third-in")
      .from(".DeviceContainer__dropArea", { opacity: 0 }, "third-in")
      .to(".effects", { x: "-300%", delay: 1 }, "third-out")
      .to(".liable", { x: "-300%", delay: 1 }, "third-out")
      .to(".drag-section", { opacity: 0, delay: 1 }, "third-out")
      .to(".DeviceContainer", { y: "-50%", delay: 1 }, "third-out")

  }, []);


  return (
    <Wrapper id="mobile-payments" ref={ref}>

      <Section light>

        <SectionBG light  onEnter="dark" onEnterBack="dark" onLeaveBack="dark"/>

        <Scene Height="100vh" className="section-1"  onEnter="dark" onEnterBack="dark" onLeaveBack="dark">

          <div className="container">


            <DndProvider backend={HTML5Backend}>

              <div className="scene">

                <div className="risk-reveal">
                  <Reveal
                    title="This is driving a move to two-factor authentication, as well as requirement for risk-based authentication"
                    content={`<p>Traditional, or “static” authentications reuse a specific authenticator such as a password which does not change. This method relies on the difficulty of a fraudster guessing the authenticator value to provide protection from fraud.</p><p>Risk-based authentication is flexible and dynamic. Merchants and issuers exchange greater contextual data (such as the device used, the geographical location, and previous purchasing behaviour) to verify a cardholder’s identity – avoiding the need for every shopper to actively authenticate with a password unless the transaction is considered high-risk.</p><p>Visa Secure supports two-factor authentication for high risk transactions, which requires the customer to provide at least two of the following:</p><ul> <li>Knowledge (something they know such as a password or PIN)</li><li>Possession (something they have such as a mobile phone, card reader or other device evidenced by a one-time password)</li><li>Inherence (something they are which uses biometrics such as facial recognition or a fingerprint)</li></ul>`}
                  />
                </div>

                <Device className="DeviceContainer">

                  {secure3dAuthentication === "Merchant" ?
                    <img className="DeviceContainer__device" src={phoneCorrect} alt="" />
                    : ""}

                  {secure3dAuthentication === "Issuer" ?
                    <img className="DeviceContainer__device" src={phoneIncorrect} alt="" />
                    : ""}

                  <img className="DeviceContainer__device" src={phone} alt="" />


                  <Loop className="DeviceContainer__loop loop">
                    <img className="loop__item" src={loop} alt="" />
                  </Loop>

                  <div className="DeviceContainer__dropArea">

                    <DropZone />

                    {secure3dAuthentication === "Merchant" ?
                      <>
                        <h2>Correct!</h2>
                        <p>Without secure authentication in place,the liability for transactions lies with the merchant</p>
                      </>
                      : ""}

                    {secure3dAuthentication === "Issuer" ?
                      <>
                        <h2>Incorrect!</h2>
                        <p>Unless secure authentication is in place,the merchant is liable, not the issuer</p>
                      </>
                      : ""}

                  </div>

                </Device>

                <div className="drag-section">

                  <DropBox image={issuerLogo} name="Issuer" />
                  <DropBox image={merchantLogo} name="Merchant" />

                </div>

                <div className="effects">
                  So secure authentication has become very important.
                  </div>
                <div className="liable">
                  If it's not used, do you know who is responsible should the transaction be fraudulent? Choose you answer below:
                  </div>

              </div>

            </DndProvider>

          </div>

        </Scene>

      </Section>


    </Wrapper>
  );
}

export default MobilePayments;