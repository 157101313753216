import styled from 'styled-components';

export const Nav = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	padding: 30px;
`;

export const NavList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
`;

export const NavItem = styled.li`

`;

export const NavIcon = styled.div`
	display: block;
	width: 40px;
	height: 40px;
	margin-right: 15px;
	cursor: pointer;

  .icon-background {
		fill: transparent;
	}
  .icon-circle {
    fill: #FFFFFF;
  }
	.icon-shape {
		fill: #FFFFFF;
	}

  ${props => props.isActive && `
    .icon-background {
      fill: #FFFFFF;
    }
    .icon-circle {
      fill: #FFFFFF;
    }
    .icon-shape {
      fill: #F7B600;
    }
  `}
`;
