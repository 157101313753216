import styled from 'styled-components';

export const FourOhFour = styled.div`
	background: #f5f5f8;
	height: 100vh;
	display: block;
	color: #fff;
	position: absolute;
	width: 100vw;
`;

export const FourOhFourContent = styled.div`
width:  50%;
margin-left: 24px;

	h4 {
		font-size: calc( 40px + (60 - 40) * (100vw - 400px) / (1400 - 400));
		font-weight: 300;
		margin: 20vh 0 48px;
	}

	a {
		text-decoration: underline;
	}

	h4, p {
		color: #101a4d;
	}
`;


export const FourOhFourImage = styled.div`
position: absolute;
right: 40vh;
`;
