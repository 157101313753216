import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { appState } from 'Stores/App';
import Navigation from './Navigation';
import Group from './Group';
import Introduction from './Components/Introduction';
import Audience from './Components/Audience';
import Create from './Components/Create';
import Pay from './Components/Pay';
import Manage from './Components/Manage';
import Engage from './Components/Engage';
import ThankYou from './Components/Thankyou';
import Benefits from './Components/Benefits';
import WhereNext from './Components/WhereNext';
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from '../../constants';

import {
  ProductWrapper,
  CloseIcon
} from './styles';


function SmarterPersonalFinance() {
  const history = useHistory();
  const {
    theme,
    navigation,
    showNavigation,
    overlay: hasOverlay,
  } = appState();

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }
  }, [navigation, showNavigation]);

  const returnToModule = (e) => {
    history.push("/module/digital-account");
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.SMARTER_PERSONAL_FINANCE}</title>
      </Helmet>
      <ProductWrapper hasOverlay={hasOverlay}>

        <CloseIcon onClick={(e) => returnToModule(e)} theme={theme} />

        <Navigation />

        <Group id="macro-content">
          <Introduction />
        </Group>

        <Group id="audience">
          <Audience />
        </Group>

        <Group id="create">
          <Create />
        </Group>

        <Group id="pay">
          <Pay />
        </Group>

        <Group id="manage">
          <Manage />
        </Group>

        <Group id="engage">
          <Engage />
        </Group>

        <Group id="thankyou">
          <ThankYou />
        </Group>

        <Group id="benefits">
          <Benefits />
        </Group>

        <Group id="where-next">
          <WhereNext />
        </Group>

        <div id="end"></div>

      </ProductWrapper>
    </>
  );
}

export default SmarterPersonalFinance;