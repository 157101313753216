import styled from 'styled-components';

export const HeroWrapper = styled.div`
  height: 100vh;
  width: 100%;
  top: 0;
  position: absolute;
  overflow: hidden;
  color: #fff;
  display: flex;
  align-items: center;
`;

export const HeroImage = styled.img`
	position: ${props => props.type === "secondary" ? "absolute" : "fixed"};
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate3d(-50%, -50%, 0);
`;
