import styled from 'styled-components';

export const Wrapper = styled.div`

  position: relative;
  z-index: 1;

  .scene {
  	position: relative;
  	width: 100%;
  	height: 100vh;
  }

  .risk-reveal {
    position: absolute;
    top: 30%;
    left: 40px;
    width: 40%;

    .reveal__title {
      color: #101a4d;
    }

  }

  .liable {
    position: absolute;
    top: 30%;
    left: 40px;
    width: 40%;
    font-size: calc( 16px + (22 - 16) * (100vw - 400px) / (1400 - 400));
    font-weight: 300;
  }

  .effects {
    position: absolute;
    top: 18%;
    left: 40px;
    width: 40%;
    font-size: calc( 24px + (32 - 24) * (100vw - 400px) / (1400 - 400));
    font-weight: 300;
  }

  .drag-section {
    position: absolute;
    top: 50%;
    left: 40px;
    width: 40%;
    display: flex;
    justify-content: center;
    color: #101a4d;
    font-size: calc( 20px + (28 - 20) * (100vw - 400px) / (1400 - 400));
    img {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
  }


`;


export const Device = styled.div`

  position: absolute;
  left: 50%;
  width: 50%;
  bottom: -30%;
  max-height: 130%;

  .DeviceContainer__device {
    max-width: 100%;
    max-height: 100%;
  }

  .DeviceContainer__dropArea {
    position: absolute;
    top: 20%;
    left: 30%;
    width: 40%;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.4;

    > div {
      position: relative;
    }

  }

`;

export const Loop = styled.div`
  position: absolute;
  top: 25%;
  left: 30%;
  width: 40%;

  .loop__item {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
  }

`;


export const DropLogo = styled.img`
  // width: calc(15vw + 4px);
  // height: calc(15vw + 4px);
  max-width: none;
  width: calc(100% + 4px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

`;

