import styled from 'styled-components';
import image from '../Lobby/assets/background_new.jpg';
import { Link } from 'react-router-dom';

export const LobbyWrapper = styled.div`
  ${props => props.hasOverlay && `
    opacity: 0.15;
    pointer-events: none;
  `}
`;

export const WelcomeScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  color: #fff;
  font-size: 30px;
  left: 50%;
  transform: perspective(800px) rotateY(26deg);
  text-transform: uppercase;
  font-weight: 200;
  opacity: 0.9;
  padding: 0 10px 0 5px;
`;

export const LobbyBG = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  margin-bottom: -100vh;
  overflow: hidden;
`;


export const ModuleLink = styled(Link)`

`;

