import styled from 'styled-components';
import { getWidth, getYPos, getXPos, getYPosBottom } from '../../../helpers';

export const PositionOne = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(104)};
  left: ${getXPos(188)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(130)});
  text-align: center;
`;

export const PositionTwo = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(104)};
  left: ${getXPos(665)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(130)});
  text-align: center;
`;

export const PositionThree = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(104)};
  left: ${getXPos(1150)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(130)});
  text-align: center;
`;

export const PositionFour = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(104)};
  left: ${getXPos(1630)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(130)});
  text-align: center;
`;

export const PositionFive = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(480)};
  left: ${getXPos(0)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(220)});
  text-align: center;
`;

export const PositionSix = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(480)};
  left: ${getXPos(480)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(220)});
  text-align: center;
`;

export const PositionSeven = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(480)};
  left: ${getXPos(960)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(220)});
  text-align: center;
`;

export const PositionEight = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(480)};
  left: ${getXPos(1440)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPosBottom(220)});
  text-align: center;
`;

export const PositionNine = styled.div`
  position: absolute;
  height: 100vh;
  width: ${getWidth(1280)};
  left: ${getXPos(0)};
  top: 100%;
  bottom: 0;
  transform: translateY(${getYPos(150)});
  text-align: left;
  padding-left: ${getXPos(91)};
`;

export const NavigateTo = styled.div`
  cursor: pointer;
`;
