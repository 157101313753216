import React from 'react';
import axiosConfig from '../../../Services/axiosConfig';
import Cookies from 'js-cookie';
import { useHistory, Redirect } from "react-router-dom";
import UpdateProfile from 'Components/Auth/UpdateProfile';
import ResetPassword from 'Components/Auth/PasswordReset/Profile/PasswordReset';
import { getToken } from 'Services/auth';

function Profile() {
    const history = useHistory();
    const logOut = () => {
        axiosConfig.post(`/identity/auth/logout`)
            .then((response) => {
                let statusCode = response.status;
                switch (statusCode) {
                    case 200:
                        Cookies.remove('__session');
                        Cookies.remove('__refresh-token')
                        history.push("/login");
                        break;
                    case 400:
                        console.error(response.data.errorMessages);
                        break;
                    case 401:
                        <Redirect to="login" />
                        break;
                    default:
                        console.error(response.data.errorMessages);
                }
            }, (error) => {
                console.error(error);
            });
    }

    // FIXME: change over to styled components
    return (
        <div className="menu">
            <div className="menu__wrapper">
                <div className="menu__intro">
                    <h2 className="menu__title">Your Profile</h2>
                    <div className="menu__logout">
                        <span className="form-btn" onClick={logOut}>Log out</span>
                    </div>
                </div>
                <div className="menu__columns">
                    <div className="menu__column">
                        <h3 className="menu__subtitle">Update personal details</h3>
                        <UpdateProfile />
                    </div>
                    <div className="menu__column">
                        <h3 className="menu__subtitle">Reset Password</h3>
                        <ResetPassword />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
