import React from 'react';
import Theme from '../../../../Theme';
import SlideOne from './SlideOne';

function WhereNext() {
    return (
        <Theme onEnter="light" onEnterBack="light" onLeaveBack="light">
            <SlideOne />
        </Theme>
    );
}

export default WhereNext;
