import styled from 'styled-components';
import image from '../Mezzanine/assets/mezz_bg.jpg';

export const MezzanineWrapper = styled.div`
  ${props => props.hasOverlay && `
    opacity: 0.15;
    pointer-events: none;
  `}
`;

export const MezzanineBG = styled.div`
  position: relative;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  margin-bottom: -100vh;
  overflow: hidden;
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 30px;
	right: 30px;
	fill: #fff;
	width: 40px;
	height: 40px;
	cursor: pointer;
  z-index: 1;
`;

export const ScrollDownButton = styled.div`
	position: absolute;
	bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
	fill: #fff;
	width: 40px;
	height: 40px;
	cursor: pointer;
  z-index: 1;
`;

export const MezzProjectsTitle = styled.h1`
  position: absolute;
  top: 20%;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 300;
  color: #FFFFFF;
  font-size: calc( 44px + (74 - 44) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  line-height: 1;
`;

export const MezzProjectsIntro = styled.p`
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  font-weight: 200;
  color: #FFFFFF;
  font-size: calc( 18px + (20 - 18) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  text-align: center;
  line-height: 1.4;
`;

export const MezzProjectsContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`;

export const MezzProjectsThumbnailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 20px;
  grid-auto-flow: row;
  position: absolute;
  top: 50%;
  width: 80%;
`;

export const MezzProjectsThumbnail = styled.div`
  position: relative;
  > img {
    width: 100%;
  }
`;

export const MezzProjectsThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0F0F0E;
  opacity: 0.75;
  display: flex;
  align-content: center;
`;

export const MezzProjectsThumbnailOverlayDescription = styled.div`
  color: white;
  text-align: center;
  padding: 10px;
`;

export const MezzProjectsThumbnailText = styled.span`
  position: absolute;
  left: 5%;
  bottom: 5%;
  color: white;
  font-size: calc( 12px + (20 - 12) * (100vw - 400px) / (1400 - 400));
`;

export const MezzProjectContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const MezzProjectContent = styled.div`
  position: absolute;
  top: 33%;
  left: 20%;
  transform: translateX(-10%);
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-auto-flow: row;
  align-items: center;
  gap: 0 20px;
`;

export const MezzProjectVideo = styled.div`
  > img {
    width: 100%;
  }
`;

export const MezzProjectVideoDescription = styled.p`
  color: white;
  font-size: calc( 12px + (20 - 12) * (100vw - 400px) / (1400 - 400));
  line-height: 1.5
`;