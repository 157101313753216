import React, { useRef, useEffect } from 'react';
import Theme from '../../../../../Theme';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Background from '../../../Background';
import image from './assets/visa_spf_europe-map.jpg';
import imageBlueBackground from './assets/blue-background.png';
import { animationPause } from '../../../animations';
import { productState } from 'Stores/Product';

import DropZone from './DropZone';
import DropBox from './DropBox';

import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
  TypographyHeading2,
  TypographyQuestion
} from '../../../styles';


import {
  CANVAS_HEIGHT,
  SLIDE_DURATION,
} from '../../../constants';

function SlideTwo() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'introduction__slide-2__animation';

  const {
    setSmarterPersonalFinanceAnswer
  } = productState();

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=400%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
        onUpdate: (self) => {
          setSmarterPersonalFinanceAnswer(false);
        },
      }
    })

    timeline

      .from(`#${ID}-text2`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: `${SLIDE_DURATION}` }, "-=30")
      .to(`#${ID}-text2`, { y: `-${CANVAS_HEIGHT}`, duration: `${SLIDE_DURATION}` }, "+=30")

      .from(`#${ID}-text3`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: `${SLIDE_DURATION}` }, "-=30")
      .to(`#${ID}-text3`, { y: `-${CANVAS_HEIGHT}`, duration: `${SLIDE_DURATION}` }, "+=30")

      .from(`#${ID}-text4`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: `${SLIDE_DURATION}` }, "-=30")

      .add(animationPause());

  }, []);

  return (
    <Theme onEnter="dark" onEnterBack="dark" onLeaveBack="dark">
      <Wrapper ref={ref}>
        <Section light>
          <SectionBG light />
          <Scene Height="100vh" className={`${ID}-scene`}>
            <div className="container">
              <DndProvider backend={HTML5Backend}>
                <div className="scene-content">

                  <Background image={image} />

                  <Background image={imageBlueBackground} />

                  <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid" style={{ position: 'absolute', top: '0' }}>

                    <g id={`${ID}-text1`}>
                      <foreignObject x="39" y="190" width="100%" height="100%">
                        <TypographyHeading2 id={`${ID}-name`}>WHICH COUNTRIES ARE LEADING</TypographyHeading2>
                        <TypographyHeading2 id={`${ID}-name`}>THE WAY IN EUROPE?</TypographyHeading2>
                      </foreignObject>
                    </g>

                    <g id={`${ID}-text2`}>
                      <foreignObject x="39" y="300" width="100%" height="100%">

                        <div id={`${ID}-quiz-1`}>
                          <TypographyQuestion style={{ marginTop: '2em' }} id={`${ID}-name`}>Which country has pledged to be the worlds</TypographyQuestion>
                          <TypographyQuestion id={`${ID}-name`}>first cashless society by 2023?</TypographyQuestion>

                          <div style={{ display: 'flex', marginTop: '2em' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '3em', marginRight: '2em' }}>
                              <div style={{ marginBottom: '1em', marginRight: '2em' }}>
                                <DropBox name="Sweden" answer="y" />
                              </div>
                              <div style={{ marginBottom: '1em', marginRight: '2em' }}>
                                <DropBox name="France" answer="n" />
                              </div>
                              <DropBox name="Ireland" answer="n" />
                            </div>
                            <DropZone answerSnippet="" />
                          </div>
                        </div>
                      </foreignObject>
                    </g>

                    <g id={`${ID}-text3`}>
                      <foreignObject x="39" y="300" width="100%" height="100%">

                        <div id={`${ID}-quiz-2`}>
                          <TypographyQuestion style={{ marginTop: '2em' }} id={`${ID}-name`}>Which Country has the highest</TypographyQuestion>
                          <TypographyQuestion id={`${ID}-name`}>debit card ownership in Europe?</TypographyQuestion>

                          <div style={{ display: 'flex', marginTop: '2em' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '3em', marginRight: '2em' }}>
                              <div style={{ marginBottom: '1em', marginRight: '2em' }}>
                                <DropBox name="United Kingdom" answer="n" />
                              </div>
                              <div style={{ marginBottom: '1em', marginRight: '2em' }}>
                                <DropBox name="Belgium" answer="n" />
                              </div>
                              <DropBox name="Netherlands" answer="y" />
                            </div>
                            <DropZone answerSnippet="Netherlands (99% of Adults)" />
                          </div>
                        </div>
                      </foreignObject>
                    </g>

                    <g id={`${ID}-text4`}>
                      <foreignObject x="39" y="300" width="100%" height="100%">

                        <div id={`${ID}-quiz-2`}>
                          <TypographyQuestion style={{ marginTop: '2em' }} id={`${ID}-name`}>Which country has the most</TypographyQuestion>
                          <TypographyQuestion id={`${ID}-name`}> online banking users in Europe?</TypographyQuestion>

                          <div style={{ display: 'flex', marginTop: '2em' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '3em', marginRight: '2em' }}>
                              <div style={{ marginBottom: '1em', marginRight: '2em' }}>
                                <DropBox name="Sweden" answer="n" />
                              </div>
                              <div style={{ marginBottom: '1em', marginRight: '2em' }}>
                                <DropBox name="Estonia" answer="n" />
                              </div>
                              <DropBox name="Iceland" answer="y" />
                            </div>
                            <DropZone answerSnippet="Iceland (96% of Adults)" />
                          </div>
                        </div>
                      </foreignObject>
                    </g>

                  </svg>

                </div>
              </DndProvider>
            </div>
          </Scene>
        </Section>
      </Wrapper>
    </Theme >
  );
}

export default SlideTwo;
