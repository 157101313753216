import styled from 'styled-components';

export const Wrapper = styled.div`

 .where_heading {
 	text-align: center;
 	margin-bottom: 50px;
 	font-size: calc( 30px + (40 - 30) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
	color: #fff;
 }

 .row {
 	display: flex;
 	margin-left: -15px;
 	margin-right: -15px;
 }

 .title {
	font-size: calc( 18px + (30 - 18) * (100vw - 400px) / (1400 - 400));
   font-weight: 300;
}

 .col {
 	width: 33%;
 	padding-left: 15px;
 	padding-right: 15px;
 	text-align: center;

 	.btn {
 		background-color: #F7B600;
		color: #101a4d;
		display: inline-block;
		border-radius: 2em;
		padding: 1em 3em;
		line-height: 1.2;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 400;
		cursor: pointer;
		position: relative;
		z-index: 2;
		text-decoration: none;
		border: none;
 	}

 }

 .scene-container {
	 width: 100vw;
 }

`;
