import React from 'react';
import Theme from '../../../../Theme';
import SlideOne from './SlideOne';
import SlideTwo from './SlideTwo';
import SlideThree from './SlideThree';
import SlideFour from './SlideFour';
import SlideFive from './SlideFive';

function Manage() {
    return (
        <Theme onEnter="light" onEnterBack="light" onLeaveBack="light">
            <SlideOne />
            <SlideTwo />
            <SlideThree />
            <SlideFour />
            <SlideFive />
        </Theme>
    );
}

export default Manage;