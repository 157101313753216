import styled from 'styled-components';

export const Wrapper = styled.div`

  background: #000;
  position: relative;
  z-index: 1;

  .section-1 {

  	.container {
  		background-color: rgba(0, 0, 0, 0.5);
  	}

  }

  .scene {
  	position: relative;
  	width: 100%;
  	height: 100%;
  }

  .scene-2 {
  	background-color: #101a4d;
  	position: absolute;
  	top: 0;
  	right: 0;
  }

  .online-growth {
  	position: absolute;
  	top: 15%;
  	right: 80px;
	color: #F7B600;
  	font-weight: 300;
  	font-size: calc( 30px + (40 - 30) * (100vw - 400px) / (1400 - 400));
  	width: 40%;
  }

  .growthLockup {
  	position: absolute;
  	top: 55%;
  	left: 50%;
  	transform: translate(-50%, -50%);
  	width: 100%;
  	text-align: center;
  }

  .growth-number {
  	display: flex;
  	align-items: flex-start;
  	justify-content: center;
  	font-weight: bold;
  	line-height: 1;
  	z-index: 2;
  	width: 100%;
  	position: relative;
  	z-index: 1;
  }

  .growth-number__unit {
  	font-size: calc( 60px + (120 - 60) * (100vw - 400px) / (1400 - 400));
  	margin-top: 0.2em;
  	color: #F7B600;
  }

  .growth-number__value {
  	font-size: calc( 200px + (350 - 200) * (100vw - 400px) / (1400 - 400));
    letter-spacing: -0.07em;
  }

  .billion {
  	color: #F7B600;
  	font-weight: 600;
  	font-size: calc( 100px + (150 - 100) * (100vw - 400px) / (1400 - 400));
  }

  .billion__1 {
  	position: absolute;
  	z-index: 0;
  	width: 100%;
  	top: 20%;
  	left: 0;
  }

  .billion__2 {
  	position: absolute;
  	width: 100%;
  	bottom: -15%;
  	left: 0;
  	padding-left: 10%;
  }

  .legal {
  	position: absolute;
  	bottom: 0;
  	left: 0;
  	width: 100%;
  	text-align: center;
  	padding: 20px;
  	font-size: 12px;
  	color: #F7B600;
  	font-weight: 400;
  }


  .central-title {
  	text-align: center;
  	width: 100%;
  	height: 100%;
  	left: 0;
  	top: 50%;
  	transform: translate(0, -50%);
  	position: absolute;
  	color: #F7B600;
  	font-size: calc( 50px + (70 - 50) * (100vw - 400px) / (1400 - 400));
  	line-height: 1;
  	padding-left: 15%;
  	padding-right: 15%;
  	font-weight: 300;
  	display: flex;
  	align-items: center;
  }


  .leading-to {
  	position: absolute;
  	width: 111%;
  	top: 50%;
  	transform: translate(0, -50%);
  	padding: 40px;
  	display: flex;
  	align-items: flex-start;
  	justify-content: space-between;
  }

  .leading-to__start {
  	font-weight: 300;
  	color: #F7B600;
	font-size: calc( 50px + (60 - 50) * (100vw - 400px) / (1400 - 400));
  	display: flex;
  	align-items: flex-start;
	align-self: center;
	text-align: center;
  }

  .leading-to__stat {
  	display: flex;
  	align-items: flex-start;
	margin-left: 200px;
  }

  .statLockup {
  	position: relative;
  }

  .statLockup__value {
  	font-size: calc( 250px + (350 - 250) * (100vw - 400px) / (1400 - 400));
  	font-weight: bold;
  	line-height: 1;
  }

  .statLockup__unit {
  	color: #F7B600;
  	font-size: calc( 60px + (80 - 60) * (100vw - 400px) / (1400 - 400));
  	font-weight: bold;
  	margin-top: 0.3em;
  }

  .statLockup__details {
  	position: absolute;
  	top: 100%;
  	left: 0;
  	width: 100%;
  	text-align: center;
	  color: #F7B600;
	  font-size: calc( 20px + (30 - 20) * (100vw - 400px) / (1400 - 400));
	  font-weight: 300;
  }

  .leading-to__end {
  	position: absolute;
  	bottom: 20%;
  	left: 100%;
  	color: #F7B600;
  	font-size: calc( 80px + (100 - 80) * (100vw - 400px) / (1400 - 400));
  	line-height: 1;
  	font-weight: 300;
  }

  .authenticating {
  	position: absolute;
  	right: 80px;
  	width: 35%;
  	top: 0;
  	height: 100vh;
  	display: flex;
  	align-items: center;
  }

  .authenticating__device {
  	width: 300px;
  	display: block;
  	margin: 0 auto;
  	position: absolute;
  	bottom: 100%;
  	left: 50%;
  	transform: translateX(-50%);

    img {
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }

  }

  .authenticating__wrapper {
  	position: relative;
  }


`;