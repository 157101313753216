import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { appState } from 'Stores/App';
import Navigation from './Navigation';
import Group from './Group';
import Introduction from './VisaSecure/Introduction';
import Macro from './VisaSecure/Macro';
import Micro from './VisaSecure/Micro';
import TargetAudience from './VisaSecure/TargetAudience';
import CustomerExperience from './VisaSecure/CustomerExperience';
import Capabilities from './VisaSecure/Capabilities';
import HowVisaCanHelp from './VisaSecure/HowVisaCanHelp';
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from '../../constants';
import {
  ProductWrapper,
  CloseIcon
} from 'Components/Product/styles';


function Product() {
  const history = useHistory();

  const {
    menu,
    theme,
    navigation,
    showNavigation,
  } = appState();

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }
  }, [navigation, showNavigation]);

  const returnToModule = (e) => {
    history.push("/module/digital-commerce");
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.PRODUCT}</title>
      </Helmet>
      <ProductWrapper className={`Product ${menu ? "menu-is-active" : ""}`}>

        <CloseIcon onClick={(e) => returnToModule(e)} theme={theme} />

        <Navigation />

        <Group id="introduction">
          <Introduction />
        </Group>

        <Group id="macro-content">
          <Macro />
        </Group>

        <Group id="micro-content">
          <Micro />
        </Group>

        <Group id="target-audience">
          <TargetAudience />
        </Group>

        <Group id="customer-experience">
          <CustomerExperience />
        </Group>

        <Group id="capabilities">
          <Capabilities />
        </Group>

        <Group id="how-visa-can-help">
          <HowVisaCanHelp />
        </Group>

        <div id="end"></div>

      </ProductWrapper>
    </>
  );
}

export default Product;