import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CONFIG from './config';
import TagManager from 'react-gtm-module'
import {
  BrowserRouter as Router,
} from 'react-router-dom';

const tagManagerArgs = {
  gtmId: CONFIG.GTM_ID,
};
TagManager.initialize(tagManagerArgs);


ReactDOM.render(

  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
