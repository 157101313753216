import React from 'react';
import dayjs from 'dayjs';
import Modules from 'Components/Module/Modules';

const data = [
  {
    "index": 1,
    "navTitle": "<p>Meet <br> Electronic<br>Store</p>",
    "title": "Meet Electronic Store",
    "content": "<p>An online retailer selling electronic products and technical support packages to consumers and small businesses.</p><p>As the industry evolves, and consumer behaviour changes, Electronic Store needs to adapt their online retail experience so that they can continue to offer a smooth and secure shopping experience.</p><p>Let’s look at how Visa has helped them...</p>",
    "background": "/Fake_Merchant.jpg"
  },
  {
    "index": 2,
    "navTitle": "<p>Visa <br> Secure</p>",
    "subTitle": "See how Electronic Store reduces checkout friction and lowers fraud with <strong>Visa Secure</strong>",
    "background": "https://player.vimeo.com/external/528288280.sd.mp4?s=46620087939001d8c404b86271cc88c427b4b0ec&profile_id=165",
    "button": {
      "url": "/product/visa-secure",
      "text": "Discover Visa Secure"
    }
  },
  {
    "index": 3,
    "navTitle": "<p>Visa Account<br> Updater</p>",
    "title": "<strong>Visa Account Updater</strong>",
    "subTitle": "Ready to explore in...",
    "date": `${dayjs('2021, 6, 18')}`,
  },
  {
    "index": 4,
    "navTitle": "<p>Click<br/>to Pay</p>",
    "title": "<strong>Click to Pay</strong>",
    "subTitle": "Ready to explore in...",
    "date": `${dayjs('2021, 6, 18')}`,
  }

];


function DigitalCommerce(props) {

  return (
    <>
      <Modules data={data} />
    </>
  );
}

export default DigitalCommerce;