/**

 SLIDE BACKGROUND
 ======================

 Responsive background image for the slides

*/

import React from 'react';

function Background({ image }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMidYMid slice" style={{ position: 'fixed', top: '0' }}>
      <image width="100%" height="100%" href={image} />
    </svg>
  );

}

export default Background;