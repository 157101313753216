import styled from 'styled-components';
import { ReactComponent as CrossIcon } from './assets/close.svg';
import { getWidth, getYPos, getXPos, getYPosBottom } from './helpers';
import imagePhone from './assets/phone.png';

export const ProductWrapper = styled.div`
  ${props => props.hasOverlay && `
    opacity: 0.15;
    pointer-events: none;
  `}
  width: auto;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  .scene-content {
  	position: relative;
  	width: 100%;
  	height: 100vh;
  }
`;

export const CloseIcon = styled(CrossIcon)`
  position: fixed;
  top: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  z-index: 10;
  fill: #fff;
  cursor: pointer;

  ${props => props.theme === "dark" && `
    fill: #101a4d;
  `}

  ${props => props.theme === "light" && `
    fill: #fff;
  `}
`;

export const Section = styled.div.attrs(({ Height }) => ({
  style: { height: `${Height}` }
}))`
  position: relative;
  color: #fff;
  z-index: 2;

  .scene {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    padding: 80px 50px 20px 20px;
  }

  ${props => props.light && `
    color: #101a4d;
  `}

  ${props => props.dark && `
    color: white;
  `}
`;


export const SectionBG = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #101a4d;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  + div {
    margin-top: -100vh !important;
  }

  ${props => props.light && `
    background-color: #c9c9cb;
    background-image: url('/background_light.jpg');
  `}

`;

export const Scene = styled.div.attrs(({ Height, Top, Bottom }) => ({
  style: {
    height: `${Height}`,
    marginTop: `${Top}`,
    marginBottom: `${Bottom}`,
  }
}))`
  position: relative;
  width: 100%;
  overflow: hidden;

  &.is-current {
    background-color: #101a4d;
  }

  .container {
    position: relative;
    height: 100%;
    z-index: 2;
  }
`;

export const TypographyHeading1 = styled.div.attrs(({ color }) => ({
  style: {
    color: color ? `${color}` : '#ffffff',
  }
}))`
  text-transform: uppercase;
  font-size: calc( 16px + (28 - 16) * (100vw - 400px) / (1400 - 400));
  font-weight: 700;

  p {
    margin: 0;
  }
`;

export const TypographyHeading2 = styled.div`
  color: #102c66;
  background-color: #fff;
  text-transform: uppercase;
  padding: 0.216em 0.55em;
  margin-bottom: 0.2em;
  font-size: calc( 36px + (36 - 36) * (100vw - 400px) / (1400 - 400));
  width: fit-content;
  letter-spacing: 0.8;
  font-weight: 200;
  text-align: left;

  p {
    margin: 0;
  }
`;

export const TypographyHeading3 = styled.div.attrs(({ color }) => ({
  style: {
    color: color ? `${color}` : '#ffffff',
  }
}))`
  text-transform: uppercase;
  font-size: calc( 36px + (36 - 36) * (100vw - 400px) / (1400 - 400));
  font-weight: 200;
  margin-top: 0.3em;

  p {
    margin: 0;
  }
`;

export const TypographyQuestion = styled.div.attrs(({ color }) => ({
  style: {
    color: color ? `${color}` : '#F7B600',
  }
}))`
  font-size: calc( 29px + (29 - 29) * (100vw - 400px) / (1400 - 400));
  font-weight: 200;
  margin-top: 0.3em;
  line-height: 1.1;

  p {
    margin: 0;
  }
`;

export const Position = styled.div`
	position: absolute;
  height: 100vh;
  top: 100%;

  ${props => props.left && `
    left: ${getXPos(props.left)};
  `}

  ${props => props.width && `
    width: ${getWidth(props.width)};
  `}

  ${props => props.top && `
    transform: translateY(${getYPos(props.top)});
  `}

  ${props => props.bottom && `
    transform: translateY(${getYPosBottom(props.bottom)});
  `}

  ${props => props.overflow && `
    overflow: ${props.overflow};
  `}

${props => props.height && `
    height: ${getYPos(props.heigh)};
  `}
`;

export const Typography = styled.p`
  margin: 0 0 0.5em 0;
  line-height: 1.2;
  letter-spacing: -0.02em;

  ${props => props.color && `
    color: ${props.color};
  `}

  ${props => props.weight && `
    font-weight: ${props.weight};
  `}

  ${props => props.transform && `
    text-transform: ${props.transform};
  `}

  ${props => props.minFontSize && props.maxFontSize && `
    font-size: calc( ${props.minFontSize}px + (${props.maxFontSize} - ${props.minFontSize}) * (100vw - 400px) / (1920 - 400));
  `}

  ${props => props.align && `
    text-align: ${props.align};
  `}

  ${props => props.lineHeight && `
    line-height: ${props.lineHeight};
  `}

  ${props => props.mb && `
    margin-bottom: ${props.mb};
  `}
`;

export const Video = styled.div`
  height: 100%;
  width: 100%;
  .content {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .content video {
    height: 784px;
    width: 492px;
    display: block;

    padding-top: 64px;
    margin-left: -55px;
  }
  .content:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${imagePhone});
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 382px 841px;
  }
`;

export const ScrollIndicator = styled.div`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  transition: color 0.5s ease;
  color: #FFFFFF;
  text-align: center;

  img {
    display: block;
    margin-top: -5px;
  }
`;
