import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TimelineMax } from "gsap/all";
import imageBuildThings from './assets/build_things_illustration.png';
import imageGear from './assets/icon-gear-large.svg';
import { animationPause } from '../../../animations';

import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
} from '../../../styles';

import {
  OutboundLink,
  Paragraph,
  HeadingH1,
  HeadingH2,
} from './styles';

function SlideOne() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'benefits__slide-1__animation';

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    const timeline = new TimelineMax({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
      }
    })

    timeline
      .add(animationPause());
  }, []);

  return (
    <Wrapper ref={ref}>
      <Section light>
        <SectionBG light />
        <Scene Height="100vh" className={`${ID}-scene`}>
          <div className="container">
            <div className="scene-content">

              <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid slice" style={{ position: 'absolute', top: '0', backgroundColor: '#FFFFFF' }}>
                <image width='55%' x='770' y="0" href={imageBuildThings} />
                <image width='140' x='700' y="700" href={imageGear} />

                <foreignObject x="49" y="190" width="740" height="300">
                  <HeadingH1>
                    Let's build great things together
                  </HeadingH1>
                </foreignObject>

                <foreignObject x="49" y="605" width="900" height="100">
                  <HeadingH2>
                    Build now: visit Visa Developer
                  </HeadingH2>
                </foreignObject>

                <foreignObject x="49" y="680" width="620" height="450">
                  <Paragraph>
                    Visa Developer makes an increasing portion of our capabilities available as application program interfaces (APIs). From funds transfer to transaction controls to travel solutions, Visa Developer offers you direct access to a growing number of APIs, tools, and support that can help you start building easier, faster and more secure ways to power commerce.
                  </Paragraph>
                  <Paragraph>
                    Design. Build. Deliver. Open up a new world of possibilities for your business with direct access to Visa’s APIs, tools and expertise.
                  </Paragraph>
                </foreignObject>

                <foreignObject x="49" y="935" width="620" height="40">
                  <OutboundLink href="https://developer.visa.com/developer_program" target="_blank" rel="noopener noreferrer">
                    https://developer.visa.com/developer_program
                  </OutboundLink>
                </foreignObject>

                <foreignObject x="1010" y="605" width="580" height="100">
                  <HeadingH2>
                    Find out more
                  </HeadingH2>
                </foreignObject>

                <foreignObject x="1010" y="680" width="580" height="200">
                  <Paragraph>
                    Ask your Visa Account team for individual product deep dives and implementation detail.
                  </Paragraph>
                  <Paragraph>
                    If you are unsure of your Visa Account team, <OutboundLink href="mailto:vneeurope@visa.com">click here</OutboundLink> to request an engagement.
                  </Paragraph>
                </foreignObject>
              </svg>

            </div>
          </div>
        </Scene>
      </Section>
    </Wrapper >
  );
}

export default SlideOne;
