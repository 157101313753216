import React, { useState, useRef } from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axiosConfig from '../../../../../Services/axiosConfig';
import { Link } from "react-router-dom";
import { TextInput } from 'Components/Auth/Fields';
import CONFIG from '../../../../../config';
import ReCAPTCHA from "react-google-recaptcha";
import ErrorMessages from '../../../ErrorMessages';
import { getApiErrorMessage } from '../../../helper';

function PasswordReset() {
	const [errors, setErrors] = useState([]);
	const [passwordSet, setPasswordSet] = useState(false);
	const recaptchaRef = useRef(null);

	const validationSchema = Yup.object({
		password: Yup.string()
			.required("Required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
	})

	return (
		<div className="authWrapper">
			<ErrorMessages errors={errors} />

			{!passwordSet ?
				<>
					<Formik
						enableReinitialize
						initialValues={{
							password: '',
							confirmPassword: '',
						}}
						validationSchema={validationSchema}
						onSubmit={async (values) => {
							const token = await recaptchaRef.current.executeAsync();
							recaptchaRef.current.reset();
							setErrors([]);

							axiosConfig.post(
								`/identity/users/reset-password`,
								{
									password: values.password,
									confirmPassword: values.confirmPassword
								},
								{
									headers: {
										'g-recaptcha-response': token
									},
								}
							)
								.then((response) => {
									let statusCode = response.data.statusCode;
									switch (statusCode) {
										case 200:
											setPasswordSet(true);
											break;
										case 403:
											setErrors(response.data.errorMessages);
											break;
										case 400:
											setErrors(response.data.errorMessages);
											break;
										default:
											console.error('API error')
									}
								})
								.catch((error) => {
									const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'Unabe to confirm account';
									setErrors([`${message}`])
								});
						}}

						render={({ handleChange, handleSubmit }) => (
							<>
								<Form>

									<TextInput
										type="password"
										label="Password"
										placeholder="Password"
										name="password"
										className="text-field"
										helptext={`Minimum of 8 characters which must include numbers, letters and a symbol`}
									/>

									<TextInput
										type="password"
										label="Re-enter password"
										placeholder="Re-enter password"
										name="confirmPassword"
										className="text-field"
										helptext={`Re-enter to confirm password`}
									/>

									<div className="form__footer">
										<button className="form-btn" type="submit">
											Update my password
										</button>
									</div>
									<ReCAPTCHA
										ref={recaptchaRef}
										size="invisible"
										sitekey={CONFIG.RECAPTCHA_SITE_KEY}
										onChange={handleSubmit}
									/>
								</Form>
							</>
						)}
					/>
				</>
				:
				<>
					<h2 className="form-header">Reset Password</h2>
					<p>Your password has been reset</p>
					<Link to="/login" className="form-btn">Login</Link>
				</>
			}
		</div>
	);
}

export default PasswordReset;
