const PAGE_TITLES = {
    VISA: 'Visa',
    LOBBY: 'Lobby',
    ARTICLE: 'Article',
    CREATE_PASSWORD: 'Create Password',
    LOGIN: 'Login',
    PASSWORD_RESET: 'Password Reset',
    REGISTER: 'Register',
    PAGE_NOT_FOUND: 'Page not found',
    PRODUCT: 'Product',
    SMARTER_PERSONAL_FINANCE: 'Smarter Personal Finance',
    VISA_SECURE: 'Visa Secure',
    MEZZANINE: 'Mezzanine',
    MEZZANINE_PROJECTS: 'Mezzanine Projects'
}

export { PAGE_TITLES };
