import React from 'react';
import { appState } from '../../../Stores/App';
import { Link } from 'react-router-dom';

import spinningChair from './assets/spinning-chair.gif';
import spinningChairStatic from './assets/spinning-chair.png';
import spinningDrone from './assets/spinning-drone.gif';
import spinningDroneStatic from './assets/spinning-drone.png';
import spinningPhone from './assets/spinning-phone.gif';
import spinningPhoneStatic from './assets/spinning-phone.png';

import './style.scss';

function Menu() {
  const {
    menu,
    theme,
  } = appState();

  // FIXME: change component to use styled components
  return (
    menu
      ?
      <div className={`Menu ${theme ? `Menu--${theme}` : ""} ${menu ? `is-active` : ""}`}>
        <div className="NavColumns">
          <div className="NavColumns__item">
            <Link className="NavColumns__link" to="/">
              <div className="NavColumns__text">
                <p className="NavColumns__title">The Lobby</p>
                <p className="NavColumns__description">Visit our lobby for the latest news and insights in the world of payments technology.</p>
              </div>
              <div className="NavColumns__media">
                <img className="NavColumns__image__spinning" src={spinningChair} alt="chair" />
                <img className="NavColumns__image__static" src={spinningChairStatic} alt="chair" />
              </div>
            </Link>
          </div>

          <div className="NavColumns__item NavColumns__item__drone">
            <Link className="NavColumns__link" to="/module/digital-commerce">
              <div className="NavColumns__text">
                <p className="NavColumns__title">Evolving<br />E-commerce</p>
                {/* <p className="NavColumns__description">Explore how Electronic Store have addressed the challenges facing their online retail experience, from regulation change to evolving customer expectations</p> */}
                <p className="NavColumns__description">Coming soon</p>
              </div>
              <div className="NavColumns__media">
                <img className="NavColumns__image__spinning" src={spinningDrone} alt="drone" />
                <img className="NavColumns__image__static" src={spinningDroneStatic} alt="chair" />
              </div>
            </Link>
          </div>

          <div className="NavColumns__item NavColumns__item__phone">
            <Link className="NavColumns__link" to="/module/digital-account">
              <div className="NavColumns__text">
                <p className="NavColumns__title">Smarter<br /> Personal Finance</p>
                <p className="NavColumns__description">Dive into the world of personal finance, and look at how we can meet the needs of the modern account holder</p>
              </div>
              <div className="NavColumns__media">
                <img className="NavColumns__image__spinning" src={spinningPhone} alt="phone" />
                <img className="NavColumns__image__static" src={spinningPhoneStatic} alt="chair" />
              </div>
            </Link>
          </div>
        </div>
      </div >
      :
      null
  );
}

export default Menu;