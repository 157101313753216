import create from 'zustand';

export const [productState] = create(set => ({
	progress: [],
	setProgress: (id, percentage) =>
		set(state => {
			const isPresent = state.progress.find(section => section.id === id);
			if (!isPresent) {
				return {
					...state,
					progress: [...state.progress, { id, progress: percentage }]
				};
			}
			const updatedProgress = state.progress.map(section =>
				section.id === id ? { ...section, progress: percentage } : section
			);
			return {
				...state,
				progress: updatedProgress
			};
		}
		),
	secure3dAuthentication: false,
	setSecure3dAuthentication: (authenticator) => set(state => ({
		secure3dAuthentication: authenticator,
	})),
	smarterPersonalFinanceAnswer: false,
	setSmarterPersonalFinanceAnswer: (authenticator) => set(state => ({
		smarterPersonalFinanceAnswer: authenticator,
	})),
}))