import Cookies from 'js-cookie';
import axiosConfig from './axiosConfig';

const convertTimestamp = (s) => {
  var date = new Date(s * 1000);
  return date;
}

export const setSession = (token, expiry) => {
  let expiryDate = new Date(convertTimestamp(expiry));
  Cookies.set('__session', token, { expires: expiryDate });
}

export const setRefreshToken = (token, expiry) => {
  Cookies.set('__refresh-token', token, { expires: expiry });
}

export const refreshToken = (token, expiry) => {
  let refreshToken = Cookies.get('__refresh-token');

  let data = {
    "token": refreshToken
  }

  axiosConfig.post(`/identity/auth/refresh`, data)

    .then((response) => {

      let statusCode = response?.status;

      switch (statusCode) {
        case 200:
          setSession(response.data.refreshToken, response.data.refreshTokenExpiration);
          setRefreshToken(response.data.refreshToken, response.data.refreshTokenExpiration)
          break;
        case 401:
          console.error(response.data.errorMessages);
          break;
        case 400:
          console.error(response.data.errorMessages);
          break;
      }

    }, (error) => {
      console.error(error);
    });

}

export const getToken = () => {
  const jwt = Cookies.get('__session');
  return jwt;
}

export const getSession = () => {
  const jwt = Cookies.get('__session');

  let session;

  try {
    if (jwt) {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      session = JSON.parse(window.atob(base64))
    }
  } catch (error) {
    console.log(error)
  }

  return session
}

export const logOut = () => {
  Cookies.remove('__session')
}