import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CountDown from '../../Countdown';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { ReactComponent as Chevron } from './assets/chevron.svg';
import { appState } from '../../../Stores/App';

import {
  Wrapper,
  ModuleContainer,
  Module,
  ModuleTitle,
  ModuleSubTitle,
  ModuleIntro,
  ModuleText,
  ModuleBackground,
  Navigation,
  NavigationItem,
  NavigationTitle,
  ProgressIndicator,
  ProgressIndicatorItem,
} from './styles';

function Modules(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = props.data.length;
  const {
    overlay: hasOverlay,
  } = appState();

  const SlickArrowLeft = ({ currentSlide }) => (
    <Chevron
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      onClick={(e) => prevSlide(e)}
    />
  );

  const SlickArrowRight = ({ currentSlide, totalSlides }) => (
    <Chevron
      className={
        "slick-next slick-arrow" +
        (currentSlide === (totalSlides - 1) ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === totalSlides - 1 ? true : false}
      type="button"
      onClick={(e) => nextSlide(e)}
    />
  );

  const nextSlide = (event) => {
    if (isAnimating) {
      return false;
    }
    if (currentSlide + 1 < totalSlides) {
      setCurrentSlide(currentSlide + 1);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }

  const prevSlide = (event) => {
    if (isAnimating) {
      return false;
    }
    if (currentSlide - 1 >= 0) {
      setCurrentSlide(currentSlide - 1);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }

  return (
    <Wrapper hasOverlay={hasOverlay}>
      <ReactScrollWheelHandler
        upHandler={(e) => prevSlide(e)}
        downHandler={(e) => nextSlide(e)}
      >
        <SlickArrowLeft currentSlide={currentSlide} />

        <ModuleContainer items={totalSlides} offset={currentSlide}>
          {props.data.map((item, index) =>
            <Module key={index}>

              <ModuleText>
                {item.title ?
                  <ModuleTitle dangerouslySetInnerHTML={{ __html: item.title }} />
                  : ""}

                {item.subTitle ?
                  <ModuleSubTitle dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                  : ""}

                {item.content ?
                  <ModuleIntro dangerouslySetInnerHTML={{ __html: item.content }} />
                  : ""}

                {item.button && item.button.url && item.button.text ?
                  <Link className="btn" to={item.button.url}>
                    {item.button.text}
                  </Link>
                  : ""}

                {item.date ?
                  <CountDown date={item.date} />
                  : ""}

              </ModuleText>

              {item.background ?
                <ModuleBackground>
                  {item.background.split('.').pop() === "jpg" || item.background.split('.').pop() === "png" ?
                    <div style={{ backgroundImage: `url(${item.background})` }} />
                    : <video autoPlay muted playsInline preload="true" loop src={item.background} />}
                </ModuleBackground> : ""
              }

            </Module>

          )}
        </ModuleContainer>

        <Navigation>
          {props.data.map((item, index) =>
            <NavigationItem active={currentSlide === index ? true : false} key={index} onClick={() => setCurrentSlide(index)} >
              {item.index}
              <NavigationTitle dangerouslySetInnerHTML={{ __html: item.navTitle }} />
            </NavigationItem>
          )}
        </Navigation>

        <ProgressIndicator>
          {props.data.map((item, index) =>
            <ProgressIndicatorItem
              key={index}
              active={currentSlide === index ? true : false}
              onClick={() => setCurrentSlide(index)}
              aria-disabled="true"
            />
          )}
        </ProgressIndicator>

        <SlickArrowRight currentSlide={currentSlide} totalSlides={totalSlides} />

      </ReactScrollWheelHandler>
    </Wrapper>
  );
}

export default Modules;