import React from 'react';

import {
  TooltipWrapper,
  TooltipText,
  TooltipIntro,
  TooltipTitle,
  TooltipMarker,
  TooltipBg,
  TooltipChrome
} from './styles';

function Tooltip(props) {
  return (
    <>
      <TooltipWrapper
        id={`tooltip-${props.id}`}
        to={props.to}
        left={props.left}
        top={props.top}
        onClick={props.handleClick}
      >
        <TooltipText>
          {props.intro &&
            <TooltipIntro>{props.intro}</TooltipIntro>
          }
          {props.title &&
            <TooltipTitle>{props.title}</TooltipTitle>
          }
        </TooltipText>
        <TooltipMarker />
        <TooltipChrome />
      </TooltipWrapper>
      <TooltipBg />
    </>
  )
}

export default Tooltip;
