import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { TextPlugin } from "gsap/TextPlugin"

import TooltipHelper from 'Components/TooltipHelper';

import { Section, SectionBG, Scene } from 'Components/Product/styles';
import Reveal from 'Components/Product/Reveal';
import {
  Primary,
  Secondary,
  OnlineSpend,
  Trillion,
  TrillionIntro,
  ConnectedDevices,
  TotalSpend
} from './styles';

function Devices() {

  gsap.registerPlugin(ScrollTrigger, TextPlugin);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    // Pinned Elements
    gsap.to(
      element.querySelector(".section-1"), {
      scrollTrigger: {
        trigger: element.querySelector(".section-1"),
        start: "top top",
        end: "+=300%",
        pin: true,
        scrub: true
      },
    }
    );

    // Timeline
    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        // markers: true
      }
    })
      .from(".animation-1", { y: "100%" })
      .from(".animation-2", { x: "300%" })
      .to(".animation-1", { x: "-300%" })
      .to(".animation-2", { x: "300%" })
      .from(".animation-3", { y: "100%" })
      .from(".animation-4", { y: "100%" })
      .from(".trillion", { x: "300%" })
      .to(".trillion__value-numeric", { duration: 0.01, text: "" })
      .to(".trillion__value-numeric", { duration: 0.1, text: "tn" })
      .to(".trillion", { x: "-300%" }, "=+1")
      .to(".animation-3", { x: "-300%" })
      .to(".animation-4", { x: "-300%" })
      .from(".connected-devices", { y: "100%" })
      .from(".total-spend", { x: "300%" })

  }, []);

  return (
    <div id="devices" ref={ref}>

      <Section className="section">

        <SectionBG className="bg-animate" />

        <Scene Height="100vh" className="section-1">

          <div className="container">

            <Primary className="animation-1">
              <Reveal
                title="Digital commerce is the fastest growing area of payments, and consumers have more ways to pay than ever before..."
                content="With an explosion in connected devices, and different ways to pay, verifying the transaction and the identity of the consumer becomes increasingly important and challenging."
              />
            </Primary>

            <Secondary className="animation-2">
              <div className="stat">
                <div className="stat__number">43</div>
                <div className="stat__description">Billion IoT-connected devices predicted by 2023
                <TooltipHelper
                    id="total-spend"
                    index="2"
                    title="https://www.mckinsey.com/"
                    x="-180"
                    y="20"
                    position="bottom"
                  />
                </div>
              </div>
            </Secondary>

            <OnlineSpend className="animation-3">
              <h4>And customer spending on physical and digital goods online will continue to grow:</h4>
            </OnlineSpend>

            <TrillionIntro className="animation-4">
              <p className="trillion__intro">By
                2025, the annual spend in Europe is predicted to be
                <TooltipHelper
                  id="mobile-payment"
                  index="2"
                  title="Juniper ECOMMERCE PAYMENTS Deep Dive Data & Forecasting 2020-2025"
                  x="-180"
                  y="15"
                  position="bottom"
                /></p>
            </TrillionIntro>

            <Trillion className="trillion">
              <div className="trillion__number">
                <span className="trillion__unit">$</span>
                <span className="trillion__value">1<span className="trillion__value-numeric">,000,000,000</span></span>
              </div>
            </Trillion>

            <ConnectedDevices className="connected-devices">
              <Reveal
                title="Customers expect to pay with their chosen payment method across any of their connected devices."
                content={`30% of people in the United Kingdom (UK) own five or more connected devices.`}
                tooltipTitle="https://www.statista.com/statistics/365104/number-connected-devices-per-person-uk/"
                tooltipID="connected-devices"
                position="right"
                x="30"
                y="0"
              />

            </ConnectedDevices>
            <TotalSpend className="total-spend">
              <div className="stat">
                <div className="stat__number"><span>57</span><span className="stat__unit">%</span></div>
                <div className="stat__description">mobile payment volume growth expected between 2020-2025
                <TooltipHelper
                    id="mobile-payment"
                    index="2"
                    title="Juniper ECOMMERCE PAYMENTS Deep Dive Data & Forecasting 2020-2025"

                    position="bottom"
                  />
                </div>
              </div>
            </TotalSpend>

          </div>

        </Scene>

      </Section>

    </div>
  );
}

export default Devices;