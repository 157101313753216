import React from 'react';
import Theme from '../../../../Theme';
import SlideOne from './SlideOne';
import SlideTwo from './SlideTwo';
import SlideThree from './SlideThree';

function Create() {
    return (
        <Theme onEnter="light" onEnterBack="light" onLeaveBack="light">
            <SlideOne />
            <SlideTwo />
            <SlideThree />
        </Theme>
    );
}

export default Create;