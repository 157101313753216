import styled from 'styled-components';

export const MarqueeWrapper = styled.div`
position: absolute;
z-index: 1;
width: 100vw;
height: 100vh;


.hero-marquee {
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  top: 50%;
transform: translate(0, -50%);
}

.marquee {
 font-size: 0;
}

.clipped-text {
 display: inline-block;
 font-weight: 100;
 font-size: 150px;
 color: #042979;
 margin-right: 100px;
}

`;
