import React from 'react';
import {
	HeroWrapper,
	HeroImage,
} from './styles';

function Hero(props) {
	return (
		<HeroWrapper className="Hero">
			<HeroImage src={props.backgroundImage} />
		</HeroWrapper>
	);
}

export default Hero;
