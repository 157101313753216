import styled from 'styled-components';

export const LogoWrapper = styled.div`
	position: fixed;
	top: 35px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
	transition: color 0.5s ease;

	${props => props.theme === "dark" && `
    color: #101A4D;
  `}

  ${props => props.theme === "light" && `
    color: #FFFFFF;
  `}
`;

export const Logo = styled.div`
	fill: currentColor;
	display: block;
	height: 30px;
`;

export const Close = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  fill: #FFFFFF;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 100;
`;