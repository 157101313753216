import React, { useState } from 'react';

import {
  Wrapper,
  Container,
} from './styles';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { ReactComponent as Chevron } from './assets/chevron.svg';


function Carousel({ data, setCurrentSlide, currentSlide, children }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = data.length;

  const ArrowLeft = ({ currentSlide }) => (
    <Chevron
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      onClick={(e) => prevSlide(e)}
    />
  );

  const ArrowRight = ({ currentSlide, totalSlides }) => (
    <Chevron
      className={
        "slick-next slick-arrow" +
        (currentSlide === (totalSlides - 1) ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === totalSlides - 1 ? true : false}
      type="button"
      onClick={(e) => nextSlide(e)}
    />
  );

  const nextSlide = () => {
    if (isAnimating) {
      return false;
    }
    if (currentSlide + 1 < totalSlides) {
      setCurrentSlide(currentSlide + 1);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }

  const prevSlide = () => {
    if (isAnimating) {
      return false;
    }
    if (currentSlide - 1 >= 0) {
      setCurrentSlide(currentSlide - 1);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }

  return (
    <Wrapper>
      <ReactScrollWheelHandler
        upHandler={(e) => prevSlide(e)}
        downHandler={(e) => nextSlide(e)}
      >
        <ArrowLeft currentSlide={currentSlide} />

        <Container items={totalSlides} offset={currentSlide}>
          {children}
        </Container>

        <ArrowRight currentSlide={currentSlide} totalSlides={totalSlides} />
      </ReactScrollWheelHandler>
    </Wrapper >
  );
}

export default Carousel;
