export function writeCookie(name, value, days = 30) {
    var now = new Date();
    now.setDate(now.getDate() + days);

    document.cookie = `${name}=${value}`;
    document.cookie = 'expires=' + now.toUTCString() + ';';
}

export function getCookie(cookieName) {
    if (document.cookie.length > 0) {
        let stringIndex = document.cookie.indexOf(cookieName + '=');
        if (stringIndex !== -1) {
            stringIndex = stringIndex + cookieName.length + 1;
            let stringIndexEnd = document.cookie.indexOf(';', stringIndex);
            if (stringIndexEnd === -1) {
                stringIndexEnd = document.cookie.length;
            }
            return unescape(document.cookie.substring(stringIndex, stringIndexEnd));
        }
    }
    return false;
}
