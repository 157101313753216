import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import infoGraphic from '../assets/VAU-infographic.jpg';
import { NavButton, NavContainer, VAUHeading, VAUInfoGraphic, VAUWrapper } from './styles';

function VisaAccountUpdater() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <VAUWrapper>
            <VAUInfoGraphic>
                <VAUHeading>
                    Explore the infographic:
                </VAUHeading>
                <img src={infoGraphic} />
                <NavContainer>
                    <Link to="/mezzanine/projects">
                        <NavButton>Back to Home Screen</NavButton>
                    </Link>
                    <Link to="/mezzanine/projects/CTP">
                        <NavButton>Next to Click and Pay</NavButton>
                    </Link>
                </NavContainer>
            </VAUInfoGraphic>
        </VAUWrapper>
    )
}

export default VisaAccountUpdater;