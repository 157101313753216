import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
	HeroWrapper,
	HeroImage,
	HeroVideo,
	HeroContent,
	HeroTitle,
	HeroIntro
} from './styles';

gsap.registerPlugin(ScrollTrigger);

function Hero(props) {

  	const ref = useRef(null);

  	useEffect(() => {
    const element = ref.current;

    gsap.fromTo(
    	element.querySelector(".hero__content"),
    	{
    		opacity: 1,
    		y: 0
    	},
    	{
    		opacity: 0,
    		y: -20,
    		scrollTrigger: {
    			trigger: element,
    			start: "10% top",
    			end: "40% top",
    			scrub: true
    		},
    	}
    	);

	  }, []);

	return (
    	<HeroWrapper className="Hero" ref={ref} type={props.type}>

            {props.backgroundVideo ? <HeroVideo type={props.type} autoPlay muted playsInline preload="true" loop src={props.backgroundVideo} />: "" }
    		{props.backgroundImage ? <HeroImage type={props.type} src={props.backgroundImage} />: "" }

    		{props.title || props.intro ? 
    			<HeroContent type={props.type} className="hero__content">
    				{props.title ?
    					<HeroTitle type={props.type}>{props.title}</HeroTitle>
    				: "" }
    				{props.intro ?
    					<HeroIntro type={props.type}>{props.intro}</HeroIntro>
    				: "" }
    			</HeroContent>
    		: ""}

    	</HeroWrapper>
  	);
}

export default Hero;