import { ItemTypes } from './ItemTypes';
import { DragSource } from 'react-dnd';
import { productState } from 'Stores/Product';

const wrapperStyle = {
    textAlign: 'center',
    width: '15vw',
    marginRight: '30px'
};

const style = {
    cursor: 'move',
};

const Box = ({ name, image, isDragging, connectDragSource }) => {

    const opacity = isDragging ? 0.4 : 1;
    return (
        <div style={wrapperStyle}>
            <div ref={connectDragSource} role={'Box'} data-id={`box-${name}`} style={{ ...style, opacity }}>
                <img src={image}/>
		    </div>
            {name}
        </div>
    );
};

export default DragSource(ItemTypes.BOX, {
    beginDrag: (props) => {
        return { name: props.name };
    },
    endDrag(props, monitor) {
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();
    },
}, (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
})(Box);
