export const getApiErrorMessage = (status) => {
    if (!status) return;
    switch (status) {
        case 400:
            return 'Invalid data';
        case 401:
            return 'Invalid Login';
        case 403:
            return 'Invalid user details';
        case 500:
            return 'The server is currrently not responding';
        default:
            return 'An error occured'
    }
};
