import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { appState } from '../../Stores/App';
import { PAGE_TITLES } from '../../constants';

import imgTBC from './assets/tbc_thumbnail.jpg';
import downArrow from './assets/down_arrow.png';

import {
  MezzanineWrapper,
  MezzanineBG,
  MezzProjectsTitle,
  MezzProjectContent,
  MezzProjectVideoDescription,
  CloseButton,
  MezzProjectVideo,
  ScrollDownButton,
  MezzProjectContainer,
} from './styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CloseIcon } from 'Components/Product/styles';
import VisaAccountUpdater from './VisaAccountUpdater';
import ClickToPay from './ClickToPay';
import VisaTokenService from './ClickToPay';

function MezzanineProject() {
  const scene = useRef();

  const {project} = useParams();
  const history  = useHistory();
  
  const {
    navigation,
    showNavigation,
  } = appState();

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }

  }, [navigation, showNavigation]);

  const projectData = {
    'VAU': {
      title: 'Visa Account Updater',
      descrption: "Lorem ipsum dolor sit amet, consectetur adipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
      content: <VisaAccountUpdater />
    },
    'CTP': {
      title: 'Click to Pay',
      descrption: "Lorem ipsum dolor sit amet, consectetur adipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
      content: <ClickToPay />
    },
    'VTS': {
      title: 'Visa Token Service',
      descrption: "Lorem ipsum dolor sit amet, consectetur adipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
      content: <VisaTokenService />
    },
    'PAR': {
      title: 'PAR',
      descrption: "Lorem ipsum dolor sit amet, consectetur adipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
    },
  }

  const scrollDown = () => {
    document.getElementById('projectInfo').scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
    <>
    <MezzProjectContainer>
      <Helmet>
        <title>{PAGE_TITLES.MEZZANINE_PROJECTS}</title>
      </Helmet>
      <MezzanineWrapper ref={scene} hasOverlay>
        <MezzanineBG />
      </MezzanineWrapper>
      <CloseButton>
        <Link to="/mezzanine/projects">
          <CloseIcon />
        </Link>
      </CloseButton>
      <MezzProjectsTitle style={{color: '#EDB93F'}}>{projectData[project].title}</MezzProjectsTitle>
      <MezzProjectContent>
        <MezzProjectVideo>
          <img src={imgTBC} />
        </MezzProjectVideo>
        <MezzProjectVideoDescription>{projectData[project].descrption}</MezzProjectVideoDescription>
      </MezzProjectContent>  
      <ScrollDownButton onClick={scrollDown}>
        <img src={downArrow} />
      </ScrollDownButton>
    </MezzProjectContainer>
    <div id="projectInfo">
      {projectData[project].content}
    </div>
    </>
  )
}

export default MezzanineProject;
