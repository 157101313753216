import styled from 'styled-components';
import iconTick from './assets/icon-tick.svg';


export const CheckboxStyled = styled.div`
    font-size: inherit;
    padding-left: 2.25em;
    position: relative;
    display: inline;
    margin-bottom: 1em;

    > input {
        display: none;
        position: absolute;
        opacity: 0;
        left: auto;
        right: 0;
        cursor: pointer;
        + label {
            display: inline;
            cursor: pointer;
            color: inherit;
            font-family: inherit;
            line-height: 1.25;
            user-select: none;

            &:before {
                top: 0;
                left: 0;
                right: 0;
                width: 1.5em;
                height: 1.5em;
                background: transparent;
                border-radius: 4px;
                color: white;
                line-height: 1.25;
                content: '';
                position: absolute;
                text-align: center;
                border: white 1px solid;
            }
        }
        &:checked {
            + label {
                &:before {
                    content: '';
                    background-color: white;
                    border: 1px solid white;
                }
                &:after {
                    font-family: 'inherit';
                    content: '✔';
                    color: black;
                    font-size: inherit;
                    width: 1.5em;
                    height: 1.5em;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    padding: 0.25em;
                    background-image: url(${iconTick});
                    text-align: center;
                    top: -1px;
                }
            }
        }
    }
`;
