import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactComponent as ImageSectionTitle } from '../../../assets/pay-limit-notifications.svg';
import image from './assets/pay-3.jpg';
import imageCube from './assets/Capability_Cube_Transaction_Controls_API.png';
import Background from '../../../Background';
import Text from '../../../Text';
import FlyoutBackground from '../../../FlyoutBackground';
import { More, showMoreButton } from '../../../More';
import { getFlyoutX, getFlyoutY } from '../../../helpers';
import { animatePhone, animateTextInOut, animateCubeIn, animationPause } from '../../../animations';
import video from './assets/VXP_Pay_3.mp4';
import PlayButton from '../../../PlayButton';

import {
  SCREEN_ANIMATION_TYPES
} from '../../../animations/constants';


import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
  Position,
  Video,
} from '../../../styles';

function SlideThree() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'pay__slide-3__animation';
  const [movement, setMovement] = React.useState(null);

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=400%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
        onUpdate: (self) => {
          const movement = self.getVelocity();
          if (movement) {
            setMovement(movement)
          }
        }
      },
    }
    );

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
      }
    })

    timeline
      .set(`#${ID}-flyout`, { x: `${getFlyoutX(1079)}`, y: `${getFlyoutY(561)}` })
      .set(`#${ID}-video`, { opacity: 0 })

      .add(animationPause())

      .add(animateTextInOut(ID))

      .add(animateCubeIn(ID))

      .add(animatePhone(ID, { screenAnimationTypes: [SCREEN_ANIMATION_TYPES.FADE, SCREEN_ANIMATION_TYPES.MORPH], swapFlyoutContent: true }))

      .add(showMoreButton(ID), "showButton")

      .add(animationPause());

  }, []);

  return (
    <Wrapper ref={ref}>
      <Section light className="test2">
        <SectionBG light />
        <Scene Height="100vh" className={`${ID}-scene`}>
          <div className="container">
            <div className="scene-content">

              <Background image={image} />

              <Position left="94" width="214" top="186">
                <ImageSectionTitle />
              </Position>

              <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMaxYMid slice" style={{ position: 'absolute', top: '0' }}>

                <More
                  id={`${ID}`}
                  width='515'
                  x='1088'
                  y='506'
                  title='Visa Transaction Control APIs'
                  text='Empowers issuers to offer blocks, alerts or both on the controls of their choice. The Customer Rules API is used to register, retrieve, modify and delete an account\s card control settings.'
                  movement={movement}
                />

                {/* Flyout */}
                <g clipPath={`url(#${ID}-mask)`}>
                  <g id={`${ID}-flyout-wrapper`}>
                    <FlyoutBackground id={ID} x="1079" />
                    <g id={`${ID}-flyout-text1`}>
                      <Text
                        text={['CONSUMER BENEFITS']}
                        position={{ x: "1093", y: "713", width: "306" }}
                        styles={{ color: "#1A1F71", weight: "400", minFontSize: "22", maxFontSize: "25", align: "right" }}
                      />
                      <Text
                        text={['Receive notifications when approaching, or exceeding, set budget.  Delivers increased transparency over spending and enables better budget management. ']}
                        position={{ x: "1096", y: "754", width: "302" }}
                        styles={{ color: "#1A1F71", weight: "200", minFontSize: "16", maxFontSize: "16", align: "right" }}
                      />
                    </g>
                    <g id={`${ID}-flyout-text2`}>
                      <Text
                        text={['ISSUER BENEFITS']}
                        position={{ x: "1093", y: "723", width: "306" }}
                        styles={{ color: "#1A1F71", weight: "400", minFontSize: "22", maxFontSize: "25", align: "right" }}
                      />
                      <Text
                        text={['Reduce call centre calls and increase money management offering.']}
                        position={{ x: "1136", y: "764", width: "262" }}
                        styles={{ color: "#1A1F71", weight: "200", minFontSize: "16", maxFontSize: "16", align: "right" }}
                      />
                    </g>
                  </g>
                </g>
                {/* Flyout End */}

                <g id={`${ID}-text1`}>
                  <Text
                    text={[
                      'For getting beach ready, the home gym equipment was essential. However, he didn\'t realise how much it was going to cost!',
                      'His bank balance has taken a beating...',
                    ]}
                    position={{ x: "1161", y: "656", width: "569" }}
                    styles={{ color: "#ffffff", weight: "200", minFontSize: "28", maxFontSize: "28" }}
                  />
                </g>

                <g id={`${ID}-text2`}>
                  <Text
                    text={[
                      'He has set up monthly spend limit notifications that help him to stay within his budget.  The new gym equipment has exceeded the limit!',
                      'Whilst this isn\'t his favourite notification, at least there\'s no surprises.',
                    ]}
                    position={{ x: "1161", y: "716", width: "524" }}
                    styles={{ color: "#ffffff", weight: "200", minFontSize: "28", maxFontSize: "28" }}
                  />
                </g>

                <image id={`${ID}-cube`} x="1464" y="492" width="267" height="205" href={imageCube} />
                <g id={`${ID}-phone-group`}>
                  <svg x="1430" y="-90" width="342px" height="1280.3px" viewBox="0 0 342 1280.3">
                    <defs>
                      <mask id={`${ID}-phone-mask`}>
                        <rect x="0" y="350" width="340" height="726" fill="#ffffff" />
                      </mask>
                    </defs>
                    <path mask={`url(#${ID}-phone-mask)`} id={`${ID}-phone-morph-start`} fill="#1A1F71" d="M0,1280.3h341.8l0.2-145.5c0,0-9.7-19.4-17.1-6.8c-2.8,4.7-3.2,8.8-2.7,12c0.9,5.4,5.5,16.5,3.3,21.6
	c-1.5,3.6-4.7,5.7-11,2.3c-3.3-1.8-12.4-13.2-15.5-16.1c-8.8-7.9-18,0.7-18.8,2.2c-2.8,4.9-3.2,11-3.5,16.3
	c-0.4,9.2-5.2,24.2-18.2,20.4c-7-2.1-10.7-14.1-12.7-24.8c-1.7-9.2-1.5-23.3-2.4-31.7c-1.1-10.2-4.3-24.2-13.1-36.2
	c-16.1-21.8-71.7-35.4-63.9,25.2c2.6,19.8-0.1,33.3-4.9,42.5c-9.8,18.9-47.7,25.4-50.7,11.8c0,0-8.5-16,0.3-35.5
	c23.6-52.8-47.5-5.6-36.3,22.4c5.5,13.8,0.4,30.6-16.5,33c-16.9,2.4-25.2-7.3-23.5-30.1c0.5-6.7,2.5-13.9,4.7-20.7
	c5.1-16.5,10.8-31.2-2-33.6c-7.8-1.4-15.7,3.4-22.3,9.4c-8.8,8.1-15.1,18.3-15.1,18.3V1280.3z"/>
                    <path style={{ visibility: 'hidden' }} id={`${ID}-phone-morph-end`} d="M0,1130h341.8L342,71.8c0,0-14-46.5-37.1-40.4c-21.1,5.5-41.6,53-13.7,89c16.5,21.3,28.2,80.8,28,119.3
	c-0.2,54,0,74-13.7,67.5c-30.7-14.5-2.2-53-5.5-118.4c-3-60.7-53.6-31.1-52.6,26.6c0.6,37,18.9,39.8,19.6,79.9
	c0.5,28.8-0.9,55.3-25.2,58.4c-23.1,2.9-32.6-19.1-34.3-40.1c-4.9-59.4-6.9-97.9,16.9-128.2c29.1-37,65.2-120.4,16.9-172.6
	C224.1-6,180-9.4,158.5,39.9c-48.5,111.1,7,176.3,9.7,245.6c2,50.3-47.1,70.2-57.2,4.5c-3.8-24.5,3.9-50.8,6.2-66.9
	c13.6-94.4-25.8-84.8-37.8-10.6c-13.2,81-7.9,96.8-25.6,96.8c-10.9,0-21.8-7.2-21.3-57.8c0.5-50.5,14.4-45.8,26.1-63
	c37.6-55.5,6.8-75.1-13.5-70.2c-16.5,4-36,10.9-39,65.2C3.5,230.3,0,287.3,0,287.3V1130z"/>
                  </svg>

                  <foreignObject x="1407" y="198" width="382" height="875">
                    <Video>
                      <div id={`${ID}-phone`} className="content">
                        <video id={`${ID}-video`} className={`${ID}-intro-video`} src={video} playsInline="">
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>
                      <PlayButton id={ID} className={`${ID}-phone-fade`} movement={movement} />
                    </Video>
                  </foreignObject>
                </g>
              </svg>

            </div>
          </div>
        </Scene>
      </Section>
    </Wrapper>
  );
}

export default SlideThree;