/**

 GROUP
 ======================

 Acts as a container to track scroll progress through a set of scenes.
 Requires an ID to be passed as a prop which passes the progress value to global
 state which is then picked up in the product nav and converted into a percentage.

*/

import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { productState } from 'Stores/Product';

gsap.registerPlugin(ScrollTrigger);

function Group(props) {

	const ref = useRef(null);
	const { setProgress } = productState();

	useEffect(() => {

		const element = ref.current;

		gsap.to(
			element, {
				scrollTrigger: {
					trigger: element,
					start: "top top",
					end: "bottom top",
					onUpdate: (self) => {
						if (self.progress !== null) {
							const progress = self.progress * 100;
							setProgress(props.id, progress);
						}
					}
				},
			} 

			);

	}, []);

	return (
		<div className="ScrollGroup" id={props.id} ref={ref}>
			{props.children}
		</div>
	);

}

export default Group;