import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Theme from '../../../../../Theme';
import Hero from '../../../Hero';
import image from './assets/visa_spf_city-skyline.jpg';
import { ReactComponent as Timeline } from './assets/timeline.svg';
import imageChevron from './assets/chevron_down.svg';
import Text from '../../../Text';
import Reveal from 'Components/Product/Reveal';

import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
  ScrollIndicator
} from '../../../styles';

import { Overlay } from './styles';

function SlideOne() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'introduction__slide-1__animation';

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    timeline
      .set(`#${ID}-scroll-indicator`, { y: 0 })
      .to(`#${ID}-scroll-indicator`, { duration: 0.5, ease: "slow (0.7, 0.7, false", y: +5 })
      .to(`#${ID}-scroll-indicator`, { duration: 1, ease: "slow (0.7, 0.7, false", y: 0 });

  }, []);

  return (
    <Theme onEnter="light" onEnterBack="light" onLeaveBack="light">
      <Wrapper ref={ref}>
        <Section light>
          <SectionBG light />
          <Scene Height="100vh" className={`${ID}-scene`}>
            <Hero backgroundImage={image} type="overlay" />
            <Overlay />
            <div className="container">
              <div className="scene-content">

                <ScrollIndicator>
                  Scroll
                  <img id={`${ID}-scroll-indicator`} width="60" height="60" src={imageChevron} alt="" />
                </ScrollIndicator>


                <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid" style={{ position: 'absolute', top: '0' }}>
                  <Text
                    text={[
                      'Lets start with how banking has evolved...',
                    ]}
                    position={{ x: "49", y: "149", width: "979" }}
                    styles={{ color: "#F7B600", weight: "200", minFontSize: "45", maxFontSize: "45" }}
                  />

                  <foreignObject id={`${ID}-text1`} x="55" y="223" width="784" height="100%">
                    <Reveal
                      title="The last 20 years have witnessed more change than the previous 200"
                      content={[
                        '<p>We have seen a shift from physical to online commerce, accelerated </br>by the emergence of new marketplaces and new business models.</p><p>With the introduction of Open Banking we expect the pace of innovation to continue</p>'
                      ]}
                      styles={{
                        title: {
                          minFontSize: "45",
                          maxFontSize: "45",
                          mb: "0.5",
                        },
                        content: {
                          minFontSize: "16",
                          maxFontSize: "20",
                          color: "#FFFFFF",
                          width: "100",
                        }
                      }}
                    />
                  </foreignObject>

                  <Timeline />

                </svg>


              </div>
            </div>
          </Scene>
        </Section>
      </Wrapper>
    </Theme>
  );
}

export default SlideOne;
