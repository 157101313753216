import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Theme from '../../../../Theme';
import Flow from './FlowDiagram';

function Capabilities() {

  return (
    <Theme onEnter="light" onLeave="dark">
      <Flow />
    </Theme>
  );
}

export default Capabilities;