import React, { useEffect } from 'react';
import { appState } from '../../Stores/App';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoIcon } from './assets/visa_logo_global.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import Navigation from './Navigation';

import './styles.js';

import {
  LogoWrapper,
  Logo,
  Close,
} from './styles';

function Header() {
  const history = useHistory();

  const {
    theme,
    navigation,
    closeOverlay,
    overlay,
    menu,
    profile,
    closeButton,
  } = appState();

  useEffect(() => {
    const historyListener = history.listen(() => {
      if (overlay && (menu || profile)) {
        closeOverlay();
      }
    })
    return () => historyListener();
  }, [history, closeOverlay, menu, overlay, profile]);

  return (
    <>
      {navigation ? <Navigation /> : null}

      <LogoWrapper theme={theme}>
        <Logo>
          <LogoIcon className="Header__logo" />
        </Logo>
      </LogoWrapper>

      {closeButton
        ?
        <Close>
          <CloseIcon onClick={closeOverlay} />
        </Close>
        :
        null
      }
    </>
  );
}

export default Header;
