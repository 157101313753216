import React, { useRef, useEffect } from 'react';
import Fraud from './Fraud';

function Macro() {

  return (
    <>
      <Fraud/>
    </>
  );
}

export default Macro;