import React from 'react';

function ErrorMessages({ errors }) {
    if (!Array.isArray(errors)) return null;
    return (
        errors && errors.length > 0 ?
            <ul className="errorList">
                {errors?.map((error, index) =>
                    <li key={index}>{error}</li>
                )}
            </ul>
            : ""
    );
}

export default ErrorMessages;
