import React from 'react';
import axiosConfig from '../../Services/axiosConfig';
import { ReactComponent as StarIcon } from './assets/Star.svg';

import {
  Wrapper,
  Title,
  Content,
  ThankYouContent,
  IconWrapper,
  StarsWrapper,
} from './styles';


function StarRating({ payloadObject }) {
  const [rating, setRating] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  function Star({ id }) {
    return (
      <IconWrapper onClick={() => setRating(id)} rating={rating} id={id} >
        <StarIcon width="100%" />
      </IconWrapper>
    )
  }

  function handleSubmit() {
    const payload = {
      ...payloadObject,
      rating: rating,
    }
    axiosConfig.post(
      `/star-ratings`,
      payload,
    ).then(() => {
      setSubmitted(true);
    }, () => {
      console.error('Unable to submit rating');
    });
  }

  return (
    <Wrapper>
      {submitted
        ?
        <>
          <Title>Thank you</Title>
          <ThankYouContent>We value your feedback.</ThankYouContent>
          <ThankYouContent>Why not explore some of our other content?</ThankYouContent>
        </>
        :
        <>
          <Title>Was this content useful?</Title>
          <Content>Please take a second to give us your anonymus feedback (1 is poor, 5 is great)</Content>
          <StarsWrapper>
            <Star id="1.0" />
            <Star id="2.0" />
            <Star id="3.0" />
            <Star id="4.0" />
            <Star id="5.0" />
          </StarsWrapper>

          {/* FIXME: using form-btn class for now for consistency across the project */}
          <div>
            <button onClick={handleSubmit} className="form-btn" type="submit">Submit rating</button>
          </div>
        </>
      }
    </Wrapper>
  );
}

export default StarRating;
