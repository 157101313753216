import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { appState } from '../../Stores/App';
import { PAGE_TITLES } from '../../constants';
import imgCTP from './assets/ctp_thumbnail.jpg'
import imgTBC from './assets/tbc_thumbnail.jpg'
import imgVTS from './assets/vts_thumbnail.jpg'

import {
  MezzanineWrapper,
  MezzanineBG,
  MezzProjectsTitle,
  MezzProjectsIntro,
  MezzProjectsContainer,
  MezzProjectsThumbnailContainer,
  MezzProjectsThumbnail,
  MezzProjectsThumbnailText,
  MezzProjectsThumbnailOverlay,
  MezzProjectsThumbnailOverlayDescription,
  CloseButton,
} from './styles';
import { Link } from 'react-router-dom';
import { CloseIcon } from 'Components/Product/styles';

function MezzanineProjects() {
  const {
    navigation,
    showNavigation,
  } = appState();

  const [thumbnailHover, setThumbnailHover] = useState('');

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }
  }, [navigation, showNavigation]);

  const projects = [
    {
      title: "Visa Account Updater",
      img: imgTBC,
      link: '/mezzanine/projects/VAU',
      description: 'Description goes here'
    },
    {
      title: "Click to Pay",
      img: imgCTP,
      link: '/mezzanine/projects/CTP',
      description: 'Description goes here'
    },
    {
      title: "Visa Token Service",
      img: imgVTS,
      link: '/mezzanine/projects/VTS',
      description: 'Description goes here'
    },
    {
      title: "PAR",
      img: imgTBC,
      link: '/mezzanine/projects/PAR',
      description: 'Description goes here'
    },
  ]

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.MEZZANINE_PROJECTS}</title>
      </Helmet>
      <MezzanineWrapper hasOverlay>
        <MezzanineBG />
      </MezzanineWrapper>
      <CloseButton>
        <Link to="/mezzanine">
          <CloseIcon />
        </Link>
      </CloseButton>
      <MezzProjectsContainer>
        <MezzProjectsTitle>Open for consumer choice</MezzProjectsTitle>
        <MezzProjectsIntro>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. 
          Risus commodo viverra maecenas accumsan lacus vel facilisis. 
        </MezzProjectsIntro>
        <MezzProjectsThumbnailContainer>
          {projects.map(({title, img, link, description}, idx) => (
            <Link 
              to={link} 
              key={idx} 
              onMouseEnter={() => setThumbnailHover(title)}
              onMouseLeave={() => setThumbnailHover('')}
              >
              <MezzProjectsThumbnail>
                <img src={img} />
                {thumbnailHover === title && 
                  <>
                    <MezzProjectsThumbnailOverlay >
                      <MezzProjectsThumbnailOverlayDescription>
                        {description}
                     </MezzProjectsThumbnailOverlayDescription>
                    </MezzProjectsThumbnailOverlay>
                  </>
                }
                {thumbnailHover !== title && <MezzProjectsThumbnailText>{title}</MezzProjectsThumbnailText>}
              </MezzProjectsThumbnail>
            </Link>
          ))}
        </MezzProjectsThumbnailContainer>
      </MezzProjectsContainer>

    </>
  )
}

export default MezzanineProjects;
