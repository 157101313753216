import React from 'react';
import Theme from '../../../../Theme';
import SlideOne from './SlideOne';

function Benefits() {
    return (
        <Theme onEnter="dark" onEnterBack="dark" onLeaveBack="dark">
            <SlideOne />
        </Theme>
    );
}

export default Benefits;
