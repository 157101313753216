/**

 TEXT BLOCK
 ======================

 Renders a block of text, either a single paragraph or an array of paragraphs.

*/

import React from 'react';
import {
  Typography,
} from '../styles';

function Text({ text, position, styles }) {
  const { color, weight, minFontSize, maxFontSize, align, mb, lineHeight } = styles;
  const { x, y, width } = position;

  return (
    <svg>
      <foreignObject x={x} y={y} width={width} height="100%">
        <div>
          {text.map((paragraph, i) => (
            <Typography key={i} color={color} weight={weight} minFontSize={minFontSize} maxFontSize={maxFontSize} align={align} mb={mb} lineHeight={lineHeight} >
              {paragraph}
            </Typography>
          ))}
        </div>
      </foreignObject>
    </svg>
  );
}

export default Text;