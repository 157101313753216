import React, { useRef, useEffect } from 'react';
import { userState } from 'Stores/User';
import { appState } from '../../Stores/App';
import { Helmet } from "react-helmet";
import CONFIG from '../../config';
import { PAGE_TITLES } from '../../constants';

import Partnerships from 'Components/Partnerships';
import Newsroom from 'Components/Newsroom';
import Tooltip from 'Components/Tooltip';
import Welcome from './Welcome/index';

import {
  LobbyWrapper,
  WelcomeScreen,
  LobbyBG,
  ModuleLink,
} from './styles';

function Lobby() {
  const scene = useRef();
  const {
    profile
  } = userState();
  const name = profile.forename ? profile.forename : '';

  const {
    overlay: hasOverlay,
    showPartnerships,
    showNewsroom,
    navigation,
    showNavigation,
    partnerships,
    newsroom,
    overlay,
  } = appState();

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }
  }, [navigation, showNavigation]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.LOBBY}</title>
      </Helmet>
      <LobbyWrapper ref={scene} hasOverlay={hasOverlay}>
        <LobbyBG />
        <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid slice" style={{ position: 'absolute', top: '0' }}>
          <foreignObject x="0" y="606" width="190" height="140">
            <WelcomeScreen>
              Welcome {name}
            </WelcomeScreen>
          </foreignObject>

          <foreignObject x="0" y="0" width="100%" height="100%">
            <Tooltip
              id="digital-commerce"
              index="1"
              intro="Evolving E-commerce"
              title="Coming soon"
              left="288"
              top="330"
              position="top"
            // to="/module/digital-commerce"
            />

            <Tooltip
              id="digital-account"
              index="2"
              intro="Dive into the world of personal finance, and look at how we can meet the needs of the modern account holder"
              title="Smarter Personal Finance"
              left="568"
              top="248"
              position="top"
              to="/module/digital-account"
            />

            <Tooltip
              id="visa-newsroom"
              index="4"
              intro="Stay up to date with the latest developments from the world page payments in the
              Newsroom"
              title="Newsroom"
              left="1335"
              top="272"
              position="top"
              handleClick={showNewsroom}
              to="#"
            />

            <Tooltip
              id="partnership"
              index="3"
              intro="Find out more about our partnerships"
              title="Partnerships"
              left="1530"
              top="310"
              position="top"
              handleClick={showPartnerships}
              to="#"
            />

            {/* TODO: Add back in when ready to launch section */}
            {/* <Tooltip
              id=""
              index="3"
              intro="Our playground. Discover early product development concepts, provide feedback and help shape the future of commerce"
              title="Coming soon"
              left="1720"
              top="272"
              position="top"
              to="#"
            /> */}


          </foreignObject>


          <ModuleLink>
            <rect fill="transparent" x="255" y="755" width="260" height="65" />
          </ModuleLink>

          <ModuleLink to="/module/digital-account">
            <rect fill="transparent" x="535" y="755" width="260" height="65" />
          </ModuleLink>

        </svg>
        <Welcome />
      </LobbyWrapper>

      {partnerships && overlay ? <Partnerships /> : null}
      {newsroom && overlay ? <Newsroom /> : null}
    </>
  )
}

export default Lobby;
