import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TooltipText = styled.div`
	width: 200px;
	text-align: center;
	line-height: 1.4;
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 0.25s ease, transform 0.25s ease;
`;

export const TooltipIntro = styled.div`
	font-size: 0.875em;
`;

export const TooltipTitle = styled.div`
	font-size: 18px;
	margin: 0.2777777778em 0 2em 0;
`;

export const TooltipMarker = styled.div`
	width: 30px;
	height: 30px;
	padding: 10px;
	background-color: rgba(17,17,17, 0.7);
	position: relative;
	border-radius: 100%;
	display: block;
	margin: 0 auto;
	transition: all 0.25s ease;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 8px;
		height: 8px;
		background-color: #F7B600;
		border-radius: 100%;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #F7B600;
		border-radius: 100%;
		border-bottom-color: transparent;
		transform: rotate(-45deg);
		opacity: 0;
		transition: opacity 0.25s ease;
	}
`;

export const TooltipBg = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(16,26,77, 0.8);
	background: radial-gradient(circle, rgba(14,23,78,0.8) 0%, rgba(5,4,38,0.8) 100%);
	display: none;
`;

export const TooltipChrome = styled.div`
	height: 100px;
	margin-top: -60px;

	&:after {
		content: '';
		width: 1px;
		height: 0;
		transition: height 0.25s ease;
		display: block;
		margin: 0 auto;
		background-color: #F7B600;
	}
`;

export const TooltipWrapper = styled(Link)`

	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: translate3d(0, 0, 0);
	text-align: center;
	color: #fff;
	font-weight: 300;
	cursor: pointer;
	text-decoration: none;
	text-align: center;

	${props => props.left && `
    	left: ${props.left}px;
  `}

	${props => props.top && `
    	top: ${props.top}px;
  `}

	&:hover {
		+ ${TooltipBg} {
			display: block;
			animation: fadeIn 0.25s;
		}

		${TooltipMarker} {

			background-color: rgba(17,17,17, 1);
			width: 40px;
			height: 40px;
			transform: translateY(-5px);

			&:after {
				background-color: #fff;
			}

			&:before {
				opacity: 1;
				transition: opacity 0.25s ease;
			}
		}

		${TooltipText} {
			opacity: 1;
			transform: translateY(0);
			transition: opacity 0.25s ease 0.125s ,transform 0.25s ease 0.125s;
		}

		${TooltipChrome} {
			&:after {
				height: 100px;
			}
		}
	}
`;
