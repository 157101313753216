import styled from 'styled-components';

export const ProductNav = styled.nav`
	position: fixed;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	right: 20px;
	z-index: 10;

	.ProductNav__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.ProductNav__title {
		position: absolute;
		bottom: 100%;
		transform: translate(8px) rotate(270deg);
	    transform-origin: bottom left;
	    left: 50%;
	    white-space: nowrap;
	    padding-left: 20px;
	}

	.ProductNav__item {
		position: relative;
		cursor: pointer;
		width: 10px;
		height: 35px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 10px;
			height: 10px;
			border-radius: 100%;
			z-index: 2;
			transition: background-color 0.15s ease;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 1px;
			height: 100%;;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		&.is-active {
			&:before {
				background-color: #F7B600;
			}
		}


	}

	.ProductNav__label {
		position: absolute;
		right: 100%;
		white-space: nowrap;
		font-size: 11px;
		line-height: 14px;
		text-transform: uppercase;
		padding-right: 10px;
		top: -2px;
		display: none;
	}

	.ProductNav__item {
		&:hover {
			.ProductNav__label {
				display: block;
			}
		}
	}

	// .ProductNav__item:before

	/* Theme variations */
	.ProductNav__list--light {
		color: #fff;
		.ProductNav__item {
			&:before {
				background-color: #fff;
			}
			&:after {
				background-color: #fff;
			}
			&.is-active {
			&:before {
				background-color: #1a1f71;
			}
		}
		}
	}

	.ProductNav__list--dark {
		color: #1a1f71;
		.ProductNav__item {
			&:before {
				background-color: #1a1f71;
			}
			&:after {
				background-color: #1a1f71;
			}
			&.is-active {
			&:before {
				background-color: #F7B600;
			}
		}
		}
	}

	.ProductNav__title--light {
		color: #fff;
	}

	.ProductNav__title--dark {
		color: #1a1f71;
	}

`;

export const ProductNavIndicator = styled.div.attrs(({ Progress, theme }) => ({
	style: { top: `${Progress}%` },
	color: `${theme}`
}))`
  	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 16px;
	height: 16px;
	z-index: 1;
	background-color: #fff;
	border-radius: 100%;
	margin-top: -3px;
`;
