import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
import PrivateRoute from 'Components/Auth/PrivateRoute';
import createActivityDetector from 'activity-detector';
import { refreshToken } from 'Services/auth';
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from './constants';
import pushDataLayer from './helpers/gtm';
import axiosConfig from './Services/axiosConfig';

import Header from 'Components/Header';
import Content from 'Components/Content';
import Module from 'Components/Module';
import Lobby from 'Components/Lobby';
import Login from 'Components/Auth/Login';
import Register from 'Components/Auth/Register';
import Activate from 'Components/Auth/Activate';
import ForgotPassword from 'Components/Auth/ForgotPassword';
import CreatePassword from 'Components/Auth/CreatePassword';
import ResetPassword from 'Components/Auth/PasswordReset';
import Product from 'Components/Product';
import SmarterPersonalFinance from 'Components/SmarterPersonalFinance';
import Mezzanine from 'Components/Mezzanine';
import MezzanineProjects from 'Components/MezzanineProjects';
import MezzanineProject from 'Components/MezzanineProjects/MezzanineProject';
import NotFound from 'Components/NotFound';
import Article from 'Components/Article';
import 'Styles/App.scss';

function useIdle(options) {
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on('idle', () => {
      setIsIdle(true)
    })
    activityDetector.on('active', () => {
      setIsIdle(false)
    })
    return () => activityDetector.stop()
  }, []);
  return isIdle;
}

function usePageViews() {
  let location = useLocation()
  useEffect(
    () => {
      pushDataLayer({ pagePath: location.pathname, pageName: document.title });
    },
    [location]
  )
}


function App() {
  // Check if user is idle after 1 minute
  const isIdle = useIdle({ timeToIdle: 120000 })

  useEffect(() => {
    // Every 2 minutes check if the user is idle if not refresh their token
    const interval = setInterval(() => {
      if (!isIdle) {
        console.log('not idle');
        refreshToken();
      }
    }, 240000);
    return () => clearInterval(interval);
  }, [isIdle]);

  useEffect(() => {
    axiosConfig.get('alive');
  }, []);

  usePageViews()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.VISA}</title>
      </Helmet>
      <Header />
      <Content>
        <Switch>
          <Route exact path="/login"><Login /></Route>
          <Route exact path="/register"><Register /></Route>
          <Route exact path="/activate/:id"><Activate /></Route>
          <Route exact path="/create-password"><CreatePassword /></Route>
          <Route exact path="/reset"><ResetPassword /></Route>
          <Route exact path="/forgot-password"><ForgotPassword /></Route>
          <PrivateRoute exact path="/"><Lobby /></PrivateRoute>
          <PrivateRoute exact path="/product/:id"><Product /></PrivateRoute>
          <PrivateRoute exact path="/smarter-personal-finance"><SmarterPersonalFinance /></PrivateRoute>
          <PrivateRoute exact path="/mezzanine"><Mezzanine /></PrivateRoute>
          <PrivateRoute exact path="/mezzanine/projects"><MezzanineProjects /></PrivateRoute>
          <PrivateRoute exact path="/mezzanine/projects/:project"><MezzanineProject /></PrivateRoute>
          <PrivateRoute path="/article/:story"><Article /></PrivateRoute>
          <PrivateRoute path="/module/:module"><Module /></PrivateRoute>
          <PrivateRoute path=""><NotFound /></PrivateRoute>
        </Switch>
      </Content>
    </>
  )
}

export default App;
