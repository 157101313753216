import styled from 'styled-components';
import starEmpty from './assets/Star-Outline.svg';
import starFilled from './assets/Star-Fill.svg';

export const Wrapper = styled.div`

 .where_heading {
 	text-align: center;
 	margin-bottom: 50px;
 	font-size: calc( 30px + (40 - 30) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
	color: #fff;
 }

 .row {
 	display: flex;
 	margin-left: -15px;
 	margin-right: -15px;
 }

 .title {
	font-size: calc( 18px + (30 - 18) * (100vw - 400px) / (1400 - 400));
   font-weight: 300;
}

 .col {
 	width: 33%;
 	padding-left: 15px;
 	padding-right: 15px;
 	text-align: center;

 	.btn {
 		background-color: #F7B600;
		color: #101a4d;
		display: inline-block;
		border-radius: 2em;
		padding: 1em 3em;
		line-height: 1.2;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 400;
		cursor: pointer;
		position: relative;
		z-index: 2;
		text-decoration: none;
 	}

 }

 .scene-container {
	 width: 100vw;
 }

`;

export const StarRatingWrapper = styled.div`
 background: #050616;
 padding: 10%;

 .rating-content {
	font-size: calc( 16px + (22 - 16) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
 }

.rating-select__container {
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: 24px;
	justify-content: center;


	&:not(:checked) > input {
		position: absolute;
		top: -9999px;
		clip: rect(0, 0, 0, 0);
	}

	&:not(:checked) > label {
		cursor: pointer;
		font-size: 180%;
		color: lightgrey;
	}


	.star {
		background: url(${starEmpty});
		background-repeat: no-repeat;
		height: 48px;
		width: 48px;
		margin: 0 3px;
	}

	& > input:checked ~ label {
		background: url(${starFilled});
		background-repeat: no-repeat;
	}

	&:not(:checked) > label:hover,
	&:not(:checked) > label:hover ~ label {
		background: url(${starFilled});
		background-repeat: no-repeat;
	}

	& > input:checked + label:hover,
	& > input:checked + label:hover ~ label,
	& > input:checked ~ label:hover,
	& > input:checked ~ label:hover ~ label,
	& > label:hover ~ input:checked ~ label {
		background: url(${starFilled});
		background-repeat: no-repeat;
	}
}
`;