import { gsap } from 'gsap';
import { SCREEN_ANIMATION_TYPES } from './constants';
import {
  CANVAS_HEIGHT,
  FLYOUT_WIDTH,
  SLIDE_DURATION,
} from '../constants';

function animateScreenContent(id, screenAnimationTypes) {
  const timeline = gsap.timeline();

  if (screenAnimationTypes.includes(SCREEN_ANIMATION_TYPES.MORPH)) {
    timeline.from(`#${id}-phone-morph-start`, { y: 0, duration: 40 }, "morph")
    timeline.to(`#${id}-phone-morph-start`, {
      duration: 40,
      morphSVG: {
        shape: `#${id}-phone-morph-end`,
      },
    }, "morph")
  }

  if (screenAnimationTypes.includes(SCREEN_ANIMATION_TYPES.FADE)) {
    timeline.from(`.${id}-phone-fade`, { opacity: 0, duration: 5 })
    if (document.querySelectorAll(`.${id}-phone-fade2`).length > 0) {
      timeline.from(`.${id}-phone-fade2`, { opacity: 0 }, "+=30")
    }
  }
  return timeline;
}

function animateFlyoutContent(id, swapFlyoutContent) {
  const timeline = gsap.timeline();
  if (swapFlyoutContent) {
    timeline.to(`#${id}-flyout-text1`, { opacity: 0, duration: 10 }, "+=30")
    timeline.from(`#${id}-flyout-text2`, { opacity: 0, duration: 10 }, "+=30")
    if (document.getElementById(`${id}-flyout-text3`)) {
      timeline.to(`#${id}-flyout-text2`, { opacity: 0, duration: 10 }, "+=30")
      timeline.from(`#${id}-flyout-text3`, { opacity: 0, duration: 10 }, "+=30")
    }
    timeline.to({}, { duration: 60 })
  }
  return timeline;
}

function animatePhone(id, config = {}) {
  const { screenAnimationTypes = [], swapFlyoutContent = false } = config;
  const timeline = gsap.timeline();
  timeline
    .from(`#${id}-phone`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: 10 }, "+=20")
    .add(animateScreenContent(id, screenAnimationTypes))
    .from(`#${id}-flyout-wrapper`, { x: `${FLYOUT_WIDTH}`, duration: 5 })
    .add(animateFlyoutContent(id, swapFlyoutContent))
    .to(`#${id}-flyout-wrapper`, { x: `${FLYOUT_WIDTH}`, duration: 10 }, "-=20")
    .to(`#${id}-phone-group`, { y: '-300%', duration: `${SLIDE_DURATION}` })
  return timeline;
}

function animateTextInOut(id) {
  const timeline = gsap.timeline();
  // Slide intro in
  timeline.from(`#${id}-text1`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: `${SLIDE_DURATION}` })
  // Slide intro out
  timeline.to(`#${id}-text1`, { y: `-${CANVAS_HEIGHT}`, duration: `${SLIDE_DURATION}` }, "+=30")
  if (document.getElementById(`${id}-text2`)) {
    // Slide secondary text in
    timeline.from(`#${id}-text2`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: `${SLIDE_DURATION}` }, "-=30")
    // Slide secondary text out
    timeline.to(`#${id}-text2`, { y: `-${CANVAS_HEIGHT}`, duration: `${SLIDE_DURATION}` }, "+=30")
  }
  return timeline;
}

function animatePageNav(id) {
  const timeline = gsap.timeline();
  timeline.fromTo(`#${id}-page-nav`, { opacity: 1, duration: 0.8 }, { opacity: 0, duration: 0.8 }, "+=30")
  return timeline;
}

function animateCubeIn(id) {
  const timeline = gsap.timeline();
  timeline.from(`#${id}-cube`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: 10 })
  return timeline;
}

function animateSectionHeader(id) {
  const timeline = gsap.timeline();
  timeline.from(`#${id}-title`, { opacity: 0, duration: 0.1 })
  return timeline;
}

function animationPause() {
  const timeline = gsap.timeline();
  timeline.to({}, { duration: 30 })
  return timeline;
}

export { animatePhone, animateTextInOut, animatePageNav, animateCubeIn, animateSectionHeader, animationPause };
