/**

 MORE INFORMATION FLYOUT
 ======================

 More information button and flyout

*/

import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ReactComponent as ImageInfoIcon } from './assets/info-icon.svg';
import {
  MoreButton,
  MorePanel,
  MoreText,
  MoreTitle
} from './styles';
import {
  Typography
} from '../styles';

function showMoreButton(id) {
  const tl = gsap.timeline();
  tl.fromTo(`#${id}-button`, { opacity: 0 }, { opacity: 1 });
  return tl;
}

function More({ id, movement, title, text, width, x, y, size }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const a = parseInt(x) + parseInt(width);
  const b = parseInt(x) - 150;
  const c = parseInt(y) + 60;

  useEffect(() => {
    const tl = gsap.timeline();
    tl.add(`#${id}-button`, { opacity: 0 });
  }, [id]);

  useEffect(() => {
    if (movement) {
      close(id);
      setIsOpen(false);
    }
  }, [movement, id]);

  const open = (id, width) => {
    const tl = gsap.timeline();
    tl.to(`#${id}-wrappper`, { x: `-${width}`, duration: 1 });
    return tl;
  }

  const close = (id) => {
    const tl = gsap.timeline();
    tl.to(`#${id}-wrappper`, { x: "0", duration: 1 })
    return tl;
  }

  const hideMoreButton = (id) => {
    const tl = gsap.timeline();
    tl.to(`#${id}-button`, { opacity: 0 });
    return tl;
  }

  const handleClick = () => {
    if (isOpen) return;
    const tl = gsap.timeline();
    tl.add(open(id, width), "open")
    tl.add(hideMoreButton(id), "open");
    setIsOpen(true);
  }

  return (
    <svg>
      <defs>
        <clipPath id={`${id}-moreMask`}>
          <rect x={x} y="0" width={width} height="100%" />
        </clipPath>
      </defs>
      <g clipPath={`url(#${id}-moreMask)`}>
        <foreignObject id={`${id}-wrappper`} x={a} y={y} width={width} height="200">
          <MorePanel size={size}>
            <MoreTitle size={size}>
              {title}
            </MoreTitle>
            <MoreText>
              {text}
            </MoreText>
          </MorePanel>
        </foreignObject>
      </g>

      <g id={`${id}-button`} onClick={handleClick}>
        <foreignObject x={b} y={c} width={width} height="100%">
          <MoreButton>
            <Typography color="#ffffff" weight="400" minFontSize="13" maxFontSize="13" align="right" mb="0">
              More Information
            </Typography>
            <ImageInfoIcon />
          </MoreButton>
        </foreignObject>
      </g>
    </svg>
  );
}

export { More, showMoreButton };
