import axios from 'axios';
import { getCookie, writeCookie } from 'helpers/cookies';
import CONFIG from '../config';
import { getToken, refreshToken } from 'Services/auth';

const instance = axios.create({
    baseURL: CONFIG.API_URL,
    withCredentials: true,
    xsrfCookieName: "xsrf-token",
    xsrfHeaderName: "x-csrf-token"
});

instance.interceptors.request.use(req => {
    const xcsrfToken = getCookie('xsrfToken');
    if (xcsrfToken) {
        req.headers['x-csrf-token'] = xcsrfToken;
    }
    const authToken = getToken();
    if (authToken) {
        req.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return req;
});

instance.interceptors.response.use(
    res => {
        if (res.headers['xsrf-token']) {
            writeCookie(
                'xsrfToken',
                res.headers['xsrf-token']
            );
        }
        return res;
    },
    err => {
        const errorCode = err?.response?.status;
        if (errorCode === 401 && window.location.pathname !== '/login') {
            refreshToken();
        }
    }
);

export default instance;
