import React, { useEffect } from 'react';
import { gsap } from 'gsap';

import {
  ButtonWrapper,
  Button,
  SROnly,
} from './styles';


const PlayButton = ({ id, className, movement }) => {
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const video = document.getElementById(`${id}-video`);

  useEffect(() => {
    if (movement && videoPlaying) {
      video.pause();
      video.currentTime = 0;
      const timeline = gsap.timeline();
      timeline.to(`#${id}-button-wrapper`, { visibility: 'visible' })
      timeline.to(`.${id}-intro-video`, { opacity: 0, duration: 0.5 });
      return timeline;
    }
  }, [movement]);

  useEffect(() => {
    // Video Controller
    gsap.to(
      document.querySelector(`.${id}-scene`), {
      scrollTrigger: {
        trigger: document.querySelector(`.${id}-scene`),
        start: "top top",
        end: "+=100%",
      },
    }
    );

  }, []);

  const playVideo = () => {
    const video = document.getElementById(`${id}-video`);
    video.play();
    setVideoPlaying(true);
    const timeline = gsap.timeline();
    timeline.to(`#${id}-button-wrapper`, { visibility: 'hidden' })
    timeline.to(`.${id}-intro-video`, { opacity: 1, duration: 0.5 });
    return timeline;
  }

  return (
    <ButtonWrapper id={`${id}-button-wrapper`} className={className}>
      <Button onClick={() => playVideo()}>
        <SROnly>Play video</SROnly>
      </Button>
    </ButtonWrapper>
  );
};

export default PlayButton;
