import React from 'react';
import Theme from '../../../../Theme';
import MoreWays from './MoreWays';
import StarRating from './StarRating';

function HowVisaCanHelp() {

	return (
		<>
			<Theme onEnter="dark">
				<MoreWays />
			</Theme>
			<Theme onEnter="light" onLeave="dark">
				<StarRating />
			</Theme>
		</>
	);
}

export default HowVisaCanHelp;