import styled from 'styled-components';
import playButton from '../assets/play-button.png';

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-image: url(${playButton});
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  width: 144px;
  height: 244px;
`;

export const SROnly = styled.span`
opacity: 0;
`;
