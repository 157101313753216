import React, { useState, useRef } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axiosConfig from '../../../Services/axiosConfig';
import { TextInput, Checkbox } from 'Components/Auth/Fields';
import CONFIG from '../../../config';
import { userState } from 'Stores/User';
import { refreshToken } from 'Services/auth';
import ReCAPTCHA from "react-google-recaptcha";
import ErrorMessages from '../ErrorMessages';
import { getApiErrorMessage } from '../helper';

function UpdateProfile() {
	const {
		profile,
		setProfile,
	} = userState();
	const userType = profile.userType;
	const [updated, setUpdated] = useState(false);
	const [errors, setErrors] = useState([]);
	const recaptchaRef = useRef(null);

	let endpoint = "identity/users/my-profile-basic";
	let validationObject = Yup.object({
		forename: Yup.string()
			.required("Required"),
		surname: Yup.string()
			.required("Required")
	})

	if (userType === "Public") {
		endpoint = "identity/users/my-profile-full";
		validationObject = Yup.object({
			forename: Yup.string()
				.required("Required"),
			surname: Yup.string()
				.required("Required"),
			companyName: Yup.string()
				.required("Required"),
			phoneNumber: Yup.string()
				.required("Required"),
			jobTitle: Yup.string()
				.required("Required"),
		})
	}

	const validationSchema = validationObject;

	return (
		<>
			<div>
				{updated ?
					<div className="successMessage">Details have been updated.</div>
					: ""}

				<ErrorMessages errors={errors} />

				<Formik
					enableReinitialize
					initialValues={{
						forename: profile.forename,
						surname: profile.surname,
						companyName: profile.companyName,
						phoneNumber: profile.phoneNumber,
						jobTitle: profile.jobTitle,
						acceptedFutureCommunications: profile.acceptedFutureCommunications,
					}}
					validationSchema={validationSchema}
					onSubmit={async (values) => {
						const token = await recaptchaRef.current.executeAsync();
						recaptchaRef.current.reset();
						setErrors([]);

						let data;

						if (userType === "Public") {
							data = {
								"forename": values.forename,
								"surname": values.surname,
								"companyName": values.companyName,
								"phoneNumber": values.phoneNumber,
								"jobTitle": values.jobTitle,
								"acceptedFutureCommunications": values.acceptedFutureCommunications,
							}
						} else {
							data = {
								"forename": values.forename,
								"surname": values.surname,
								"acceptedFutureCommunications": values.acceptedFutureCommunications,
							}
						}

						axiosConfig.put(
							`${endpoint}`,
							data,
							{
								headers: {
									'g-recaptcha-response': token
								},
							},
						)
							.then((response) => {
								let statusCode = response.data.statusCode;
								switch (statusCode) {
									case 200:
										const { data: { returnedObject } } = response;
										setUpdated(true);
										if (returnedObject) {
											setProfile(returnedObject);
										}
										break;
									case 400:
										refreshToken();
										setErrors(response.data.errorMessages);
										break;
									default:
										console.error('API error')
								}
							})
							.catch((error) => {
								const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'Unable to confirm account';
								setErrors([`${message}`])
							});;
					}}
				>
					{({ values, handleChange, handleSubmit }) => (
						<Form>
							<TextInput
								label="First Name"
								placeholder="First Name"
								name="forename"
								type="text"
								className="text-field"
							/>

							<TextInput
								label="Last Name"
								placeholder="Last Name"
								name="surname"
								type="text"
								className="text-field"
							/>

							{userType === "Public" ?
								<>
									<TextInput
										label="Company Name"
										placeholder="Company Name"
										name="companyName"
										type="text"
										className="text-field"
									/>

									<TextInput
										label="Phone Number"
										placeholder="Phone Number"
										name="phoneNumber"
										type="text"
										className="text-field"
									/>

									<TextInput
										label="Job Title"
										placeholder="Job Title"
										name="jobTitle"
										type="text"
										className="text-field"
									/>
								</>
								: ""}

							<Field
								name="acceptedFutureCommunications"
								render={() => (
									<Checkbox
										id="subscribe"
										name="acceptedFutureCommunications"
										value={values.acceptedFutureCommunications}
										checked={values.acceptedFutureCommunications}
										onChange={handleChange}
									>
										By checking this box, you agree to receive marketing communications about Visa business products and services. You can unsubscribe at any time. For more information on how Visa collects, uses, and protects your personal information, please review our Global Privacy Notice
									</Checkbox>
								)}
							/>

							<div className="form__footer">
								<button className="form-btn" type="submit">
									Update Details
								</button>
							</div>
							<div>
								<a href="https://www.visa.co.uk/legal/privacy-policy.html" className="form-link" target="_blank" rel="noopener noreferrer">Privacy policy</a>
							</div>
							<div>
								<a href="//policy.cookiereports.com/c50e3639-en-gb.html" className="form-link">Cookie policy</a>
							</div>
							<div>
								<a href="mailto:vneeurope@visa.com" className="form-link">Contact us</a>
							</div>
							<ReCAPTCHA
								ref={recaptchaRef}
								size="invisible"
								sitekey={CONFIG.RECAPTCHA_SITE_KEY}
								onChange={handleSubmit}
							/>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}

export default UpdateProfile;
