import React, { useRef, useEffect } from 'react';
import Hero from 'Components/Product/Hero';
import Theme from '../../../../Theme';
import MobilePayments from 'Components/Product/VisaSecure/Micro/MobilePayments';
import VideoHero from 'Components/Product/VisaSecure/Micro/VideoHero';

function Micro() {

  return (
    <>

      <VideoHero />

      <Theme onEnter="dark" onLeave="light">
        <MobilePayments />
      </Theme>
    </>
  );
}

export default Micro;