import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { appState } from '../../Stores/App';
import DigitalCommerce from './DigitalCommerce';
import DigitalAccount from './DigitalAccount';

function Module() {
  const location = useLocation();
  const currentUrl = location.pathname.split("/")[2];

  const {
    navigation,
    showNavigation,
  } = appState();

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }
  }, [navigation, showNavigation]);

  function ActiveModule() {
    if (currentUrl === "digital-commerce") {
      return <DigitalCommerce />;
    }

    if (currentUrl === "digital-account") {
      return <DigitalAccount />;
    }
  }

  return (
    <ActiveModule />
  );
}

export default Module;
