import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const OutboundLink = styled.a`
    color: #1A1F71;
    font-size: calc( 20px + (20 - 20) * (100vw - 400px) / (1920 - 400));
    font-weight: 200;
`;

export const Paragraph = styled.p`
    color: #1A1F71;
    font-size: calc( 20px + (20 - 20) * (100vw - 400px) / (1920 - 400));
    font-weight: 200;
`;

export const HeadingH1 = styled.p`
    color: #1A1F71;
    font-size: calc( 72px + (72 - 72) * (100vw - 400px) / (1920 - 400));
    font-weight: 400;
`;

export const HeadingH2 = styled.p`
    color: #1A1F71;
    font-size: calc( 36px + (36 - 36) * (100vw - 400px) / (1920 - 400));
    font-weight: 200;
`;
