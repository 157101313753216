import React, { useRef, useEffect } from 'react';
import { DropTarget } from 'react-dnd';
import { productState } from 'Stores/Product';

export const ItemTypes = {
  BOX: 'box',
}

const style = {
  width: '375px',
  height: '375px',
  borderRadius: '50%',
  color: '#101a4d',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  fontWeight: '700',
};

const DropZone = ({ canDrop, isOver, connectDropTarget, type, result, answerSnippet }) => {

  const {
    smarterPersonalFinanceAnswer,
    setSmarterPersonalFinanceAnswer
  } = productState();

  const isActive = canDrop && isOver;
  let backgroundColor = '#F7B600';

  if (isActive) {
    backgroundColor = '#fff';
  } else if (canDrop) {
    backgroundColor = '#101a4d';
  }

  if (result) {
    backgroundColor = '#fff';
  }

  if (smarterPersonalFinanceAnswer === 'y') {
    backgroundColor = '#299e3c';
  } else if (smarterPersonalFinanceAnswer === 'n') {
    backgroundColor = '#9f2639'
  } else {
    backgroundColor = '#F7B600'
  }

  useEffect(() => {
    if (type && type.answer) {
      setSmarterPersonalFinanceAnswer(type.answer);
    }

  }, [result]);

  return (
    <div ref={connectDropTarget} style={{ ...style, backgroundColor }} role="DropZone">
      {smarterPersonalFinanceAnswer === "n" ? <div style={{ fontSize: '31px', color: '#FFFFFF' }}>Incorrect</div> : ""}
      {smarterPersonalFinanceAnswer === "y"
        ?
        <div style={{ fontSize: '31px', color: '#FFFFFF' }}>
          Correct
          <div style={{ fontSize: '18px', fontWeight: '200', textTransform: 'inital', color: '#FFFFFF', marginTop: '0.25em' }}>{answerSnippet}</div>
        </div>
        :
        ""
      }
      {!smarterPersonalFinanceAnswer ? <span>{isActive ? 'Release to drop' : 'Drag your answer here'}</span> : ""}
    </div>
  );

};

export default DropTarget(ItemTypes.BOX,
  {
    drop: () => ({ name: 'DropZone' }),
  }, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    type: monitor.getItem(),
    result: monitor.getDropResult()
  }
  ))(DropZone);
