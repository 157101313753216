import React, { useRef, useEffect } from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from 'gsap';
import { Hero } from './styles';

function VideoHero() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {

    const element = ref.current;

    gsap.to(
      element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: "+=150%",
          pin: true,
          scrub: true
        },
      }
    );

    gsap.timeline({
      scrollTrigger: {
        trigger: element.querySelector(".marker"),
        start: "top top+=10",
        end: "bottom",
        scrub: true,
        // markers: true
      }
    })
    
    .to(".videoHero__title--1",{ opacity: "0" })
    .from(".videoHero__title--2",{ opacity: "0"})
    .to(".videoHero__title--2",{ y: "-100%", opacity: "0"})

  }, []);

  return (

    <Hero className="videoHero" ref={ref}>
      <div className="marker"></div>
      <video autoPlay playsInline muted loop src="https://player.vimeo.com/external/528288389.sd.mp4?s=c4f804235893608edebbb962e08394fac58e6bb0&profile_id=165"/>     
      <p className="videoHero__title videoHero__title--1">It’s not just consumer behaviour driving changes</p>
      <p className="videoHero__title videoHero__title--2">Changing regulations have impacted the authentication process</p>
    </Hero>

  );

}

export default VideoHero;