import styled from 'styled-components';

export const Hero = styled.div`

  position: relative;
  height: 100vh;
  width: 100vw;
  color: #fff;
  background-color: #000;
  &:after {
    background: black;
    content: "";
    display: block;
    left: 0;
    top: 0;
    opacity: .3;
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate3d(-50%, -50%, 0);
  }

  .videoHero__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-weight: 300;
    line-height: 1;
    width: 100%;
    padding: 0 15%;
    max-width: 100%;
    margin: 0;
    font-size: calc( 30px + (60 - 30) * (100vw - 400px) / (1400 - 400));
    text-align: center;
    z-index: 1;
  }

  .marker {
    position: absolute;
    top: 50%;
    height: 150vh;
  }

`;