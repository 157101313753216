import styled from 'styled-components';

export const Primary = styled.div`

	position: absolute;
	padding-top: 10%;
	width: 40%;
	height: 100vh;
	left: 40px;

`;

export const Secondary = styled.div`
	position: absolute;
	bottom: 10%;
	left: 45%;


	.stat__number {
		font-size: calc( 70px + (350 - 70) * (100vw - 400px) / (1400 - 400));
		font-weight: 600;
		line-height: 1;
	}

	.stat__description {
		color: #F7B600;
		font-size: calc( 20px + (40 - 20) * (100vw - 400px) / (1400 - 400));
		font-weight: 300;
		transform: translate3d(10px, -15px, 0);
		text-align: center;
		width: 70%;

		small {
			color: #FFF;
			display: block;
			line-height: 1;
			margin-top: 0.5em;
			font-size: calc( 8px + (10 - 8) * (100vw - 400px) / (1400 - 400));

			a {
				display: block;
				line-height: 1.2;
				color: inherit;
			}
		}

	}

`;

export const OnlineSpend = styled.div`
	position: absolute;
	padding-top: 10%;
	width: 50%;
	left: 40px;
	height: 100vh;

	h4 {
		font-size: calc( 24px + (32 - 24) * (100vw - 400px) / (1400 - 400));
		font-weight: 300;
		line-height: 1.2;
		color: #F7B600;
		margin-bottom: 10px;

		span {
			display: inline;
		}
	}

	.onlineSpend__description {
		font-size: calc( 20px + (40 - 20) * (100vw - 400px) / (1400 - 400));
	}
`;

export const Trillion = styled.div`
	position: absolute;
	left: 3%;
	bottom: 50px;

	.trillion__number {
		display: flex;
		align-items: flex-start;
	}

	.trillion__unit {
		color: #F7B600;
		font-weight: 600;
		font-size: calc( 50px + (100 - 50) * (100vw - 400px) / (1400 - 400));

	}

	.trillion__value {
		line-height: 1;
		font-size: calc( 120px + (300 - 120) * (100vw - 400px) / (1400 - 400));
		font-weight: bold;
	}
`;

export const TrillionIntro = styled.div`
position: absolute;
	padding-top: 20%;
	width: 100%;
	left: 40px;
	height: 100vh;

.trillion__intro {
	line-height: 1;
	color: #fff;
	font-weight: 300;
	font-size: calc( 20px + (40 - 20) * (100vw - 400px) / (1400 - 400));
}
`;

export const ConnectedDevices = styled.div`
	position: absolute;
	padding-top: 10%;
	height: 100vh;
	width: 50%;
	left: 40px;
`;

export const TotalSpend = styled.div`
	position: absolute;
	right: 20%;
	bottom: 50px;

	.stat {

	}

	.stat__number {
		font-size: calc( 70px + (350 - 70) * (100vw - 400px) / (1400 - 400));
		font-weight: 600;
		line-height: 1;
	}

	.stat__description {
		color: #F7B600;
		font-size: calc( 20px + (40 - 20) * (100vw - 400px) / (1400 - 400));
		font-weight: 300;
		transform: translate3d(10px, -15px, 0);
		text-align: center;
		width: 70%;
		position: relative;

		small {
			color: #FFF;
			display: block;
			line-height: 1;
			margin-top: 0.5em;
			font-size: calc( 8px + (10 - 8) * (100vw - 400px) / (1400 - 400));

			a {
				display: block;
				line-height: 1.2;
				color: inherit;
			}
		}

	}
	.stat__unit {
		line-height: 1;
		color: #F7B600;
		font-weight: 700;
		font-size: 0.3em;
	}
`;
