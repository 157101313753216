import styled from 'styled-components';


export const Heading = styled.div`
  font-size: calc( 24px + (30 - 24) * (100vw - 400px) / (1400 - 400));
  font-weight: 300;
  text-align: center;
  color: #101a4d;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
`;

export const Wrapper = styled.div`
  
 .Carousel {
   margin: 0 auto;
 }

`;