import styled from 'styled-components';
import image from './assets/mezz_bg.jpg';

export const MezzanineWrapper = styled.div`
  ${props => props.hasOverlay && `
    opacity: 0.15;
    pointer-events: none;
  `}
`;

export const MezzanineBG = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  margin-bottom: -100vh;
  overflow: hidden;
`;

