const countDecimals = (a) => (a.toString().split('.')[1] ? a.toString().split('.')[1].length : 0);
const maxDecimals = (a, b) => Math.max(countDecimals(a), countDecimals(b));
const convertToInteger = (number, factor) => number * 10 ** factor;
const convertToFloatingPoint = (a, decimalPlaces) => parseFloat(a) / 10 ** decimalPlaces;


export function getXPos(x) {
    if (!x || isNaN(parseFloat(x))) return null;
    const width = 1920;
    const pos = Number.parseFloat((x * 100) / width).toFixed(2);
    const string = `${pos}%`;
    return string;
}

export function getFromToXPos(a, b) {
    if (!a || isNaN(parseFloat(a))) return null;
    if (!b || isNaN(parseFloat(b))) return null;
    const width = window.innerWidth;
    const distance = b - a;
    const pos = Number.parseFloat((distance * 100) / width).toFixed(2);
    const string = `${pos}%`;
    return string;
}

export function getYPos(y) {
    if (!y || isNaN(parseFloat(y))) return null;
    const height = 1080;
    const pos = 100 - (Number.parseFloat((y * 100) / height).toFixed(2));
    const string = `-${pos}%`;
    return string;
}

export function getYPosBottom(y) {
    if (!y || isNaN(parseFloat(y))) return null;
    const height = 1080;
    const pos = (Number.parseFloat((y * 100) / height).toFixed(2));
    const string = `-${pos}%`;
    return string;
}

export function getWidth(number) {
    if (!number || isNaN(parseFloat(number))) return null;
    const width = 1920;
    const pos = Number.parseFloat((number * 100) / width).toFixed(2);
    const string = `${pos}%`;
    return string;
}

export function getHeight(number) {
    if (!number || isNaN(parseFloat(number))) return null;
    const height = window.innerHeight;
    const pos = Number.parseFloat((number * 100) / height).toFixed(2);
    const string = `${pos}%`;
    return string;
}

export function getScale(a, b) {
    if (!a || isNaN(parseFloat(a))) return null;
    if (!b || isNaN(parseFloat(b))) return null;
    const pos = Number.parseFloat(b / a).toFixed(2);
    return pos;
}

export function minus(a, b) {
    if (!a || isNaN(parseFloat(a))) return null;
    if (!b || isNaN(parseFloat(b))) return null;
    const decimalPlaces = maxDecimals(a, b);
    const sumInteger = convertToInteger(a, decimalPlaces) - convertToInteger(b, decimalPlaces);
    const sumFloatingPoint = convertToFloatingPoint(sumInteger, decimalPlaces);

    return sumFloatingPoint;
}

export function getFlyoutX(x) {
    return x - 248;
}

export function getFlyoutMaskX(x) {
    return x - 15;
}

export function getFlyoutY(y) {
    return y - 575;
}
