import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import pushDataLayer from '../../../helpers/gtm';
import { productState } from 'Stores/Product';
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from '../../../constants';

import { ProductNav, ProductNavIndicator } from './styles';

function Navigation() {
	gsap.registerPlugin(ScrollToPlugin);
	const [id, setId] = React.useState();
	const location = useLocation();

	const {
		progress
	} = productState();

	let introProgress = 0;
	let macroProgress = 0;
	let microProgress = 0;
	let targetProgress = 0;
	let customerProgress = 0;
	let capabilitiesProgress = 0;
	let howProgress = 0;

	useEffect(() => {
		if (!id) return;
		pushDataLayer({ pagePath: `${location.pathname}/${id}`, pageName: `${document.title} ${id}` });
	}, [id, location]);

	if (progress) {

		let intro = progress.find(section => section.id === 'introduction');
		if (intro) {
			introProgress = intro.progress
		}

		let macro = progress.find(section => section.id === 'macro-content');
		if (macro) {
			macroProgress = macro.progress
		}

		let micro = progress.find(section => section.id === 'micro-content');
		if (micro) {
			microProgress = micro.progress
		}

		let target = progress.find(section => section.id === 'target-audience');
		if (target) {
			targetProgress = target.progress
		}

		let customer = progress.find(section => section.id === 'customer-experience');
		if (customer) {
			customerProgress = customer.progress
		}

		let capabilities = progress.find(section => section.id === 'capabilities');
		if (capabilities) {
			capabilitiesProgress = capabilities.progress
		}

		let how = progress.find(section => section.id === 'how-visa-can-help');
		if (how) {
			howProgress = how.progress
		}
	}

	const jumpTo = (id) => {
		if (id) {
			console.log('id', id);
			gsap.to(window, 0.5, { scrollTo: { y: id, offsetY: -1 } });
			setId(id);
		}
	}

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLES.VISA_SECURE}</title>
			</Helmet>
			<ProductNav className="ProductNav">

				<span className="ProductNav__title">Visa Secure</span>

				<ul className="ProductNav__list">

					<li onClick={() => { jumpTo('#introduction') }} className={`ProductNav__item ${introProgress > 0 && introProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Introduction</span>
						{introProgress > 0 && introProgress < 100 ?
							<ProductNavIndicator Progress={introProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#macro-content') }} className={`ProductNav__item ${macroProgress > 0 && macroProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Macro Content</span>
						{macroProgress > 0 && macroProgress < 100 ?
							<ProductNavIndicator Progress={macroProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#micro-content') }} className={`ProductNav__item ${microProgress > 0 && microProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Micro Content</span>
						{microProgress > 0 && microProgress < 100 ?
							<ProductNavIndicator Progress={microProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#target-audience') }} className={`ProductNav__item ${targetProgress > 0 && targetProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Target Audience</span>
						{targetProgress > 0 && targetProgress < 100 ?
							<ProductNavIndicator Progress={targetProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#customer-experience') }} className={`ProductNav__item ${customerProgress > 0 && customerProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Customer Experience</span>
						{customerProgress > 0 && customerProgress < 100 ?
							<ProductNavIndicator Progress={customerProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#capabilities') }} className={`ProductNav__item ${capabilitiesProgress > 0 && capabilitiesProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Capabilities</span>
						{capabilitiesProgress > 0 && capabilitiesProgress < 100 ?
							<ProductNavIndicator Progress={capabilitiesProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#how-visa-can-help') }} className={`ProductNav__item ${howProgress > 0 && howProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">How Visa can help</span>
						{howProgress > 0 && howProgress < 100 ?
							<ProductNavIndicator Progress={howProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#end') }} className="ProductNav__item"></li>

				</ul>
			</ProductNav>
		</>
	);
}

export default Navigation;