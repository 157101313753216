import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Hero from '../../../Hero';
import image from './assets/visa_spf_personas.jpg';
import { ReactComponent as Create } from './assets/create.svg';
import { ReactComponent as Pay } from './assets/pay.svg';
import { ReactComponent as Manage } from './assets/manage.svg';
import { ReactComponent as Engage } from './assets/engage.svg';

import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
  TypographyHeading1,
  TypographyHeading3,
} from '../../../styles';
import {
  PositionOne,
  PositionTwo,
  PositionThree,
  PositionFour,
  PositionFive,
  PositionSix,
  PositionSeven,
  PositionEight,
  PositionNine,
  NavigateTo,
} from './styles';

function SlideOne() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'audience__slide-1__animation';
  const timeline = gsap.timeline();

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
      }
    })

  }, []);

  const jumpTo = (id) => {
    if (id) {
      timeline.to(window, 0.5, { scrollTo: { y: id, offsetY: -1 } });
    }
  }


  return (
    <Wrapper ref={ref}>
      <Section light>
        <SectionBG light />
        <Scene Height="100vh" className={`${ID}-scene`}>
          <Hero backgroundImage={image} />
          <div className="container">
            <div className="scene-content">

              <PositionNine>
                <TypographyHeading3 color="#1A1F71">
                  Meet our explorers and follow their journey
                </TypographyHeading3>
              </PositionNine>

              <PositionOne>
                <Create />
              </PositionOne>

              <PositionTwo>
                <Pay />
              </PositionTwo>

              <PositionThree>
                <Manage />
              </PositionThree>

              <PositionFour>
                <Engage />
              </PositionFour>

              <NavigateTo onClick={() => { jumpTo('#create') }}>
                <PositionFive>
                  <TypographyHeading1>
                    <h3>Create</h3>
                  </TypographyHeading1>
                </PositionFive>
              </NavigateTo>

              <NavigateTo onClick={() => { jumpTo('#pay') }}>
                <PositionSix>
                  <TypographyHeading1>
                    <h3>Pay</h3>
                  </TypographyHeading1>
                </PositionSix>
              </NavigateTo>

              <NavigateTo onClick={() => { jumpTo('#manage') }}>
                <PositionSeven>
                  <TypographyHeading1>
                    <h3>Manage</h3>
                  </TypographyHeading1>
                </PositionSeven>
              </NavigateTo>

              <NavigateTo onClick={() => { jumpTo('#engage') }}>
                <PositionEight>
                  <TypographyHeading1>
                    <h3>Engage</h3>
                  </TypographyHeading1>
                </PositionEight>
              </NavigateTo>


            </div>
          </div>
        </Scene>
      </Section>
    </Wrapper>
  );
}

export default SlideOne;