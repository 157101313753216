import React, { useRef, useEffect } from 'react';
import { Wrapper } from './styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import TooltipHelper from 'Components/TooltipHelper';


import { Section, SectionBG, Scene } from 'Components/Product/styles';
import Reveal from 'Components/Product/Reveal';

import phone from './assets/Payment-declined.png';

function Fraud() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const element = ref.current;
    const video = videoRef.current;

    const playVideo = () => {
      // console.log('play video');
      video.play();
    }

    const resetVideo = () => {
      console.log('reset video');
      // video.current.play();
    }

    const leaveVideo = () => {
      // video.pause();
      // video.current.play();
    }

    const leaveBackVideo = () => {
      // console.log('leave back video');
      video.pause();
      // videoRef.node.seek(0);
    }

    // Video Controller
    gsap.to(
      element, {
      scrollTrigger: {
        trigger: element,
        start: "top top",
        onEnter: () => playVideo(),
        // onEnterBack: () => resetVideo(),
        // onLeave: () => leaveVideo(),
        onLeaveBack: () => leaveBackVideo(),
      },
    }
    );

    // Pin Section
    gsap.to(
      element.querySelector(".section-1"), {
      scrollTrigger: {
        trigger: element.querySelector(".section-1"),
        start: "top top",
        end: "+=600%",
        pin: true,
        scrub: true
      },
    }
    );

    // Timeline
    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true
      }
    })
      .from(".online-growth", { x: "300%" })
      .from(".growthLockup", { y: "300%" }, "anim-group-1")
      .fromTo(".billion__1", { x: "100%" }, { x: "-100%", duration: 1.8 }, "anim-group-billion")
      .fromTo(".billion__2", { x: "100%" }, { x: "-100%", duration: 2 }, "anim-group-billion")
      .from(".legal", { y: "300%" }, "anim-group-1")
      .to(".scene-1", { x: "-100%" }, "group")
      .to(".scene-1-bg", { x: "-100%" }, "group")
      .from(".scene-2", { x: "100%" }, "group")
      .to(".central-title", { y: "-100%" })
      .from(".leading-to", { x: "110%" })
      .to(".leading-to", { x: "-60%" })
      .from(".authenticating", { y: "150%" })
      .to(".authenticating__device", { y: "-150%" })
  }, []);


  return (
    <Wrapper id="fraud" ref={ref}>

      <Section>

        <SectionBG className="scene-1-bg">
          <video ref={videoRef} playsInline muted loop src="https://player.vimeo.com/external/528288044.sd.mp4?s=94cc133d6494718f9d2591c3ba28c75fbda5b326&profile_id=165" />
        </SectionBG>

        <Scene Height="100vh" className="section-1">

          <div className="container">

            <div className="scene scene-1">
              <div className="online-growth">
                With growth in online commerce, opportunistic criminals will continue to try and defraud customers
                </div>

              <div className="growthLockup">

                <div className="growth-number">
                  <span className="growth-number__unit">$</span>
                  <span className="growth-number__value">1.8</span>
                </div>

                <div className="billion">
                  <span className="billion__1">billion in fraud</span>
                  <span className="billion__2">over two years</span>
                </div>

              </div>

              <div className="legal">
                *Europe issued card not present fraud in total for the for the two years ending September 2020<br />
                  Visa Fraud Dashboard
                </div>
            </div>

            <div className="scene scene-2">

              <div className="central-title">
                The threat of fraud can drive a cautious approach to payment authorisation
                </div>

              <div className="leading-to">
                <div className="leading-to__start">this can have a negative impact on the customer experience as</div>
                <div className="leading-to__stat">
                  <div className="statLockup">
                    <div className="statLockup__value">66</div>
                    <div className="statLockup__details">of E-commerce transactions declined due to fear of fraud, are actually legitimate
                    <TooltipHelper
                        id="tooltip-fraud"
                        index="2"
                        title="https://www.riskified.com/blog/how-consumers-respond-to-false-declines/"
                        x="0"
                        y="0"
                        position="bottom"
                      /></div>
                  </div>
                  <div className="statLockup__unit">%</div>
                </div>
              </div>
              <div className="authenticating">

                <div>
                  <div className="authenticating__wrapper">
                    <Reveal
                      title="Therefore identifying and authenticating customers is key to prevent lost revenue"
                      content={`Lost revenue due to false declines is project to reach $433 billion in 2021, nearly 70 times more than losses from fraud itself.`}
                      tooltipTitle="https://news.cardnotpresent.com/news/report-false-declines-lead-to-more-losses-than-fraud"
                      tooltipID="fraud"
                      x= "-240"
                      y= "0"
                    />
                  </div>
                </div>

              </div>

            </div>

          </div>

        </Scene>

      </Section>


    </Wrapper>
  );
}

export default Fraud;