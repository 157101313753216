import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #050616;
  padding: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
	font-size: calc( 18px + (30 - 18) * (100vw - 400px) / (1400 - 400));
  font-weight: 300;
  margin-top: 0;
  padding: 0 0.5em;
`;

export const Content = styled.p`
	font-size: calc( 16px + (16 - 16) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
  line-height: 1.5;
  padding: 0 0.5em;
`;

export const ThankYouContent = styled.p`
	font-size: calc( 16px + (16 - 16) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
  line-height: 1.5;
  padding: 0 3em;
  margin: 0;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  color: transparent;
  width: fit-content;
  max-width: 48px;
  padding: 0.3em;

  &:hover {
    color: #f7b63f;
  }

  ${props => props.id <= props.rating && `
    color: #f7b63f;
  `}
`;

export const StarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 3.5em;
`;
