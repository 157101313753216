import styled from 'styled-components';

export const Wrapper = styled.div`
  .slick-arrow {
    z-index: 1;
    width: 39px;
    height: 39px;
    top: 55%;
    -webkit-appearance: none;

    &.slick-disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .slick-prev {
    left: 10%;
    transform: rotate(180deg);
  }

  .slick-next {
    right: 15%;
  }
`;

export const Container = styled.div.attrs(({ items, offset }) => ({
  style: {
    width: `${items * 100}vw`,
    transform: `translateX(-${offset * 100}vw)`
  }
}))`
  transition: transform 0.5s ease;
  display: flex;
`;
