import styled from 'styled-components';

export const Wrapper = styled.div`

  position: relative;
  z-index: 2;

  .scene {
  	position: relative;
  	width: 100%;
  	height: 100vh;
  }

  .BusinessBenefits {
    position: absolute;
    top: 50%;
    width: 40%;
    transform: translateY(-50%);
    color: #101a4d;
    font-size: calc( 32px + (40 - 32) * (100vw - 400px) / (1400 - 400));
    font-weight: 300;
  }

  .BenefitsNumbered {
    position: absolute;
    top: 55%;
    left: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 80px;
    transform: translateY(-50%);
  }

  .BenefitsNumbered__heading {
    color: #101a4d;
    font-size: calc( 36px + (44 - 36) * (100vw - 400px) / (1400 - 400));
    font-weight: 300;
    margin: 0;
    text-align: center;
  }

  .BenefitsRow {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }

  .BenefitsRow__item {
    width: 33.33333%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .BenefitsRow__primary {
    color: #101a4d;
    font-size: calc( 70px + (300 - 70) * (100vw - 400px) / (1400 - 400));
    font-weight: 700;
    padding-right: 15px;
  }

  .BenefitsRow__secondary {

    .BenefitsRow__icon {
      width: 100%;
      max-width: 100%;
      display: block;
      padding: 0 30%;
    }

    .reveal__title {
      color: #101a4d;
      font-size: calc( 24px + (32 - 24) * (100vw - 400px) / (1400 - 400));
    }

    .reveal__content { color: #101a4d; }

  }

`;