import React, { useRef, useEffect } from 'react';
import { Helmet } from "react-helmet";

import { appState } from '../../Stores/App';
import { PAGE_TITLES } from '../../constants';

import {
  MezzanineWrapper,
  MezzanineBG,
} from './styles';

function Mezzanine() {
  const scene = useRef();
  
  const {
    navigation,
    showNavigation,
  } = appState();

  useEffect(() => {
    if (navigation === false) {
      showNavigation();
    }
  }, [navigation, showNavigation]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.MEZZANINE}</title>
      </Helmet>
      <MezzanineWrapper ref={scene}>
        <MezzanineBG />
      </MezzanineWrapper>
    </>
  )
}

export default Mezzanine;
