import React, { useState, useEffect, useRef } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import axiosConfig from '../../../Services/axiosConfig';
import { TextInput } from 'Components/Auth/Fields';
import CONFIG from '../../../config';
import { PAGE_TITLES } from '../../../constants';
import Marquee from 'Components/Auth/Marquee';
import ReCAPTCHA from "react-google-recaptcha";
import { appState } from '../../../Stores/App';
import ErrorMessages from '../ErrorMessages'
import { getApiErrorMessage } from '../helper';
import { Helmet } from "react-helmet";

function CreatePassword() {
	const [passwordSet, setPasswordSet] = useState(false);
	const [errors, setErrors] = useState([]);
	const recaptchaRef = useRef(null);

	const {
		navigation,
		hideNavigation,
	} = appState();

	useEffect(() => {
		if (navigation === true) {
			hideNavigation();
		}
	}, [navigation, hideNavigation]);

	const validationSchema = Yup.object({
		newPassword: Yup.string()
			.required("Required"),
		confirmNewPassword: Yup.string()
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
	})

	useEffect(() => {
		hideNavigation();
	}, []);

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLES.CREATE_PASSWORD}</title>
			</Helmet>
			<Marquee />
			<div className="auth">
				<div className="authWrapper">
					<div className="formWrapper">
						<ErrorMessages errors={errors} />

						{passwordSet ?
							<div className="successMessage">Your Password has been updated.</div>
							:
							<>
								<Formik
									initialValues={{
										currentPassword: '',
										newPassword: '',
										confirmNewPassword: '',
									}}
									validationSchema={validationSchema}
									onSubmit={async (values) => {
										const token = await recaptchaRef.current.executeAsync();
										recaptchaRef.current.reset();
										setErrors([]);

										axiosConfig.put(
											`/identity/users/create-password`,
											{
												newPassword: values.newPassword,
												confirmNewPassword: values.confirmNewPassword
											},
											{
												headers: {
													'g-recaptcha-response': token
												}
											}
										)
											.then((response) => {
												let statusCode = response.data.statusCode;
												switch (statusCode) {
													case 200:
														setPasswordSet(true);
														break;
													case 403:
														setErrors(response.data.errorMessages);
														break;
													case 400:
														setErrors(response.data.errorMessages);
														break;
													default:
														console.error('API error')
												}
											})
											.catch((error) => {
												const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'Unable to confirmm account';
												setErrors([`${message}`])
											});
									}
									}
								>
									{({ values, handleChange, handleBlur, handleSubmit }) => (
										<form onSubmit={handleSubmit} className="form-setup">
											<h2 className="form-header">Create Password</h2>
											<div className="form-input__wrapper">
												<TextInput
													type="password"
													value={values.newPassword}
													label="New Password"
													placeholder="Password"
													name="newPassword"
													onChange={handleChange}
													onBlur={handleBlur}
													className="text-field"
													helptext={`Minimum 8 characters, must include numbers and letters`}
												/>
												<TextInput
													type="password"
													value={values.confirmNewPassword}
													label="Re-enter password"
													placeholder="Password"
													name="confirmNewPassword"
													onChange={handleChange}
													onBlur={handleBlur}
													className="text-field"
													helptext={`Re-enter to confirm password`}
												/>
											</div>
											<div className="form__footer btn-center">
												<button type="submit" className="form-btn">
													Create password
												</button>
											</div>
											<ReCAPTCHA
												ref={recaptchaRef}
												size="invisible"
												sitekey={CONFIG.RECAPTCHA_SITE_KEY}
												onChange={handleSubmit}
											/>
										</form>
									)}
								</Formik>
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default CreatePassword;
