import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Theme from '../../../../../Theme';
import { Section, SectionBG, Scene } from 'Components/Product/styles';

import {
  SectionTitle,
  Wrapper,
  FlowWrapper,
  FlowContainer,
  FlowElement,
  FlowImage,
  FlowOverlay
} from './styles';

import visaSecureCube from './assets/Capability_Cube_Visa_Secure.png';

import customer from './assets/1-customer.png';
import customerNumber from './assets/1-number.png';
import customerNumberDetails from './assets/1-number-details.png';
import merchant from './assets/2-merchant.png';
import merchantNumber from './assets/2-number.png';
import merchantNumberDetails from './assets/2-number-details.png';
import visaSecureWheel from './assets/visa-secure-wheel.png';
import visaSecureWheelRing from './assets/visa-secure-wheel-ring.png';

import visaSecureWheel1 from './assets/visa-secure-wheel-1.png';
import visaSecureWheel2 from './assets/visa-secure-wheel-tick-cross.png';

import Number3 from './assets/3-number.png';
import Number3Details from './assets/3-number-details.png';
import Number3MoreDetails from './assets/3-number-details-2.png';

import Number4 from './assets/4-issuer.png';
import Number4Bar from './assets/4-bar.png';
import Number4Numbers from './assets/4-numbers-blue.png';
import Number4Arrows from './assets/4-numbers-yellow.png';
import Number4Final from './assets/4-final.png';

function FlowDiagram() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    // Pinned Elements
    gsap.to(
      element.querySelector(".section-1"), {
      scrollTrigger: {
        trigger: element.querySelector(".section-1"),
        start: "top top",
        end: "+=1200%",
        pin: true,
        scrub: true
      },
    }
    );

    // Timeline
    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        // markers: true
      }
    })

      .to(".master", { y: "-20%" })
      .from(".customerNumberDetails", { opacity: 0 })
      .to(".master", { y: "-40%" })
      .from(".merchantNumberDetails", { opacity: 0 })
      .to(".master", { y: "-57%" })
      .to(".rotate", { rotation: 90 }, "flow-group-0")
      .from(".visaSecureWheel1", { opacity: 0 }, "flow-group-0")
      .to(".master", { y: "-72%" })
      .from(".Number3Details", { opacity: 0 }, "flow-group-1")
      .to(".Number4Bar", { opacity: 0 }, "flow-group-1")
      .from(".Number3MoreDetails", { opacity: 0 }, "flow-group-2")
      .from(".Number4Numbers", { opacity: 0 }, "flow-group-2")
      .to(".Number3Details", { opacity: 0 }, "flow-group-2-out")
      .to(".Number3MoreDetails", { opacity: 0 }, "flow-group-2-out")
      .from(".Number4Arrows", { opacity: 0 }, "flow-group-3")
      .from(".Number4Final", { opacity: 0 }, "flow-group-3")
      .from(".visaSecureWheel2", { opacity: 0 }, "flow-group-3")

  }, []);

  return (
    <div ref={ref}>

      <Wrapper>


        <Section className="asdasdasdasd">

          <SectionBG />

          <Scene className="section-1">

            <div className="container">


              <FlowWrapper>
                <FlowContainer className="master">

                  <SectionTitle>Let's dive in and see Visa Secure in action</SectionTitle>

                  <img className="secureCube" src={visaSecureCube} />

                  <FlowElement>
                    <img src={customer} />
                  </FlowElement>

                  <FlowContainer className="">
                    <img src={customerNumber} />
                    <FlowOverlay className="customerNumberDetails">
                      <img src={customerNumberDetails} />
                    </FlowOverlay>
                  </FlowContainer>

                  <FlowElement>
                    <img src={merchant} />
                  </FlowElement>

                  <FlowContainer>
                    <img src={merchantNumber} />
                    <FlowOverlay className="merchantNumberDetails">
                      <img src={merchantNumberDetails} />
                    </FlowOverlay>
                  </FlowContainer>

                  <FlowContainer>

                    <FlowContainer className="">

                      <img src={visaSecureWheel} />

                      <FlowOverlay className="ring">
                        <img className="rotate" src={visaSecureWheelRing} />
                      </FlowOverlay>

                      <FlowOverlay className="visaSecureWheel1">
                        <img src={visaSecureWheel1} />
                      </FlowOverlay>
                      <FlowOverlay className="visaSecureWheel2">
                        <img src={visaSecureWheel2} />
                      </FlowOverlay>
                    </FlowContainer>

                  </FlowContainer>

                  <FlowContainer className="Number3Group">
                    <img src={Number3} />
                    <FlowOverlay className="Number3Details">
                      <img src={Number3Details} />
                    </FlowOverlay>
                    <FlowOverlay className="Number3MoreDetails">
                      <img src={Number3MoreDetails} />
                    </FlowOverlay>
                  </FlowContainer>

                  <FlowContainer>
                    <FlowElement>
                      <img src={Number4} />
                    </FlowElement>
                    <FlowOverlay className="Number4Bar" level={0}>
                      <img src={Number4Bar} />
                    </FlowOverlay>
                    <FlowOverlay className="Number4Numbers">
                      <img src={Number4Numbers} />
                    </FlowOverlay>
                    <FlowOverlay className="Number4Arrows">
                      <img src={Number4Arrows} />
                    </FlowOverlay>

                  </FlowContainer>

                  <FlowOverlay className="bottom Number4Final">
                    <img src={Number4Final} />
                  </FlowOverlay>

                </FlowContainer>
              </FlowWrapper>

            </div>

          </Scene>

        </Section>
      </Wrapper>
    </div>
  );
}

export default FlowDiagram;