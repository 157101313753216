import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
    NavButton, 
    NavContainer, 
    CTPHeading, 
    CTPContent, 
    CTPWrapper, 
    CTPContentWrapper, 
    CTPHeading2,
    CTPTextBold,
    CTPImage,
} from './styles';
import ctpGraphic1 from '../assets/ctp_graphic1.jpg'
import ctpBanner from '../assets/ctp_banner.jpg'

function VisaTokenService() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <CTPWrapper>
            <CTPContent>
                <CTPContentWrapper>
                    <CTPHeading>
                        The new payment solution <br /> for your online shop.
                    </CTPHeading>
                    <CTPHeading2>
                        Click to Pay with Visa allows your customers to pay online easily, <br />
                        quickly and securely once registered, even when they shop as a guest. 
                    </CTPHeading2>
                    <CTPImage>
                        <img src={ctpGraphic1} />
                    </CTPImage>
                    <CTPTextBold>
                        Security brings peace of mind – for you and your customers: 
                    </CTPTextBold>
                    <div>
                        Visa uses advanced technologies such as Visa Token Service and multi-layered security procedures to protect sensitive customer data and transaction data. 
                        This means your customers can shop with absolute confidence, and you can sell with absolute confidence.
                    </div>
                    <hr />

                    <div>
                        And what does Click to Pay with Visa mean for you and your online shop? Your chance of:
                    </div>

                    <ul>
                        <li>
                            <CTPTextBold>More completed purchases through a smooth check-out process.</CTPTextBold> Currently 86 % of all shopping baskets in the mobile eCommerce sector are abandoned, and 73 % on desktop computers.<sup>2</sup>
                        </li>
                        <li>
                           <CTPTextBold>Higher authorisation rates</CTPTextBold> through secure encryption of payment details with the innovative token technology. The authorisation rate for online payments is currently significantly lower than in retail shops<sup>3</sup>.	
                        </li>
                        <li>
                            <CTPTextBold>Minimal level of fraud</CTPTextBold> through high security standards. Working to counter the trend: The annual level of fraud in eCommerce sectors is expected to rise by 14% by 2023.<sup>4</sup>
                        </li>
                    </ul>
                </CTPContentWrapper>
                
                <CTPImage>
                    <img src={ctpBanner} />
                </CTPImage>

                <CTPContentWrapper>
                    <ol>
                        <li>
                            The EMV® SRC payment icon is a trademark owned by and used with permission of EMVCo, LLC. EMV® is a registered trademark of EMVCo, LLC in the U.S. and other countries and an unregistered trademark in other countries.
                        </li>
                        <li>
                            2020 VisaNet Global Data
                        </li>
                        <li>
                            Forbes, Why Cart Abandonment Rates Aren’t Falling, 2018, https://www.forbes.com/sites/paultalbot/2018/06/27/why-cart-abandonment-rates-arent-falling/#7f1bfd7f7bb6  
                        </li>
                        <li>
                            eCommerce Fraud Trends 2019, Merchant Fraud Journal, https://www.merchantfraudjournal.com/ecommerce-fraud-trends-2019/
                        </li>
                    </ol>
                    <NavContainer>
                        <Link to="/mezzanine/projects">
                            <NavButton>Back to Home Screen</NavButton>
                        </Link>
                        <Link to="/mezzanine/projects/VTS">
                            <NavButton>Next to Visa Token Service</NavButton>
                        </Link>
                    </NavContainer>
                </CTPContentWrapper>

            </CTPContent>
        </CTPWrapper>
    )
}

export default VisaTokenService;