import React from 'react';

import {
	TooltipWrapper,
	TooltipText,
	TooltipIntro,
	TooltipTitle,
	TooltipMarker,
} from './styles';

function TooltipHelper(props) {

	/* Set percentage position based on props */
	const position = {
		left: `${props.x}px`,
		top: `${props.y}px`
	}

	return (
		<>
			<TooltipWrapper
				id={`tooltip-${props.id}`}
				to='#'
				className="tooltip__wrapper"
			>
				<TooltipText>
					{props.intro &&
						<TooltipIntro>{props.intro}</TooltipIntro>
					}

					{props.title &&
						<TooltipTitle
							style={position}
							className="tooltip__title"
							fontSize={props.fontSize}
						>
							{props.title}
						</TooltipTitle>
					}

				</TooltipText>
				<TooltipMarker display={props.display} />
			</TooltipWrapper>
		</>
	)
}

export default TooltipHelper;