import React, { useRef, useEffect } from 'react';
import { Wrapper } from './styles';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Reveal from 'Components/Product/Reveal';


import optimisedApprovals from './assets/optimised-approvals.png';
import reducedExpenses from './assets/reduced-operational-expenses.png';
import increasedConfidence from './assets/increased-confidence.png';


function BusinessBenefits() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {

    const element = ref.current;

    gsap.to(
      element.querySelector(".section-1"), {
        scrollTrigger: {
          trigger: element.querySelector(".section-1"),
          start: "top top",
          end: "+=100%",
          pin: true,
          scrub: true
        },
      }
    );

    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        // markers: true
      }
    })
    .to(".BusinessBenefits",{ opacity: 0, y: "-300%" }, "sceneIn" )
    .from(".BenefitsNumbered",{ y: "300%"}, "sceneIn" )
    // .to(".BenefitsNumbered",{ x: "-100%" }, "sceneOut")
    // .to(".sceneBG",{ x: "-100%" }, "sceneOut")

  }, []);


  return (
    <Wrapper id="business-benefits" ref={ref}>

        <Section>

          <SectionBG className="sceneBG" light/>

          <Scene Height="100vh" className="section-1">

            <div className="container">

              <div className="scene">

                <div className="BusinessBenefits">
                By implementing Visa Secure into their payment platform, Electronic Store are able to address their business challenges
                </div>

                <div className="BenefitsNumbered">

                  <p className="BenefitsNumbered__heading">And deliver real business benefits</p>

                  <div className="BenefitsRow">

                    <div className="BenefitsRow__item">

                      <div className="BenefitsRow__primary">1</div>
                      <div className="BenefitsRow__secondary">
                        <img className="BenefitsRow__icon" src={reducedExpenses}/>
                        <Reveal
                        title="Reduced Fraud and Operational Expenses"
                        content={`<p>A decrease in fraudulent disputes and their associated losses and processing costs contribute to a healthier bottom line. </p>`}
                        />
                      </div>

                    </div>

                    <div className="BenefitsRow__item">

                      <div className="BenefitsRow__primary">2</div>
                      <div className="BenefitsRow__secondary">
                        <img className="BenefitsRow__icon" src={optimisedApprovals}/>
                        <Reveal
                        title="Optimised Approvals"
                        content={`<p>Used as another layer of fraud defence, false positives can be minimised allowing increased authorisation rates.</p>`}
                        />
                      </div>

                    </div>

                    <div className="BenefitsRow__item">

                      <div className="BenefitsRow__primary">3</div>
                      <div className="BenefitsRow__secondary">
                        <img className="BenefitsRow__icon" src={increasedConfidence}/>
                        <Reveal title="Consumer Confidence"
                        content={`<p>Using robust authentication methods improves security and reduces friction, which bolsters consumer confidence leading to increased profitability for merchants and issuers. </p>`}/>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </Scene>

        </Section>

    </Wrapper>
  );
}

export default BusinessBenefits;