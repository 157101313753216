import React, { useRef, useEffect } from 'react';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import { productState } from 'Stores/Product';

import issuerLogo from './assets/logo-fdnb.png';
import merchantLogo from './assets/logo-electronic-store.png';
import { DropLogo } from './styles';

const style = {
    height: '15vw',
    width: '15vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15%',
    marginBottom: '1.5rem',
    color: 'white',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: 'normal',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const DropZone = ({ canDrop, isOver, connectDropTarget, type, result }) => {

    const { 
        secure3dAuthentication,
        setSecure3dAuthentication
    } = productState();

    const isActive = canDrop && isOver;

    let backgroundColor = '#000';

    let markup = 'test';


    if (isActive) {
        backgroundColor = '#fff';
    }

    else if (canDrop) {
        backgroundColor = '#101a4d';
    }

    if(result) {
        backgroundColor = '#fff';
    }


    useEffect(() => {

        if(type && type.name) {
            setSecure3dAuthentication(type.name);
        }

    }, [result]);


    return (
        <div ref={connectDropTarget} style={{ ...style, backgroundColor }} role="DropZone">
            
            { secure3dAuthentication === "Merchant" ? <DropLogo alt="Merchant" src={merchantLogo}/> : "" }
            { secure3dAuthentication === "Issuer" ? <DropLogo alt="Issuer" src={issuerLogo}/> : "" }
            { !secure3dAuthentication ? <span>{isActive ? 'Release to drop' : 'Drag your answer here'}</span> : "" }
            
        </div>
    );

};

export default DropTarget(ItemTypes.BOX, 
    {
        drop: () => ({ name: 'DropZone' }),
    }, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        type: monitor.getItem(),
        result: monitor.getDropResult()
    }
))(DropZone);
