import styled from 'styled-components';

export const CTPWrapper = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
`;

export const CTPHeading = styled.p`
  display: flex;
  color: #1A1F71;
  font-size: 48px;
`; 

export const CTPContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #1A1F71;
  margin: 20px auto;
`; 

export const CTPContentWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 0 20px;
  > div {
    margin: 20px 0;
  }
`; 

export const CTPHeading2 = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

export const CTPTextBold = styled.span`
  font-weight: bold;
`;

export const CTPImage = styled.div`
  margin: 30px 0;
`;

export const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
`;

export const NavButton = styled.div`
  display: flex;
  background #1A1F71;
  color: white;
  padding: 15px 10px;
`; 

