import React from 'react';
import Carousel from '../Carousel';
import imageFasttrack from './assets/fintechFastTrack.jpg';
import { appState } from '../../Stores/App';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg';

import {
  PartnershipsTitle,
  PartnershipsIntro,
  CloseButton,
  PartnershipsContent,
  PartnershipsText,
  PartnershipsContainer,
  PartnershipsLink,
  PartnershipsImage,
  PartnershipsTextWrapper,
  ProgressIndicator,
  ProgressIndicatorItem,
} from './styles';


const data = [
  {
    "index": 1,
    "text": "Visa expands Fintech Fast Track in Europe; accelerates new ways to pay and be paid",
    "link": {
      "url": "#",
      "text": "Learn how"
    },
    "media": imageFasttrack,
    "id": "fintech-fast-track",
  },
  {
    "index": 2,
    "text": "Visa Fintech Partner Connect launches in Europe to enable next generation of digital payment experiences",
    "link": {
      "url": "#",
      "text": "Learn how"
    },
    "media": imageFasttrack,
    "id": "connect",
  }
];
function Partnerships() {
  const {
    hidePartnerships,
  } = appState();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [article, setArticle] = React.useState(false);

  return (
    <>
      <CloseButton>
        <CloseIcon onClick={hidePartnerships} />
      </CloseButton>
      <PartnershipsTitle>Partnerships</PartnershipsTitle>
      <PartnershipsIntro>Move left or right to explore our latest content</PartnershipsIntro>
      <Carousel data={data} setCurrentSlide={setCurrentSlide} currentSlide={currentSlide}>
        {data.map((item, index) =>
          <PartnershipsContainer key={index}>
            <PartnershipsContent>
              {item.media ?
                <PartnershipsImage src={item.media} />
                : ""}

              <PartnershipsTextWrapper>
                {item.text ?
                  <PartnershipsText>
                    {item.text}
                  </PartnershipsText>
                  : ""}

                {item.link && item.link.url && item.link.text ?
                  <>
                    <PartnershipsLink
                      to={{ pathname: `/article/${item.id}` }}>
                      {item.link.text}
                      <ArrowRight
                        width="0.7em"
                        height="0.7em"
                        style={{ position: 'absolute', right: 0 }}
                      />
                    </PartnershipsLink>
                  </> : ""}

                <ProgressIndicator>
                  {data.map((item, index) =>
                    <ProgressIndicatorItem
                      key={index}
                      active={currentSlide === index ? true : false}
                      onClick={() => setCurrentSlide(index)}
                      aria-disabled="true"
                    />
                  )}
                </ProgressIndicator>
              </PartnershipsTextWrapper>
            </PartnershipsContent>
          </PartnershipsContainer>
        )}
      </Carousel>
    </>
  );
}

export default Partnerships;
