import styled from 'styled-components';
import background from './assets/iMac-on-desk---background.jpg';


export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  .hero-section {
	&:after {
		background: black;
		content: "";
		display: block;
		left: 0;
		top: 0;
		opacity: .3;
		position: absolute;
		width: 100vw;
		height: 100vh;
	  }
  }

  .customer-experience-intro {
	position: relative;

	  h4 {
		padding: 80px 50px 20px 20px;
		font-size: calc( 32px + (40 - 32) * (100vw - 400px) / (1400 - 400));
		font-weight: 300;
		width: 40%;
	  }

  }
`;



export const Screen = styled.div`
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 80vh;

	img {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		max-width: none;
	}
`;

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-image: url(${background});
	background-size: cover;
	background-position: center;
`;

/*

Screen Image is 2000x1124
Screen Content area is 780x443
497px from left 188px from top

*/


export const ScreenMonitor = styled.img`

`;


/*

	Monitor Image is
	2300px Wide
	1591px High
	Content Area is
	1638 wide
	928 high
	477px from the left
	235px from the top
*/

export const ScreenContent = styled.div`

	position: absolute;
	top: 14.770584538026398%;
	left: 20.73913043478261%;
	width: 71.21739130434783%;
	height: 58.328095537397864%;
	background-color: transparent;
	z-index: 2;
	overflow: hidden;

`;


export const ScreenText = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #F7B600;
	font-weight: 100;
	font-size: 30px;
	@media (max-width: 768px) {
		font-size: 18px;
	}

	.btn {
		background-color: #F7B600;
	    color: #101a4d;
	    display: inline-block;
	    border-radius: 2em;
	    padding: 0.5em 3em;
	    line-height: 1.2;
	    text-transform: uppercase;
	    font-size: 12px;
	    font-weight: 400;
	    cursor: pointer;
	    position: relative;
	    z-index: 2;
	    text-decoration: none;
	    cursor: pointer;
	}

`;

export const ScreenVideo = styled.video`
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
	width: 100%;
	outline: none;
`;

