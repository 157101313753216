import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from './assets/404-icon.png';
import { appState } from '../../Stores/App';
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from '../../constants';

import {
  FourOhFour,
  FourOhFourContent,
  FourOhFourImage,
} from './styles';

function NotFound() {

  const {
    setMenuTheme
  } = appState();

  useEffect(() => {
    setMenuTheme("dark");
  }, []);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.PAGE_NOT_FOUND}</title>
      </Helmet>
      <FourOhFour>
        <FourOhFourContent>
          <h4>Sorry this page has not been found</h4>
          <p>Try returning to the <Link to="/">Lobby</Link> and start from there</p>
        </FourOhFourContent>
        <FourOhFourImage>
          <img src={image} alt="" />
        </FourOhFourImage>
      </FourOhFour>
    </>
  );
}

export default NotFound;