import React, { useRef, useEffect } from 'react';
import Theme from '../../../../../Theme';
import { gsap } from 'gsap';
import { TimelineMax } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import image from './assets/background.png';
import Text from '../../../Text';
import { animationPause } from '../../../animations';
import Background from '../../../Background';

import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
} from '../../../styles';

function SlideOne() {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);
  const ID = 'thankyou__slide-1__animation';

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    const timeline = new TimelineMax({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
      }
    })

    timeline
      .add(animationPause());
  }, []);


  return (
    <Wrapper ref={ref}>
      <Section>
        <SectionBG />
        <Scene Height="100vh" className={`${ID}-scene`}>
          <div className="container">
            <div className="scene-content">

              <Background image={image} />

              <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid slice" style={{ position: 'absolute', top: '0' }}>
                <g id={`${ID}-text1`}>
                  <Text
                    text={[
                      'Thank you for following our holiday makers journey, it was a great success.',
                    ]}
                    position={{ x: "49", y: "220", width: "880" }}
                    styles={{ color: "#1A1F71", weight: "200", minFontSize: "36", maxFontSize: "36" }}
                  />
                </g>
              </svg>

            </div>
          </div>
        </Scene>
      </Section>
    </Wrapper >
  );
}

export default SlideOne;
