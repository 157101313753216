import React, { useRef } from 'react';
import { appState } from '../../../Stores/App';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hotspot from './assets/hotspot.svg';
import menu from './assets/menu-icon.svg';
import { writeCookie, getCookie } from '../../../helpers/cookies';
import { WELCOME_SCREEN_VIEWED } from './constants';

import {
    WelcomeWrapper,
    SlideScreen,
    SlideTitle,
    SlideContent,
    SlideImage,
    SlideWrapper,
    SlideAction,
    SlideButton
} from './styles';

function Lobby() {
    const {
        overlay: hasOverlay,
    } = appState();
    const settings = {
        dots: true,
        infinite: false,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const node = useRef(null);
    const [showModule, setShowModule] = React.useState(() => {
        return getCookie(WELCOME_SCREEN_VIEWED) === false;
    });
    const moduleClose = () => {
        writeCookie(
            WELCOME_SCREEN_VIEWED,
            true
        );
        setShowModule(false)
    }

    return (
        <>
            {showModule ?
                <WelcomeWrapper hasOverlay={hasOverlay}>
                    <SlideWrapper ref={node}>
                        <Slider {...settings}>
                            <div>
                                <SlideScreen>
                                    <SlideTitle><p>Welcome</p></SlideTitle>
                                    <SlideContent>To enjoy Visa Navigate Experiences at its most immersive we recommend viewing on a desktop, laptop or tablet device.</SlideContent>
                                    <SlideImage></SlideImage>
                                </SlideScreen>
                            </div>
                            <div>
                                <SlideScreen>
                                    <SlideTitle><p>Discover</p></SlideTitle>
                                    <SlideContent>Click or tap on any of the hotspots within the lobby to see what content is available - they look like this..</SlideContent>
                                    <SlideImage><img src={hotspot} /></SlideImage>
                                </SlideScreen>
                            </div>
                            <div>
                                <SlideScreen>
                                    <SlideTitle><p>Explore</p></SlideTitle>
                                    <SlideContent>Click on the menu icon at any time to explore the content. It is located at the top left of the screen & it looks like this:</SlideContent>
                                    <SlideImage><img src={menu} /></SlideImage>
                                </SlideScreen>
                            </div>
                            <div>
                                <SlideScreen>
                                    <SlideContent className="no-title">Click enter to start exploring the site</SlideContent>
                                    <SlideAction onClick={moduleClose}>
                                        <SlideButton>Enter</SlideButton>
                                    </SlideAction>
                                </SlideScreen>
                            </div>

                        </Slider>
                    </SlideWrapper>

                </WelcomeWrapper>
                : null
            }
        </>
    );
}

export default Lobby;
