import React, { useRef, useEffect } from 'react';
import Theme from '../../../../../Theme';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Hero from '../../../Hero';
import image from './assets/visa_spf_new-technologies.jpg';
import { ReactComponent as ImageNewTecnologies } from './assets/new-technologies.svg';
import Text from '../../../Text';
import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
} from '../../../styles';


function SlideThree() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'introduction__slide-3__animation';

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

  }, []);


  return (
    <Theme onEnter="light" onEnterBack="light" onLeaveBack="dark">
      <Wrapper ref={ref}>
        <Section light>
          <SectionBG light />
          <Scene Height="100vh" className={`${ID}-scene`}>
            <Hero backgroundImage={image} />
            <div className="container">
              <div className="scene-content">

                <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid" style={{ position: 'absolute', top: '0' }}>
                  <Text
                    text={[
                      'This digital banking adoption is being accelerated by new technologies, macro-environmental shifts and changing consumer tastes ',
                    ]}
                    position={{ x: "49", y: "179", width: "779" }}
                    styles={{ color: "#F7B600", weight: "200", minFontSize: "45", maxFontSize: "45" }}
                  />
                  <ImageNewTecnologies />
                </svg>



              </div>
            </div>
          </Scene>
        </Section>
      </Wrapper>
    </Theme>
  );
}

export default SlideThree;