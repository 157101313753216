/**

 THEME
 ======================

 Acts as a wrapper for a scrolling section, set global theme when scrolling
 in and out of wrapper, also adds a class to wrapper to add inerhit styles
 for it's children

*/

import React, { useRef, useEffect } from 'react';
import { appState } from 'Stores/App';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Theme(props) {

    gsap.registerPlugin(ScrollTrigger);

    const {
        setMenuTheme
    } = appState();

    const ref = useRef(null);

    useEffect(() => {

        const element = ref.current;

        const setTheme = (theme, direction) => {
            if (theme) {
                setMenuTheme(theme)
            }
        }

        gsap.to(
            element, {
            scrollTrigger: {
                trigger: element,
                start: "top top",
                end: "bottom top",
                onEnter: () => setTheme(props.onEnter, "onEnter"),
                onEnterBack: () => setTheme(props.onEnterBack, "OnEnterBack"),
                onLeaveBack: () => setTheme(props.onLeaveBack, "onLeaveBack"),
                anticipatePin: 1,
            },
        }
        );
    }, []);

    return (
        <section id={props.id} ref={ref} className={`theme ${props.onEnter ? `theme--${props.onEnter}` : ""} ${props.onLoad ? `theme--${props.onLoad}` : ""} `}>
            {props.children}
        </section>
    );

}

export default Theme;