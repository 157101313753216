import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import toolTipOpen from './assets/Tooltip-open.svg';
import toolTipClose from './assets/Tooltip-close.svg';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const TooltipText = styled.div`
position: absolute;
	width: 200px;
	text-align: center;
	line-height: 1.4;
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 0.25s ease, transform 0.25s ease;
`;

export const TooltipIntro = styled.div`
	font-size: 0.875em;
`;

export const TooltipTitle = styled.div`
	font-size: 18px;
	margin: 0.7em 0 2em 0;
	color: black;
	background: rgba(255, 255, 255, .8);
	padding: 10px;
	position: absolute;
	width: 200%;

	${props => props.fontSize && `
    	font-size: ${props.fontSize};
  	`}
`;

export const TooltipMarker = styled.div`
content: " ";
width: 30px;
	height: 30px;
	padding: 10px;
	background-image: url(${toolTipOpen});
	background-size: cover;
	position: relative;
	border-radius: 100%;
	display: inline-block;
	margin: 0 auto 0 12px;
	transition: all 0.25s ease;

	${props => props.display && `
    	display: ${props.display};
  	`}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 8px;
		height: 8px;
		border-radius: 100%;
		display: inline-block;
	}
`;

export const TooltipBg = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(16,26,77, 0.8);
	background: radial-gradient(circle, rgba(14,23,78,0.8) 0%, rgba(5,4,38,0.8) 100%);
	display: none;
`;

export const TooltipChrome = styled.div`
	height: 100px;
	margin-top: -60px;

	&:after {
		content: '';
		width: 1px;
		height: 0;
		transition: height 0.25s ease;
		display: block;
		margin: 0 auto;
		background-color: #F7B600;
	}

`;

export const TooltipWrapper = styled(Link)`
	position: relative;
	z-index: 2;
	color: #fff;
	font-weight: 300;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	display: inline-block;

	&:hover {
		+ ${TooltipBg} {
			display: block;
			animation: fadeIn 0.25s;
		}

		${TooltipMarker} {

			background-image: url(${toolTipClose});
		}

		${TooltipText} {
			opacity: 1;
			transform: translateY(0);
			transition: opacity 0.25s ease 0.125s ,transform 0.25s ease 0.125s;
		}

		${TooltipChrome} {
			&:after {
				height: 100px;
			}
		}
	}


`;