import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextInput, Checkbox } from 'Components/Auth/Fields';
import CONFIG from '../../../config';
import { PAGE_TITLES } from '../../../constants';
import Marquee from 'Components/Auth/Marquee';
import ReCAPTCHA from "react-google-recaptcha";
import { appState } from '../../../Stores/App';
import ErrorMessages from '../ErrorMessages';
import { getApiErrorMessage } from '../helper';
import axiosConfig from '../../../Services/axiosConfig';
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Register() {
	const [errors, setErrors] = useState([]);
	const [userCreated, setUserCreated] = useState(false);
	const [verifyUserDomain, setVerifyUserDomain] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const recaptchaVerifyRef = React.createRef();
	const recaptchaNoVerifyRef = React.createRef();

	const {
		navigation,
		hideNavigation,
	} = appState();

	let validationSchema = Yup.object({
		firstName: Yup.string()
			.required("Required"),
		lastName: Yup.string()
			.required("Required"),
		email: Yup.string()
			.email("Invalid email address")
			.required("Required"),
	})

	if (verifyUserDomain) {
		validationSchema = Yup.object({
			firstName: Yup.string()
				.required("Required"),
			lastName: Yup.string()
				.required("Required"),
			email: Yup.string()
				.email("Invalid email address")
				.required("Required"),
			address: Yup.string()
				.required('Required'),
			companyName: Yup.string()
				.required('Required'),
			phoneNumber: Yup.string()
				.required('Required'),
			businessSector: Yup.string()
				.required('Required'),
			jobTitle: Yup.string()
				.required('Required')
		})
	}

	useEffect(() => {
		if (navigation === true) {
			hideNavigation();
		}
	}, [navigation, hideNavigation]);

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLES.REGISTER}</title>
			</Helmet>
			<Marquee />
			<div className="auth">
				<div className="authWrapper">
					<ErrorMessages errors={errors} />

					{!userCreated ?
						<>
							<Formik
								initialValues={{
									firstName: '',
									lastName: '',
									email: '',
									address: '',
									companyName: '',
									phoneNumber: '',
									businessSector: '',
									businessName: '',
									jobTitle: '',
									acceptedFutureCommunications: false,
								}}
								validationSchema={validationSchema}
								onSubmit={async (values) => {
									let user;
									let endpoint;
									let token;

									if (verifyUserDomain) {
										token = await recaptchaVerifyRef.current.executeAsync();
										recaptchaVerifyRef.current.reset();
										endpoint = "identity/users/full-user";
										user = {
											email: values.email,
											address: values.address,
											surname: values.lastName,
											forename: values.firstName,
											companyName: values.companyName,
											phoneNumber: values.phoneNumber,
											businessSector: values.businessSector,
											jobTitle: values.jobTitle,
											acceptedFutureCommunications: values.acceptedFutureCommunications,
										}
									} else {
										token = await recaptchaNoVerifyRef.current.executeAsync();
										recaptchaNoVerifyRef.current.reset();
										endpoint = "identity/users/basic-user"
										user = {
											forename: values.firstName,
											surname: values.lastName,
											email: values.email,
											acceptedFutureCommunications: values.acceptedFutureCommunications,
										}
									}

									setErrors([]);

									axiosConfig.post(
										`/${endpoint}`,
										user,
										{
											headers: {
												'g-recaptcha-response': token
											},
										}
									)
										.then((response) => {
											let statusCode = response.data.statusCode;

											switch (statusCode) {
												case 200:
													setUserCreated(true);
													break;
												case 202:
													setVerifyUserDomain(true);
													setShowMore(true);
													break;
												case 400:
													setErrors(response.data.errorMessages);
													break;
												default:
													console.error('API error')
											}

										})
										.catch((error) => {
											const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'The account could not be created';
											setErrors([`${message}`])
										});

								}
								}
								render={({ values, handleChange, handleSubmit }) => (
									<>
										{verifyUserDomain ?
											<>
												<div className="formWrapper__wide">
													<div className="formWrapper">
														<form onSubmit={handleSubmit} className="form-setup">
															<h2 className="form-header">Request access</h2>
															<p className="form-intro">Sorry your domain is not registered. Please fill in your details to request access</p>
															<div className="form-columns">
																<div className="form-column">
																	<TextInput
																		label="First name"
																		placeholder="First name (from previous)"
																		name="firstName"
																		type="text"
																		className="text-field"
																		value={values.firstName}
																	/>
																	<TextInput
																		label="Last name"
																		placeholder="Last name (from previous)"
																		name="lastName"
																		type="text"
																		className="text-field"
																		value={values.lastName}
																	/>
																	<TextInput
																		label="Email address (from previous)"
																		placeholder="Email address (from previous)"
																		name="email"
																		type="email"
																		className="text-field"
																		value={values.email}
																	/>
																	<TextInput
																		label="Job title"
																		placeholder="Job title"
																		name="jobTitle"
																		type="text"
																		className="text-field"
																		value={values.jobTitle}
																	/>
																</div>
																<div className="form-column">
																	<TextInput
																		label="Business name"
																		placeholder="Business name"
																		name="companyName"
																		type="text"
																		className="text-field"
																		value={values.companyName}
																	/>
																	<TextInput
																		label="Business address"
																		placeholder="Business address"
																		name="address"
																		type="text"
																		className="text-field"
																		value={values.address}
																	/>
																	<TextInput
																		label="Phone number"
																		placeholder="Phone number"
																		name="phoneNumber"
																		type="text"
																		className="text-field"
																		value={values.phoneNumber}
																	/>
																	<TextInput
																		label="Industry/Sector"
																		placeholder="Industry/sector"
																		name="businessSector"
																		type="text"
																		className="text-field"
																		value={values.businessSector}
																	/>
																	<Field
																		name="acceptedFutureCommunications"
																		render={() => (
																			<Checkbox
																				id="subscribe"
																				name="acceptedFutureCommunications"
																				value={values.acceptedFutureCommunications}
																				checked={values.acceptedFutureCommunications}
																				onChange={handleChange}
																			>
																				By checking this box, you agree to receive marketing communications about Visa business products and services. You can unsubscribe at any time. For more information on how Visa collects, uses, and protects your personal information, please review our Global Privacy Notice.
																			</Checkbox>
																		)}
																	/>
																</div>
															</div>
															<div className="form__footer btn-center">
																<button type="submit" className="form-btn">
																	Request access
																</button>
															</div>
															<ReCAPTCHA
																ref={recaptchaVerifyRef}
																size="invisible"
																sitekey={CONFIG.RECAPTCHA_SITE_KEY}
																onChange={handleSubmit}
															/>
														</form>
													</div>
												</div>
											</>
											:
											<div className="formWrapper">
												<Form className="form-setup">
													<h2 className="form-header">Create an account</h2>
													<p className="form-intro">Already have an account? <a className="form-link" href="/login">Sign in here</a></p>

													<div className="form-input__wrapper">
														<TextInput
															label="First Name"
															placeholder="First Name"
															name="firstName"
															type="text"
															className="text-field"
															value={values.firstName}
														/>

														<TextInput
															label="Last Name"
															placeholder="Last Name"
															name="lastName"
															type="text"
															className="text-field"
															value={values.lastName}
														/>

														<TextInput
															label="Email Address"
															placeholder="Email Address"
															name="email"
															type="email"
															className="text-field"
															value={values.email}
														/>

														<Field
															name="acceptedFutureCommunications"
															render={() => (
																<Checkbox
																	id="subscribe"
																	name="acceptedFutureCommunications"
																	value={values.acceptedFutureCommunications}
																	checked={values.acceptedFutureCommunications}
																	onChange={handleChange}
																>
																	By checking this box, you agree to receive marketing communications about Visa business products and services. You can unsubscribe at any time. For more information on how Visa collects, uses, and protects your personal information, please review our Global Privacy Notice
																</Checkbox>
															)}
														/>

													</div>
													<div className="form__footer btn-center">
														<button className="form-btn" type="submit">
															Validate
														</button>
													</div>
													<ReCAPTCHA
														ref={recaptchaNoVerifyRef}
														size="invisible"
														sitekey={CONFIG.RECAPTCHA_SITE_KEY}
														onChange={handleSubmit}
													/>
												</Form>
											</div>
										}
									</>
								)}
							/>
						</>
						:
						<>
							<div className="formWrapper">
								<div className="form-setup">
									{showMore ?
										<div>
											{/* JASMINE TO EDIT  */}
											<h2 className="form-header">Thank you</h2>
											<p className="form-intro">A member of our team will review your request and get back to you ASAP</p>
											<Link to="/login" className="form-btn">Login</Link>
										</div>
										:
										<div>
											<h2 className="form-header">Nearly there!</h2>
											<p className="form-intro">We have sent you an email with account activation instructions. Please check your junk folder if you cannot see it.</p>
											<p className="form-intro">Enable cookies to remain logged in.</p>
											<div className="form-link__wrapper">
													<Link to="/login" className="form-btn">Login</Link>
											</div>
											<div className="form-footer">
												<p className="footer-bookmark">Bookmark this page</p>
											</div>
										</div>
									}
								</div>
							</div>
						</>
					}
				</div>
			</div>
		</>

	);
}

export default Register;