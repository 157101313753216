import styled from 'styled-components';

export const HeroWrapper = styled.div`
  height: 100vh;
  top: 0;
  position: relative;
  overflow: hidden;
  background-color: #111;
  color: #fff;
`;

export const HeroImage = styled.img`
	position: ${props => props.type === "secondary" ? "absolute" : "fixed" };
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate3d(-50%, -50%, 0);
`;

export const HeroVideo = styled.video`
	position: ${props => props.type === "secondary" ? "absolute" : "fixed" };
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate3d(-50%, -50%, 0);

  + ${HeroImage} {
    display: none;
  }

`;

export const HeroContent = styled.div`
	position: ${props => props.type === "secondary" ? "absolute" : "fixed" };
  top: 50%;
  left: 50%;
  width: 800px;
  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
`;

export const HeroTitle = styled.h1`
	font-weight: 300;
  line-height: 1.1666666667;
  margin: 0;
  font-size: ${props => props.type === "secondary" ? "calc( 50px + (60 - 50) * (100vw - 400px) / (1400 - 400))" : "calc( 50px + (110 - 50) * (100vw - 400px) / (1400 - 400))" };
`;

export const HeroIntro = styled.p`
	font-weight: 300;
  margin: 0;
  font-size: calc( 20px + (24 - 20) * (100vw - 400px) / (1400 - 400));
`;