import styled from 'styled-components';

export const Overlay = styled.div`
  background-color: #111;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #101a4d;
    opacity: 0.7;
    z-index: 2;
    height: 100vh;
    width: 100vw;
  }
`;
