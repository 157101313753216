import React, { useRef, useEffect } from 'react';
import Theme from '../../../../../Theme';
import { gsap } from 'gsap';
import { TimelineMax } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin"
import image from './assets/tattoo-covid-mask.jpg';
import imagePhoneF6 from './assets/phone-f6.png';
import imagePhone from './assets/phone-gradient.png';
import imageEuroNotes from './assets/euro-notes.png';
import imageAbnAmro from './assets/abn-amro.png';
import imageNotificationPayment from './assets/notification-phone-payment.png';
import imageAppScreen from './assets/app-screen.png';
import imagePhoneFrame from './assets/phone-frame.png';
import imageArrow from './assets/arrow.svg';
import { getScale, minus } from '../../../helpers';
import Text from '../../../Text';
import { animationPause } from '../../../animations';
import TooltipHelper from 'Components/TooltipHelper';

import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  SLIDE_DURATION,
} from '../../../constants';

import {
  Wrapper,
  Section,
  SectionBG,
  Scene,
  Typography,
} from '../../../styles';
import {
  ScreenPanel,
  Img,
} from './styles';

function SlideFour() {

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MorphSVGPlugin);
  gsap.registerPlugin(DrawSVGPlugin);

  const ref = useRef(null);
  const ID = 'intro__slide-4__animation';
  const FLYOUT_WIDTH = '1081';
  const MOVE_OFFSCREEN_WIDTH = CANVAS_WIDTH * 2;

  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=2000%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    const timeline = new TimelineMax({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
      }
    })


    timeline
      .duration(100)
      .set(`#${ID}-screenPanel-1`, { transformOrigin: "50% 0" })
      .set(`#${ID}-screenPanel-2`, { transformOrigin: "50% 0" })
      .set(`#${ID}-screenPanel-3`, { transformOrigin: "50% 0" })
      .set(`#${ID}-screen-2`, { x: "0" })
      .set(`#${ID}-flyout1`, { x: "-480" })
      .set(`#${ID}-flyout2`, { x: "-480" })
      .set(`#${ID}-flyout3`, { x: "-480" })
      .set(`#${ID}-flyout4`, { x: "-480" })
      .set(`#${ID}-flyout5`, { x: "-480" })


      // Slide intro in
      .from(`#${ID}-text1`, { y: `${CANVAS_HEIGHT}`, opacity: 0, duration: 30 })
      .from(`#${ID}-phone`, { y: `${CANVAS_HEIGHT}`, duration: 30 }, "circleAndPhone")
      .from(`#${ID}-phoneFrame`, { y: `${CANVAS_HEIGHT}`, duration: 30 }, "circleAndPhone")

      // Move 1st screen panel to phone
      .from(`#${ID}-screenPanel-1`, { y: `${CANVAS_HEIGHT}`, ease: "slow", duration: 60 })
      .to(`#${ID}-screenPanel-1`, { x: "-63", y: "-228", scale: `${getScale(612, 280)}`, ease: "slow", duration: 60 })

      // Move 2nd screen panel to phone
      .from(`#${ID}-screenPanel-2`, { y: `${CANVAS_HEIGHT}`, ease: "slow", duration: 60 })
      .to(`#${ID}-screenPanel-2`, { x: "-63", y: "-165", scale: `${getScale(612, 280)}`, ease: "slow", duration: 60 })

      // Move 3rd screen panel to phone
      .from(`#${ID}-screenPanel-3`, { y: `${CANVAS_HEIGHT}`, ease: "slow", duration: 60 })
      .to(`#${ID}-screenPanel-3`, { x: "-63", y: "-102", scale: `${getScale(612, 280)}`, ease: "slow", duration: 60 })

      // Pause
      .to({}, { duration: 30 })

      // Move screen panels out
      .to(`#${ID}-screenPanel-1`, { x: `${MOVE_OFFSCREEN_WIDTH}`, duration: 240, ease: "power4" }, "group3")
      .to(`#${ID}-screenPanel-2`, { x: `${MOVE_OFFSCREEN_WIDTH}`, duration: 240, ease: "expo" }, "group3")
      .to(`#${ID}-screenPanel-3`, { x: `${MOVE_OFFSCREEN_WIDTH}`, duration: 160, ease: "slow" }, "group3")

      // Move items to the right
      .to(`#${ID}-phone`, { x: `${minus(808, 161)}`, ease: "expo", duration: 80 }, "group3")
      .to(`#${ID}-phoneFrame`, { x: `${minus(808, 161)}`, ease: "expo", duration: 80 }, "group3")
      .to(`#${ID}-bg`, { x: `${minus(808, 470)}`, ease: "expo", duration: 80 }, "group3")

      // Swap text
      .to(`#${ID}-text1`, { opacity: 0, duration: `${SLIDE_DURATION}` }, "swapText+=1")
      .from(`#${ID}-text2`, { opacity: 0, duration: `${SLIDE_DURATION}` }, "swapText+=2")

      .from(`#${ID}-phone-morph-start`, { y: 0, duration: 50 }, "morph")
      .to(`#${ID}-phone-morph-start`, {
        duration: 50,
        morphSVG: {
          shape: `#${ID}-phone-morph-end`,
        },
      }, "morph")

      // Swipe screen up
      .to(`#${ID}-screen`, { top: '0', duration: 75 }, "morph+=4")

      // Slide text from behind phone
      .to(`#${ID}-flyout1`, { x: `-${MOVE_OFFSCREEN_WIDTH}`, duration: 300 })
      .to(`#${ID}-flyout2`, { x: `-${MOVE_OFFSCREEN_WIDTH}`, duration: 300 }, "-=100")
      .to(`#${ID}-flyout3`, { x: `-${MOVE_OFFSCREEN_WIDTH}`, duration: 300 }, "-=100")
      .to(`#${ID}-flyout4`, { x: `-${MOVE_OFFSCREEN_WIDTH}`, duration: 300 }, "-=100")
      .to(`#${ID}-flyout5`, { x: `-${MOVE_OFFSCREEN_WIDTH}`, duration: 300 }, "-=100")

      // Swap text
      .to(`#${ID}-text2`, { opacity: 0, duration: 0.5 })
      .from(`#${ID}-text3`, { opacity: 0, duration: 0.5 })

      // Fade screen 2 in
      .from(`#${ID}-screen-2`, { opacity: 0, duration: 10 })

      .add(animationPause());

  }, []);


  return (
    <Theme onEnter="dark" onEnterBack="dark" onLeaveBack="dark">
      <Wrapper ref={ref}>
        <Section light>
          <SectionBG dark />
          <Scene Height="100vh" className={`${ID}-scene`}>
            <div className="container">
              <div className="scene-content">

                {/* TODO: get background image extended for when it slides */}
                <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMaxYMid slice" style={{ position: 'fixed', top: '0' }}>
                  <image width="100%" height="100%" href={image} />
                </svg>
                <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMaxYMid slice" style={{ position: 'fixed', top: '0' }}>
                  <image id={`${ID}-bg`} width="100%" height="100%" href={image} />
                </svg>

                <svg width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMid slice" style={{ position: 'absolute', top: '0' }}>

                  <g>
                    <defs>
                      <clipPath id={`${ID}-mask`}>
                        <rect x="-100" y="0" width={`${FLYOUT_WIDTH}`} height="100%" />
                      </clipPath>
                    </defs>
                    <g clipPath={`url(#${ID}-mask)`}>
                      <g id={`${ID}-flyout1`}>
                        <text x="1481" y="840" width={`${FLYOUT_WIDTH}`} fill="#F7B600" fontSize="243" fontWeight="200">
                          Shopping
                        </text>
                      </g>
                      <g id={`${ID}-flyout2`}>
                        <text x="1481" y="840" width={`${FLYOUT_WIDTH}`} fill="#F7B600" fontSize="243" fontWeight="200">
                          Transport
                        </text>
                      </g>
                      <g id={`${ID}-flyout3`}>
                        <text x="1481" y="840" width={`${FLYOUT_WIDTH}`} fill="#F7B600" fontSize="243" fontWeight="200">
                          Travel
                        </text>
                      </g>
                      <g id={`${ID}-flyout4`}>
                        <text x="1481" y="840" width={`${FLYOUT_WIDTH}`} fill="#F7B600" fontSize="243" fontWeight="200">
                          Social
                        </text>
                      </g>
                      <g id={`${ID}-flyout5`}>
                        <text x="1481" y="840" width={`${FLYOUT_WIDTH}`} fill="#F7B600" fontSize="243" fontWeight="200">
                          Payments
                        </text>
                      </g>
                    </g>
                  </g>


                  <image id={`${ID}-phone`} x="239" y="328" width="451" height="675" href={imagePhone} />
                  <svg x="970" y="0" width="305.4px" height="1527px" viewBox="0 165 305 1190">
                    <mask id={`${ID}-phone-mask`}>
                      <rect x="0" y="337" width="292" height="612" fill="#FFFFFF" />
                    </mask>
                    <path mask={`url(#${ID}-phone-mask)`} id={`${ID}-phone-morph-start`} d="M0,1292.2h305.4v-279.5c0,0-14.9-43.8-52.1-10.9c-6.9,6.1-17.4,44.7-17.4,44.7s-19.7,48-65.2,5.2
	c-30-28.2-60.8-2.2-60.8-2.2s-20.4,20.4-33.4,28.7c-13,8.3-38.2,19.1-65.6-5.6c0,0-6.8-6.5-11-16.9V1292.2z"/>
                    <path style={{ visibility: 'hidden' }} id={`${ID}-phone-morph-end`} d="M0,988h305.4V314.7c0,0-26.5-307.7-63.7-263.1c-6.9,8.2-13,78-13,78s-6.9,154.5-65-14.4c-28.9-83.9-36.1,74.4-36.1,74.4
	s2.9,199.7-37.6,131.2C80.4,304.6,63.4,65.4,43.8,14.1c0,0-21.7-36.1-40.5,7.2L0,988z"/>
                  </svg>
                  <foreignObject x="970" y="337" width="291" height="611" style={{ position: 'relative' }}>
                    <img id={`${ID}-screen`} src={imageAppScreen} alt="" style={{ position: 'absolute', top: '100%' }} />
                  </foreignObject>
                  <image id={`${ID}-phoneFrame`} x="246" y="328" width="451" height="651" href={imagePhoneFrame} />
                  <image id={`${ID}-screen-2`} x="873" y="328" width="476" height="675" href={imagePhoneF6} />


                  <g id={`${ID}-text1`}>
                    <Text
                      text={[
                        'Covid 19 has further accelerated this shift to digital banking channels. These adopted behaviours are likely to be ingrained long after the pandemic',
                      ]}
                      position={{ x: "49", y: "180", width: "880" }}
                      styles={{ color: "#1A1F71", weight: "200", minFontSize: "36", maxFontSize: "36" }}
                    />
                  </g>

                  <g id={`${ID}-text2`}>
                    <Text
                      text={[
                        'Consumers have unparalleled choice for their financial lives with an explosion of new interaction points for services',
                      ]}
                      position={{ x: "49", y: "180", width: "646" }}
                      styles={{ color: "#1A1F71", weight: "200", minFontSize: "36", maxFontSize: "36" }}
                    />
                  </g>

                  <g id={`${ID}-text3`}>
                    <Text
                      text={[
                        'This has opened up new opportunities for account providers to engage with their customers and build deeper banking relationships.',
                      ]}
                      position={{ x: "49", y: "180", width: "646" }}
                      styles={{ color: "#1A1F71", weight: "200", minFontSize: "36", maxFontSize: "36", mb: "0" }}
                    />
                  </g>


                  <g id={`${ID}-screenPanel-1`} x="227" y="680" width="612" height="100%">
                    <foreignObject x="227" y="680" width="612" height="100%">
                      <ScreenPanel height="128" width="612" id={`${ID}-screenPanel-4`}>
                        <Img src={imageEuroNotes} />
                        <div>
                          <Typography color="#FFFFFF" weight="200" minFontSize="20" maxFontSize="20" lineHeight="1.2" mb="0">
                            71% of EU consumers say they have been using cash less since the start of the pandemic
                          </Typography>
                          <div style={{ position: 'relative', top: '4px', left: '10px' }}>
                            <TooltipHelper
                              id={`${ID}-tooltip-3`}
                              index="2"
                              title="Source: https://www.bankofengland.co.uk/quarterly-bulletin/2020/2020-q4/cash-in-the-time-of-covid"
                              x="-180"
                              y="15"
                              position="bottom"
                              display="inline-table"
                              fontSize="14px"
                            />
                          </div>
                        </div>
                      </ScreenPanel>
                    </foreignObject>
                  </g>

                  <g id={`${ID}-screenPanel-2`} x="227" y="680" width="612" height="100%">
                    <foreignObject x="227" y="680" width="612" height="100%">
                      <ScreenPanel height="128" width="612" id={`${ID}-screenPanel-4`}>
                        <Img src={imageAbnAmro} />
                        <div>
                          <Typography color="#FFFFFF" weight="200" minFontSize="20" maxFontSize="20" lineHeight="1.2" mb="0">
                            With a sharp rise in mobile banking monthly usage from 52% to 57% between 2h 2019 and 1hr 2020
                          </Typography>
                          <div style={{ position: 'relative', top: '4px', left: '10px' }}>
                            <TooltipHelper
                              id={`${ID}-tooltip-3`}
                              index="2"
                              title="Source: https://www.hsbc.com/insight/topics/how-banking-will-change-after-covid-19"
                              x="-180"
                              y="15"
                              position="bottom"
                              display="inline-table"
                              fontSize="14px"
                            />
                          </div>
                        </div>
                      </ScreenPanel>
                    </foreignObject>
                  </g>

                  <g id={`${ID}-screenPanel-3`} x="227" y="680" width="612" height="100%">
                    <foreignObject x="227" y="680" width="612" height="100%">
                      <ScreenPanel height="128" width="612" id={`${ID}-screenPanel-4`}>
                        <Img src={imageNotificationPayment} />
                        <div>
                          <Typography color="#FFFFFF" weight="200" minFontSize="18" maxFontSize="18" lineHeight="1.2" mb="0">
                            ANB Amro’s mobile banking app broke the recorded for customer usage. Over 1B logins in a single calendar year in 2020
                          </Typography>
                          <div style={{ position: 'relative', top: '4px', left: '10px' }}>
                            <TooltipHelper
                              id={`${ID}-tooltip-3`}
                              index="2"
                              title="Source: https://www.finextra.com/newsarticle/37187/abn-amro-app-breaks-record-with-1000000000-mobile-logins"
                              x="-180"
                              y="15"
                              position="bottom"
                              display="inline-table"
                              fontSize="14px"
                            />
                          </div>
                        </div>
                      </ScreenPanel>
                    </foreignObject>
                  </g>

                </svg>

              </div>
            </div>
          </Scene>
        </Section>
      </Wrapper >
    </Theme >
  );
}

export default SlideFour;