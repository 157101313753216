import { DragSource } from 'react-dnd';

export const ItemTypes = {
  BOX: 'box',
}

const style = {
  width: `94px`,
  height: `94px`,
  cursor: 'move',
  borderRadius: '50%',
  color: '#101a4d',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  fontWeight: '400',
  backgroundColor: '#F7B600',
  fontSize: '12px',
};

const Box = ({ name, image, isDragging, connectDragSource }) => {
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div>
      <div ref={connectDragSource} role={'Box'} data-id={`box-${name}`} style={{ ...style, opacity }}>
        {name}
      </div>
    </div>
  );
};

export default DragSource(ItemTypes.BOX, {
  beginDrag: (props) => {
    return { name: props.name, answer: props.answer };
  },
  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
  },
}, (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
})(Box);
