import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  Wrapper,
  Screen,
  Background,
  ScreenContent,
  ScreenMonitor,
  ScreenWrapper,
  ScreenText,
  ScreenVideo
} from './styles';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import Theme from '../../../../Theme';


import background from './assets/screen.jpg';
import monitor from './assets/iMac-Mockup.png';

function CustomerExperience() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {

    const element = ref.current;

    gsap.to(
      element.querySelector(".section-1"), {
      scrollTrigger: {
        trigger: element.querySelector(".section-1"),
        start: "top top",
        end: "+=200%",
        pin: true,
        scrub: true
      },
    }
    );


    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        // markers: true
      }
    })
      .from(".customer-experience-intro", { y: "300%" })
      .to(".customer-experience-intro", { x: "-300%", delay: 1 })
      .from(".customer-experience-screen", { opacity: 0 })
      .from(".screenVideo", { y: "100%" })
      .from(".screenVideo", { opacity: 0 })

  }, []);

  return (
    <>

      <Theme onEnter="dark" onEnterBack="dark" onLeaveBack="dark">

        <Wrapper className="customer-experience-wrap" id="customer-experience" ref={ref}>

          <Scene Height="100vh" className="section-1" onEnter="dark" onEnterBack="dark" onLeaveBack="dark">

            <Background onEnter="dark" onEnterBack="dark" onLeaveBack="dark"/>
            <div className="customer-experience-intro">
              <h4>So what does the experience look like for Electronic Store customers?</h4>
            </div>
            <div className="customer-experience-screen">
              <Screen className="">
                <ScreenContent>
                  <ScreenVideo controls playsInline preload="true" className="screenVideo" src="https://player.vimeo.com/external/528288280.sd.mp4?s=46620087939001d8c404b86271cc88c427b4b0ec&profile_id=165" />
                </ScreenContent>

                <ScreenMonitor src={monitor} />
              </Screen>
            </div>


          </Scene>

        </Wrapper>

      </Theme>
    </>
  );
}

export default CustomerExperience;