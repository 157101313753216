import React, { useEffect } from 'react';
import { appState } from '../../../Stores/App';
import video from './assets/shutterstock_1008617539.mp4';

import {
  ModuleContainer,
  Module,
  ModuleTitle,
  ModuleIntro,
  ModuleBackground,
  Mask,
  Button,
} from './styles';

function DigitalAccount() {
  const {
    setMenuTheme,
    theme,
    overlay: hasOverlay,
  } = appState();

  useEffect(() => {
    if (theme === 'dark') {
      setMenuTheme("light");
    }
  }, [theme, setMenuTheme]);

  return (
    <ModuleContainer hasOverlay={hasOverlay}>
      <Module>
        <ModuleTitle>
          See how Consumers lives are made easier with <strong>Visa Smarter personal finance</strong>
        </ModuleTitle>
        <ModuleIntro>
          A group of friends are planning a trip away after a long lock down. Whilst they would usually do this face to face they have relied on digital communications and mobile banking to co-ordinate their holiday planning.
        </ModuleIntro>
        <Button to="/smarter-personal-finance">
          Explore
        </Button>
      </Module>
      <ModuleBackground>
        <video autoPlay muted playsInline preload="true" loop src={video} />
      </ModuleBackground>
      <Mask />
    </ModuleContainer>
  );
}

export default DigitalAccount;
