import React, { useRef, useEffect } from 'react';
import { Wrapper } from './styles';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from 'react-router-dom';

import vau from './assets/vau.png';
import drone from './assets/drone.png';
import chair from './assets/chair.png';


import {
  StarRatingWrapper
} from './styles';

function StarRating() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
  }, []);



  return (
    <Wrapper id="where-next" ref={ref}>

      <Section>

        <SectionBG className="sceneBG" />

        <Scene Height="100vh" className="section-1">

          <div className="container">

            <div className="scene">

              <div className="scene-container">

                <div className="where_heading">Where can we take you next?</div>

                <div className="row">
                  <div className="col">
                    <img src={drone} alt="" />
                    <p className="title">Explore the Lobby</p>
                    <Link to="/" className="btn">Explore content </Link>
                  </div>
                  <div className="col">
                    <StarRatingWrapper>
                      <p className="rating-title title">Was this content useful?</p>
                      <p className="rating-content">Please take a second to give us your anonymus feedback (1 is poor, 5 is great)</p>
                      <form className="rating-form">
                        <div className="rating-select__container">
                          <input type="radio" className="rating-select" name="star" id="star-5" />
                          <label htmlFor="star-5" className="star"></label>

                          <input type="radio" className="rating-select" name="star" id="star-4" />
                          <label htmlFor="star-4" className="star"></label>

                          <input type="radio" className="rating-select" name="star" id="star-3" />
                          <label htmlFor="star-3" className="star"></label>

                          <input type="radio" className="rating-select" name="star" id="star-2" />
                          <label htmlFor="star-2" className="star"></label>

                          <input type="radio" className="rating-select" name="star" id="star-1" />
                          <label htmlFor="star-1" className="star"></label>
                        </div>
                        <button className="form-btn" type="submit">Submit rating</button>
                      </form>
                    </StarRatingWrapper>
                  </div>
                  <div className="col">
                    <img src={vau} alt="" />
                    <p className="title">Return to Evolving Commerce</p>
                    <Link to="/" className="btn">Learn</Link>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </Scene>

      </Section>

    </Wrapper >
  );
}

export default StarRating;