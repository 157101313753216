import React from 'react';
import { appState } from '../../../Stores/App';
import Menu from '../Menu';
import Profile from '../Profile';

import { ReactComponent as MenuIcon } from './assets/menu.svg';
import { ReactComponent as ProfileIcon } from './assets/menu-profile.svg';

import {
  Nav,
  NavList,
  NavItem,
  NavIcon,
} from './styles';

function Navigation() {

  const {
    menu,
    navigation,
    profile,
    showMenu,
    showProfile,
  } = appState();

  const showNavItems = menu || profile;

  return (
    <>
      {navigation
        ?
        <>
          <Nav>
            <NavList>
              <NavItem onClick={showMenu}>
                <NavIcon isActive={menu}>
                  <MenuIcon />
                </NavIcon>
              </NavItem>

              {showNavItems
                ?
                <NavItem onClick={showProfile}>
                  <NavIcon isActive={profile}>
                    <ProfileIcon />
                  </NavIcon>
                </NavItem>
                :
                null
              }

            </NavList>
          </Nav>

          {menu && showNavItems ? <Menu /> : null}

          {profile && showNavItems ? <Profile /> : null}
        </>
        :
        null
      }
    </>
  );
}

export default Navigation;
