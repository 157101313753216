import styled from 'styled-components';

export const Revealer = styled.div`
	width: 100%;

	.tooltip-connected-devices {
		display: inline;
	}
`;

export const RevealTitle = styled.div`
	font-size: calc( 24px + (32 - 24) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
	line-height: 1.2;
	color: #F7B600;
	margin-bottom: 10px;

	${props => props.minFontSize && props.maxFontSize && `
		font-size: calc( ${props.minFontSize}px + (${props.maxFontSize} - ${props.minFontSize}) * (100vw - 400px) / (${window.innerWidth} - 400));
  	`}

	${props => props.mb && `
    	margin-bottom: ${props.mb}em;
  	`}
`;

export const RevealContent = styled.div`
	font-size: calc( 16px + (22 - 16) * (100vw - 400px) / (1400 - 400));
	margin-bottom: 10px;
	font-weight: 300;

	> div {
		display: inline;
	}

	.tooltip__wrapper {
		z-index: 4;
	}
	.tooltip__title {
		width: 250%;
	}

	a {
		color: inherit;
	}

	ul, ol {
		margin: 0;
		padding: 0;
		padding-left: 1.2em;
		margin-bottom: 0.5em;

		li {
			margin: 0;
			+ li {
				margin-top: 0.5em;
			}
		}
	}

	${props => props.minFontSize && props.maxFontSize && `
		font-size: calc( ${props.minFontSize}px + (${props.maxFontSize} - ${props.minFontSize}) * (100vw - 400px) / (${window.innerWidth} - 400));
  	`}

	${props => props.color && `
    	color: ${props.color};
  	`}

	${props => props.pt && `
    	padding-top: ${props.pt}em;
  	`}

	${props => props.pb && `
    	padding-bottom: ${props.pb}em;
  	`}

	${props => props.width && `
    	width: ${props.width}%;
  	`}
`;

export const RevealDivider = styled.div`
	text-align: center;
	position: relative;

	${props => props.width && `
    	width: ${props.width}%;
  	`}
`;

export const RevealButton = styled.div`
	background-color: #F7B600;
	color: #101a4d;
	display: inline-block;
	border-radius: 2em;
	padding: 0.5em 3em;
	line-height: 1.2;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	position: relative;

	${props => props.isOpen && `
    	background-color: #929292;
  	`}
`;

export const Divider = styled.div`
	width: 100%;
	border-top: solid 1px #ccc;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
`;
