import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ModuleContainer = styled.div`
  padding-top: 10%;
  padding-bottom: 5%;

  ${props => props.hasOverlay && `
    opacity: 0.15;
    pointer-events: none;
  `}
`;

export const Module = styled.div`
  position: relative;
  z-index: 3;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 780px;
  text-align: center;
  margin: 0 auto;

  strong {
    font-weight: 600;
  }
`;

export const ModuleTitle = styled.h1`
  font-weight: 300;
  color: #F7B600;
  font-size: calc( 40px + (60 - 40) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1;
`;

export const ModuleIntro = styled.div`
  width: 540px;
  max-width: 100%;
  font-size: calc( 24px + (24 - 24) * (100vw - 400px) / (1400 - 400));
  text-align: center;
  margin-top: 2em;
  margin-bottom: 3em;
  color: #FFFFFF;
`;

export const ModuleBackground = styled.div`
  video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
  }
`;

export const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.7;
`;

export const Button = styled(Link)`
  min-width: 14em;
  display: inline-block;
  background-color: #F7B600;
  padding: 1rem 2.5rem;
  border-radius: 3em;
  margin-top: 10px;
  font-size: calc( 16px + (16 - 16) * (100vw - 400px) / (1400 - 400));
  cursor: pointer;
  white-space: nowrap;
  color: #101a4d;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: -0.03em;
  text-align: center;
`;
