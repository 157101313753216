import styled from 'styled-components';
import nextArrow from './assets/next-arrow.svg';


export const WelcomeWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 40;
    background: rgba(54, 56, 100, .8);
`;

export const Slider = styled.div`
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

`;

export const SlideWrapper = styled.div`
    color: #fff;
    max-width: 100%;
    width: 650px;
    background-color: #050616;
    box-shadow: 0px 0px 15px #050616;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .slick-dots {
        bottom: 40px;
        li {
            margin-right: 24px;
            button {
                height: 100%;
                width: 100%;
                &:before {
                    color: #fff;
                    opacity: 1;
                    font-size: 24px;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: #f7b600;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .slick-arrow {
        top: 92%;
        z-index: 5;
        width: auto;
    }

    .slick-next {
        right: 50px;
        display: flex !important;
        &:before {
            content: "NEXT";
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            display: inline-block;
        }
        &:after {
            content: url(${nextArrow});
            display: inline-block;
            margin-left: 12px;
            position: relative;
            top: 2px;
        }

        &.slick-disabled {
            &:before,
            &:after {
                opacity: 0;
            }
        }
    }

    .slick-prev {
        left: 75px;
        display: flex !important;

        &:before {
            content: "PREV";
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            order: 2;

        }
        &:after {
            content: url(${nextArrow});
            display: inline-block;
            margin-right: 12px;
            transform:rotate(180deg);
            position: relative;
            top: 2px;
            order: 1;
        }


        &.slick-disabled {
            &:before,
            &:after {
                opacity: 0;
            }
        }
    }

`;

export const SlideScreen = styled.div`
position: relative;
z-index: 5;
padding: 15%;
`;

export const SlideTitle = styled.div`
   color: #fff;
   font-weight: 300;
   text-align: center;
   font-size: calc( 40px + (60 - 40) * (100vw - 400px) / (1400 - 400));
   margin-bottom: 68px;
`;


export const SlideAction = styled.div`
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-size: calc( 40px + (60 - 40) * (100vw - 400px) / (1400 - 400));
    `;

export const SlideContent = styled.div`
    color: #fff;
    text-align: center;
    font-size: calc( 18px + (20 - 18) * (100vw - 400px) / (1400 - 400));
    font-weight: 300;
    line-height: 1.5;
    &.no-title {
        margin-top: 30%;
    }
`;

export const SlideButton = styled.div`
    display: inline-block;
    padding: 0.75rem 2.5rem;
    background-color: #F7B600;
    padding: 1.15rem 2.5rem;
    border-radius: 3em;
    font-size: 20px;
    margin: 56px auto;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    color: #101a4d;
    font-weight: 400;
    text-decoration: none;
`;

export const SlideImage = styled.div`
    margin: 56px 0;

    img {
        margin: auto;
    }
`;