import styled from 'styled-components';
import { ReactComponent as CrossIcon } from './assets/close.svg';

export const ProductWrapper = styled.div`
  &.menu-is-active {
    opacity: 0.25;
  }
`;

export const CloseIcon = styled(CrossIcon)`
  position: fixed;
  top: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  z-index: 10;
  fill: #fff;
  cursor: pointer;

  ${props => props.theme === "dark" && `
    fill: #101a4d;
  `}

  ${props => props.theme === "light" && `
    fill: #fff;
  `}

`;

export const Section = styled.div.attrs(({ Height }) => ({
  style: { height: `${Height}` }
}))`
  position: relative;
  color: #fff;
  z-index: 2;

  .scene {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    padding: 80px 50px 20px 20px;
  }

  ${props => props.light && `
    color: #101a4d;
  `}

  ${props => props.dark && `
    color: white;
  `}



`;


export const SectionBG = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #101a4d;
  background-image: url('/background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // margin-bottom: -100vh;
  overflow: hidden;

  + div {
    margin-top: -100vh !important;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate3d(-50%, -50%, 0);
    pointer-events: none;
  }

  ${props => props.light && `
    background-color: #c9c9cb;
    background-image: url('/background_light.jpg');
  `}

`;




export const Scene = styled.div.attrs(({ Height, Top, Bottom }) => ({
  style: {
    height: `${Height}`,
    marginTop: `${Top}`,
    marginBottom: `${Bottom}`,
  }
}))`
  position: relative;
  width: 100%;
  overflow: hidden;

  &.is-current {
    background-color: #101a4d;
  }

  .container {
    position: relative;
    height: 100%;
    // padding: 80px 50px 20px 20px;
    // display: grid;
    // align-items: center;
    // grid-template-columns: repeat(12, 1fr);
    // grid-column-gap: 1em;
    // grid-row-gap: 1em;
    // grid-template-rows: repeat(3, 1fr);
  }

`;
