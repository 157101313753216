import React, { useRef, useEffect } from 'react';
import HeroBG from '../../assets/hero.jpg';
import Start from 'Components/Product/Start';
import Devices from './Devices';

function Introduction() {

  return (
    <>

        <Start
          backgroundVideo="https://player.vimeo.com/external/528288280.sd.mp4?s=46620087939001d8c404b86271cc88c427b4b0ec&profile_id=165"
          title="<small>Introducing</small><br/> Visa Secure"
        />

        <Devices/>

    </>
  );
}

export default Introduction;