import React, { useRef, useEffect } from 'react';
import {
  Heading,
  Wrapper
} from './styles';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Carousel from "./HelpCarousel"

function MoreWays() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {

    const element = ref.current;

    gsap.to(
      element.querySelector(".section-1"), {
        scrollTrigger: {
          trigger: element.querySelector(".section-1"),
          start: "top top",
          end: "+=200%",
          pin: true,
          scrub: true
        },
      }
    );

    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        // markers: true
      }
    })
    .to(".heading",{ y: "-300%" }, "sceneOut")
    .from(".Carousel",{ y: "200%" }, "sceneOut")
    // .to(".sceneBG",{ x: "-100%" }, "sceneOut")

  }, []);


  return (
    <Wrapper id="more-ways" ref={ref}>

        <Section>

          <SectionBG className="sceneBG" light/>

          <Scene Height="100vh" className="section-1">

            <div className="container">

              <div className="scene">

                <Heading className="heading">Contact your Visa Account Team to learn how Visa Secure can help you deliver faster checkout times, added security, improved sales, and a better customer experience</Heading>

                <div className="Carousel">
                {/* <Carousel/> */}
                </div>

              </div>

            </div>

          </Scene>

        </Section>

    </Wrapper>
  );
}

export default MoreWays;