import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axiosConfig from '../../../Services/axiosConfig';
import { useLocation } from "react-router-dom";
import { appState } from '../../../Stores/App';
import { TextInput } from 'Components/Auth/Fields';
import CONFIG from '../../../config';
import { PAGE_TITLES } from '../../../constants';
import ReCAPTCHA from "react-google-recaptcha";
import ErrorMessages from '../ErrorMessages';
import { getApiErrorMessage } from '../helper';
import { Helmet } from "react-helmet";

function Activate() {
	const [errors, setErrors] = useState([]);
	const [passwordSet, setPasswordSet] = useState(false);
	const recaptchaRef = useRef(null);

	let location = useLocation();
	let id, code;

	const {
		hideNavigation,
		navigation,
	} = appState();

	useEffect(() => {
		if (navigation === true) {
			hideNavigation();
		}
	}, [navigation, hideNavigation]);

	const validationSchema = Yup.object({
		password: Yup.string()
			.required("Required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
	})

	if (location) {
		if (location.pathname) {
			id = location.pathname.split("/");
			id = decodeURIComponent(id[2]);
		}
		if (location.search) {
			code = decodeURIComponent(location.search.replace("?code=", ""));
		}
	}

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLES.ACTIVATE}</title>
			</Helmet>
			<div className="auth">
				<div className="authWrapper">
					<div className="formWrapper form-setup">
						<ErrorMessages errors={errors} />

						{!passwordSet ?
							<>
								<h2 class="form-header">Create password</h2>

								<Formik
									enableReinitialize
									initialValues={{
										password: '',
										confirmPassword: '',
									}}
									validationSchema={validationSchema}
									onSubmit={async (values) => {
										const token = await recaptchaRef.current.executeAsync();
										recaptchaRef.current.reset();
										setErrors([]);

										axiosConfig.post(
											`/identity/users/confirm-account`,
											{
												userId: id,
												code: code,
												password: values.password,
												confirmPassword: values.confirmPassword
											},
											{
												headers: {
													'g-recaptcha-response': token
												},
											}
										)
											.then((response) => {
												let statusCode = response.data.statusCode;

												switch (statusCode) {
													case 200:
														setPasswordSet(true);
														break;
													case 403:
														setErrors(response.data.errorMessages);
														break;
													case 400:
														setErrors(response.data.errorMessages);
														break;
													default:
														console.error('API error')
												}
											})
											.catch((error) => {
												const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'Unable to confirmm account';
												setErrors([`${message}`])
											});
									}}

									render={({ handleChange, handleSubmit }) => (
										<>
											<Form>
												<TextInput
													label="Password"
													placeholder="Password"
													name="password"
													type="password"
													className="text-field"
													helptext={`Minimum of 8 characters which must include numbers, letters and a symbol`}
												/>
												<TextInput
													label="Re-enter password"
													placeholder="Re-enter password"
													name="confirmPassword"
													type="password"
													className="text-field"
													helptext={`Re-enter to confirm password`}
												/>

												<div className="form__footer">
													<button className="form-btn" type="submit">
														Let's Go
													</button>
												</div>
												<ReCAPTCHA
													ref={recaptchaRef}
													size="invisible"
													sitekey={CONFIG.RECAPTCHA_SITE_KEY}
													onChange={handleSubmit}
												/>
											</Form>
										</>
									)}
								/>
							</>
							:
							<>
								<h2 className="form-header">Account Activated</h2>
								<p>Account has been created and password set</p>
								<div>
									<a href="/login" className="form-link">Login</a>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default Activate;
