/**

 FLYOUT
 ======================

 Background shape and mask for the phone flyout

*/

import React from 'react';
import { getFlyoutMaskX } from '../helpers';

import {
  FLYOUT_MASK_WIDTH,
} from '../constants';

function FlyoutBackground({ id, x, size }) {
  const posX = getFlyoutMaskX(`${x}`);

  switch (size) {
    case 'large':
      return (
        <>
          <defs>
            <clipPath id={`${id}-mask`}>
              <rect x={posX} y="0" width={`${FLYOUT_MASK_WIDTH}`} height="100%" />
            </clipPath>
          </defs>
          <path id={`${id}-flyout`} fill="#ffffff" opacity="0.6" d="M 670 621 C 670 621 676 574 612 574 S 531 631 557 651 C 571.329 659.099 578 672 577 689 C 577 704 560 712.5 552 712.5 H 245.334 C 245.334 712.5 233.334 712.834 233.334 727.167 V 943 C 233 951 233 960 247 960 L 676 961 L 670 621 Z" />
        </>
      )
    case 'medium':
      return (
        <>
          <defs>
            <clipPath id={`${id}-mask`}>
              <rect x={posX} y="0" width={`${FLYOUT_MASK_WIDTH}`} height="100%" />
            </clipPath>
          </defs>
          <path id={`${id}-flyout`} fill="#ffffff" opacity="0.6" d="M 670 621 C 670 621 676 574 612 574 S 531 631 557 651 C 571.329 659.099 578 672 577 689 C 577 704 560 712.5 552 712.5 H 245.334 C 245.334 712.5 233.334 712.834 233.334 727.167 V 916 C 233 925 237 930 247 929 L 677 923 L 670 621 Z" />
        </>
      )
    default:
      return (
        <>
          <defs>
            <clipPath id={`${id}-mask`}>
              <rect x={posX} y="0" width={`${FLYOUT_MASK_WIDTH}`} height="100%" />
            </clipPath>
          </defs>
          <path id={`${id}-flyout`} fill="#ffffff" opacity="0.6" d="M 670 621 c 0 0 6 -47 -58 -47 s -81 57 -55 77 c 14.329 8.099 21 21 20 38 c 0 15 -17 23.5 -25 23.5 H 245.334 c 0 0 -12 0.334 -12 14.667 v 120 c 0 0 -0.667 12.001 11.667 12.667 s 425.149 0 425.149 0 L 670 621 z" />
        </>
      )
  }
}

export default FlyoutBackground;
