import styled from 'styled-components';

export const CountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  justify-content: center;
`;

export const CountdownCard = styled.div`
  background-color: #050616;
  width: 125px;
  height: 130px;
  padding: 20px;
  text-transform: uppercase;

  &:nth-child(odd) {
    color: #F7B600;
  }
`;

export const CountdownNumber = styled.div`
  font-size: 74px;
  line-height: 59px;
  font-weight: 200;
`;

export const CountdownDescription = styled.div`
  font-size: 20px;
  line-height: 40px;
  font-weight: 200;
  letter-spacing: 10px;
  text-align: center;
  padding-left: 5px;
`;
