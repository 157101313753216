import styled from 'styled-components';

export const HeroWrapper = styled.div`
  height: 100vh;
  top: 0;
  position: relative;
  overflow: hidden;
  background-color: #111;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #101a4d;
    opacity: 0.7;
    z-index: 2;
  }

`;

export const HeroImage = styled.img`
	position: ${props => props.type === "secondary" ? "absolute" : "fixed" };
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate3d(-50%, -50%, 0);
`;

export const HeroVideo = styled.video`
	position: ${props => props.type === "secondary" ? "absolute" : "fixed" };
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate3d(-50%, -50%, 0);

  + ${HeroImage} {
    display: none;
  }

`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 3;
`;

export const HeroTitle = styled.h1`
	font-weight: 300;
  line-height: 1.1666666667;
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: calc( 50px + (90 - 50) * (100vw - 400px) / (1400 - 400));

  small {
    font-size: 50%;
  }
`;

export const HeroIntro = styled.p`
	font-weight: 300;
  margin: 0;
  font-size: calc( 20px + (24 - 20) * (100vw - 400px) / (1400 - 400));
`;