import { useField } from "formik";
import { CheckboxStyled } from './styles';

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input id={props.name} className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
      {props.helptext ? (
        <small>{props.helptext}</small>
      ) : null}

    </div>
  );
};

export const Checkbox = ({ value, id, name, children, checked, onChange }) => {
  const checkbox = (
    <>
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
        aria-checked={checked}
        id={id}
        name={name}
      />
      <label htmlFor={id}>
        <span className="checkbox_label">{children}</span>
      </label>
    </>
  );

  return (
    <CheckboxStyled>
      {checkbox}
    </CheckboxStyled>
  );
};

export default Checkbox;
