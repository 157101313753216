import styled from 'styled-components';

export const VAUWrapper = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
`;

export const VAUHeading = styled.p`
  display: flex;
  color: #1A1F71;
  font-size: 22px;
`; 

export const VAUInfoGraphic = styled.div`
  display: flex;
  flex-direction: column;
  color: #1A1F71;
  margin: 20px auto;
`; 

export const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
`;

export const NavButton = styled.div`
  display: flex;
  background #1A1F71;
  color: white;
  padding: 15px 10px;
`; 

