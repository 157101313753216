import styled from 'styled-components';
import { getHeight, getWidth } from '../../../helpers'

export const ScreenPanel = styled.div`
  ${props => props.height && `
    height: ${getHeight(props.height)};
  `}

  ${props => props.width && `
    width: ${getWidth(props.width)};
  `}

  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 0.45rem 1rem 0.45rem 0.45rem;
  width: 100%;
  font-size: calc( 12px + (12 - 12) * (100vw - 400px) / (1400 - 400));
  line-height: 1.2;
  min-height: 64px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  p {
    padding: 0 1em;
  }
`;

export const Img = styled.img`
  height: 100%;
`;

export const RevealY = styled.div`
  position: relative;
  overflow: hidden;
  height: ${getHeight(675)};

  img {
    position:absolute;
    top: 100%;
  }
`;

export const RevealX = styled.div`
  position:absolute;
  left: 100%;
`;
