import styled from 'styled-components';
import background from './assets/background.jpg';
import { ReactComponent as CrossIcon } from './assets/close.svg';


export const ArticleWrapper = styled.div`
background: url(${background});
background-size: cover;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: left;
    padding: 0 10%;
    h3, h6, p {
    font-weight: 300;
    z-index: 7;
    }

    h3{
        font-size: calc( 20px + (36 - 20) * (100vw - 400px) / (1400 - 400));
        color: #f7b600;
        margin: 0 0 32px;
    }

    h6 {
        font-size: calc( 14px + (20 - 14) * (100vw - 400px) / (1400 - 400));
        color: #ffffff;
        margin: 0;
        margin: 0 0 18px;

    }

    li, p {
        margin-bottom: 12px;
        font-weight: 300;
        color: #ffffff;
        font-size:  calc( 14px + (18 - 14) * (100vw - 400px) / (1400 - 400));
    }

    .article-intro {
        margin: 120px 0 24px;
        border-bottom: 1px solid #ffffff;
        display: flex;
    }

    .article-content {
        height: 50%;
        overflow-y: auto;
    }

    .text-bold {
        font-weight: 700;
    }

    .text-italic {
        font-style: italic;
    }

    .columns {
        display: flex;
        .column {
            padding: 10px;
        }

        .article-intro__image {
            width: 100%;
            height: 250px;
            overflow: hidden;

            img {
                position: relative;
                top: -35%;
            }
        }
    }
    `;

export const ArticleContent = styled.div`
height: 100%;
  `;

export const ArticleBody = styled.div`
height: 50%;
overflow: auto;

.article-image {
    width: 80%;
    display: block;
    margin: auto;
}
`;

export const CloseIcon = styled(CrossIcon)`
    position: fixed;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    z-index: 10;
    fill: #fff;
    cursor: pointer;

    ${props => props.theme === "dark" && `
      fill: #101a4d;
    `}

    ${props => props.theme === "light" && `
      fill: #fff;
    `}

  `;