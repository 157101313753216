import React from 'react';
import Team from './Team';
import BusinessBenefits from './BusinessBenefits';
import Theme from '../../../../Theme';

function TargetAudience() {

  return (
    <>
      <Theme onEnter="light" onEnterBack="light" onLeaveBack="light">
        <Team />
      </Theme>
      <Theme onEnter="dark" onLeave="light">
        <BusinessBenefits />
      </Theme>
    </>
  );
}

export default TargetAudience;