import styled from 'styled-components';

export const SectionTitle = styled.div`
	color: #fff;
	padding: 5vw 0;
	text-align: center;
	font-size: calc( 30px + (40 - 30) * (100vw - 400px) / (1400 - 400));
	font-weight: 300;
`;

export const Wrapper = styled.div`
	
	.section-1 {
		height: 100vh;
	}

	.secureCube {
		display: block;
		margin: 0 auto;
		width: 300px;
		max-width: 100%;
	}

`;



export const FlowWrapper = styled.div`
	position: relative;
	padding: 0 40px;
`;


export const FlowContainer = styled.div`
	position: relative;

	&.master {
		margin: 20vh 0;
	}

	&.Number3Group {
		margin-top: -4vw;
		margin-bottom: -4vw;
	}

	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

`;

export const FlowElement = styled.div`
position: relative;
z-index: 1;
`;


export const FlowOverlay = styled.div.attrs(({ level }) => ({
  style: { zIndex: `${level}` }
}))`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	&.bottom {
		top: auto;
		bottom: 0;
	}

	&.Number4Final {
		bottom: 35px;
	}

	&.ring {
		top: 50%;
		left: 50%;
		transform: rotate(0deg) translate(-50%, -50%);
		transform-origin: top left;
		width: 100%;
	}


`;