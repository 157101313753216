import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  ${props => props.hasOverlay && `
    opacity: 0.1;
    background-color: rgba(4,11,48, 0.85);
  `}

  .slick-arrow {
    z-index: 1;
    width: 48px;
    height: 48px;
    fill: #ffffff;
    color: #ffffff;
    -webkit-appearance: none;

    &.slick-disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .slick-prev {
    left: 3.5%;
    transform: rotate(180deg);
  }

  .slick-next {
    right: 3.5%;
  }
`;


export const ModuleContainer = styled.div.attrs(({ items, offset }) => ({
  style: {
    width: `${items * 100}vw`,
    transform: `translateX(-${offset * 100}vw)`
  }
}))`
  transition: transform 0.5s ease;
  display: flex;
`;

export const Module = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #111;
  padding: 40px;
  padding-top: 250px;
  padding-bottom: 80px;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: 300;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #101a4d;
    opacity: 0.8;
  }
`;

export const ModuleText = styled.div`
  position: relative;
  z-index: 3;
  max-width: 100%;
  line-height: 1.2;

  strong {
    font-weight: 600;
  }

  .btn {
    display: inline-block;
    padding: 0.75rem 2.5rem;
    background-color: #F7B600;
    padding: 1.15rem 2.5rem;
    border-radius: 3em;
    margin-top: 10px;
    font-size: 20px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 30px;
    position: relative;
    color: #101a4d;
    font-weight: 400;
    text-decoration: none;
  }
`;

export const ModuleTitle = styled.h1`
  font-weight: 300;
  color: #F7B600;
  font-size: calc( 40px + (60 - 40) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1;
`;

export const ModuleSubTitle = styled.h2`
  font-weight: 300;
  color: #F7B600;
  font-size: calc( 40px + (60 - 40) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  margin-bottom: 80px;
  line-height: 1;
  width: 880px;
  max-width: 100%;
`;

export const ModuleIntro = styled.div`
  padding-left: 5px;
  width: 760px;
  max-width: 100%;
  font-size: calc( 20px + (24 - 20) * (100vw - 400px) / (1400 - 400));
`;

export const ModuleBackground = styled.div`

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
  }

`;

export const Navigation = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  color: #fff;
  display: flex;
`;

export const NavigationTitle = styled.span`
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center;
  display: block;
  position: absolute;
  width: calc(100% + 24px);
  text-align: center;
  top: 100%;
  line-height: 1.3;
  p {
    margin-top: 16px;
    font-size: 12px;
    margin-left: -24px;
  }
`;

export const NavigationItem = styled.span`
  background-color: transparent;
  padding: 0.75rem 2.5rem;
  border-radius: 50%;
  padding: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 72px;
  position: relative;
  color: #ffffff;
  font-weight: 400;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 0;
    margin-top: -1px;
    width: 74px;
    height: 1px;
    background-color: #fff;
    color: white;
    z-index: -1;
  }

  &:last-child {
    margin-right: 0;

    &:after {
      display: none;
    }

  }

  ${props => props.active && `
    background-color: #F7B600;
    color: #101a4d;
    border: 1px solid #F7B600;

    .navigationTitle {
      color: #fff;
    }
  `}
`;

export const ProgressIndicator = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  color: #fff;
  display: flex;
`;

export const ProgressIndicatorItem = styled.span`
  background-color: #fff;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0 0.25em;

  ${props => props.active && `
    background-color: #F7B600;
  `}
`;
