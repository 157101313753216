import styled from 'styled-components';
import ring from './assets/ring.png';

export const Carousel = styled.div`
	width: 80vw;
	margin: 0 auto;

	.slick-slider {
		width: 100%;
	}

	.slick-arrow {
		width: 60px;
		height: 60px;
		fill: #101a4d;
		-webkit-appearance: none;

		&.slick-disabled {
			opacity: 0.2;
		}

	}

	.slick-prev {
		left: -100px;
	}

	.slick-next {
		right: -100px;
	}

`;

export const CarouselWrapper = styled.div`
	display: flex;
	// width: 80vw;
    // width: calc(80vw - 80px);
    padding: 0 100px;
    margin: 0 auto;
`;

export const CarouselNav = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 -10px;
	margin-top: 2vh;
`;

export const CarouselArrow = styled.div`
	width: 100px;
`;


export const CarouselNavItem = styled.div`
	width: ${props => `calc(100% /${props.numberOfSlides})` || "25%"};
	position: relative;
	padding: 10px;
	cursor: pointer;
`;

export const CarouselNavItemLabel = styled.div`

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	background-color: #102c66;
	color: #fff;
	text-align: center;

	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.is-active,
	&:focus {
		background-color: #F7B600;
	}

`;



export const CarouselItem = styled.div`
	display: flex !important;
	align-items: center;
`;


export const CarouselItemMedia = styled.div`
	width: 450px;
	position: relative;
	padding: 0 0 150px 0;

	.details {
		position: absolute;
		top: 150px;
		left: 150px;
		background-color: #F7B600;
		color: #fff;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 300px;
		height: 300px;
		transform: translate(-20px, -20px);
		z-index: 1;
		padding: 45px;
		line-height: 1.4;

		span {
			display: block;
		}

		.expert {
			color: #102c66;
		}

		.name {
			font-size: 1.2em;
		}

		.title {
			font-size: 0.8em;
		}

	}

`;

export const CarouselItemContent = styled.div`
	width: 50%;
	padding-left: 30px;
	color: #102c66;

	p {
		&:before {
			content: "“";
		}
		&:after {
			content: "”";
		}
	}

`;


export const CarouselAvatar = styled.div`
	width: 300px;
	padding: 45px;

	img {
		position: relative;
		z-index: 2;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-image: url(${ring});
		background-size: 100%;
		background-position: center;
		width: 300px;
		height: 300px;
		z-index: 0;
	}

`;