import React from 'react';
import Theme from '../../../../Theme';
import SlideOne from './SlideOne';

function Audience() {
    return (
        <Theme onEnter="light" onEnterBack="light" onLeaveBack="dark">
            <SlideOne />
        </Theme>
    );
}

export default Audience;