import React, { useRef, useEffect } from 'react';
import {
  Wrapper,
  VisaSecure,
  TabletWrapper,
  Tablet,
  TabletDevice,
  TabletShadow,
  TabletContent
} from './styles';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Hero from 'Components/Product/Hero';
import Reveal from 'Components/Product/Reveal';

import TooltipHelper from 'Components/TooltipHelper';

import Florian from './assets/florian.png';
import Alyssa from './assets/alyssa.png';
// import Nicole from './assets/nicole.png';
import Background from './assets/Fake_Merchant.jpg';

import SecureCube from './assets/Capability_Cube_Visa_Secure.png';
import tablet from './assets/tablet-device.png';
import tabletShadow from './assets/tablet-shadow.png';

function Team() {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {

    const element = ref.current;

    gsap.to(
      element.querySelector(".hero-section"), {
        scrollTrigger: {
          trigger: element.querySelector(".hero-section"),
          start: "top top",
          end: "+=100%",
          pin: true,
          scrub: true
        },
      }
    );

    gsap.to(
      element.querySelector(".section-1"), {
        scrollTrigger: {
          trigger: element.querySelector(".section-1"),
          start: "top top",
          end: "+=600%",
          pin: true,
          scrub: true
        },
      }
    );

    gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        // markers: true
      }
    })
    .to(".teamCarousel__member--1",{ opacity: 0, delay: 2 } )
    .from(".teamCarousel__member--2",{ opacity: 0, zIndex: 'auto'} )
    .to(".teamCarousel__member--2",{ zIndex: 4 })
    .to(".teamCarousel__member--2",{ opacity: 0, zIndex: 'auto' } )
    .from(".welcome-to-secure",{ opacity: 0, delay: 0.5 }, "team-video-in" )
    .from(".tablet-video",{ opacity: 0, delay: 0.5 }, "team-video-in" )



  }, []);

  return (

    <Wrapper id="the-team" ref={ref}>

      <div className="hero-section">
        <Hero
          title="Let’s look at the challenges facing the electronicstore.com team"
          type="secondary"
        />
      </div>

      <Section dark>

        <SectionBG dark/>

        <Scene Height="100vh" className="section-1">

          <div className="container">

            <div className="scene">

              <div className="teamCarousel__member teamCarousel__member--1">

                <div className="teamCarousel__quote">
                  I'm under pressure from the CRM team to reduce the decline rate for genuine customers, but genuine fraud is hitting our bottom line!
                </div>

                <div className="teamCarousel__photo">
                  <img src={Florian} alt="Florian"/>
                </div>

                <div className="teamCarousel__details">
                  <Reveal
                    title="Florian Valence"
                    preview="Title: Authorisation Management"
                    content={`<p><strong>Who they are:</strong></p>
<ul>
<li>Establish authorization policies based on organization’s risk management strategy</li>
<li>Responsible for managing authorization performance</li>
</ul>

<p><strong>Why they care:</strong></p>
<ul>
<li>Provides a higher-level of confidence on approving transactions (can reduce false declines)</li>
<li>Provides additional information to be included in the authorization strategy</li></ul>`}
                  />
                </div>

              </div>

              <div className="teamCarousel__member teamCarousel__member--2">

                <div className="teamCarousel__quote">
                  Fraud is costing my business. I'm responsible for keeping fraudulent activity down, but our current measures are not fit for purpose.
                </div>

                <div className="teamCarousel__photo">
                  <img src={Alyssa} alt="Alyssa"/>
                </div>

                <div className="teamCarousel__details">
                  <Reveal
                    title="Alyssa Marshal"
                    preview="Title: Fraud Analyst"
                    content={`<p><strong>Who they are:</strong></p>
<ul>
<li>Define acceptable business risk to their organisation</li>
<li>Collaborate closely with security team to determine an acceptable risk plan
</li>
<li>Conduct detailed fraud analysis and risk model development</li>
</ul>

<p><strong>Why they care:</strong></p>
<ul>
<li>Authentication is a key component in a risk management strategy</li></ul>`}
                  />
                </div>
              </div>

              <div className="welcome-to-secure">
                <VisaSecure>
                <h4>Introducing Visa Secure</h4>
                <p className="subtitle">Helping to solve the Electronic Store team's problem.</p>
                <p className="subtitle">Visa’s solution to the EMV 3DS protocol, designed to increase protection to the card holder and ensure compliance with the latest regulatory requirements.
                <TooltipHelper
                        id="tooltip-team"
                        index="2"
                        title="The Payment Services Directive (PSD2) came into force in January 2018 and requires that strong customer Authentication (SCA) be applied to all electronic payments - including proximity, remote and m-payments - within the European Economic Area (EEA). The deadline for compliance was 31st December 2020, and the new regulations apply to banks and merchants in the EEA and UK. Enforcement for PSD2 SCA for e-commerce in the UK begins on 21 September 2021."
                        x="0"
                        y="0"
                        position="bottom"
                      /></p>
                </VisaSecure>
              </div>

              <TabletWrapper className="tablet-video">
                <Tablet>
                    <TabletDevice src={tablet} alt="tablet"/>
                    <TabletShadow src={tabletShadow} alt="shadow"/>
                    <TabletContent>
                      <div className="tablet-embed">
                      <video autoPlay muted playsInline preload="true" loop src="https://player.vimeo.com/external/528288224.sd.mp4?s=7de890f5158067ef12d68570a96c2bf81fc032bd&profile_id=165"/>
                      </div>
                    </TabletContent>
                  </Tablet>
              </TabletWrapper>

            </div>

          </div>

        </Scene>

      </Section>

    </Wrapper>

  );

}

export default Team;