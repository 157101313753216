import React, { useState, useEffect, useRef } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import axiosConfig from '../../../Services/axiosConfig';
import { TextInput } from 'Components/Auth/Fields';
import CONFIG from '../../../config';
import { PAGE_TITLES } from '../../../constants';
import { userState } from 'Stores/User';
import { setSession, setRefreshToken } from 'Services/auth';
import { useHistory } from "react-router-dom";
import Marquee from 'Components/Auth/Marquee';
import ReCAPTCHA from "react-google-recaptcha";
import { appState } from '../../../Stores/App';
import ErrorMessages from '../ErrorMessages';
import { getApiErrorMessage } from '../helper';
import { Helmet } from "react-helmet";

function Login() {
	const history = useHistory();
	const [errors, setErrors] = useState([]);
	const recaptchaRef = useRef(null);

	const {
		setToken,
		setProfile,
		setBookmarks,
	} = userState();

	const {
		navigation,
		hideNavigation,
	} = appState();

	const validationSchema = Yup.object({
		username: Yup.string()
			.required("Required"),
		password: Yup.string()
			.required("Required"),
	})

	useEffect(() => {
		if (navigation === true) {
			hideNavigation();
		}
	}, [navigation, hideNavigation]);

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLES.LOGIN}</title>
			</Helmet>
			<Marquee />
			<div className="auth">
				<div className="authWrapper">
					<div className="formWrapper">
						<ErrorMessages errors={errors} />

						<Formik
							initialValues={{ username: '', password: '' }}
							validationSchema={validationSchema}
							onSubmit={async (values) => {
								const token = await recaptchaRef.current.executeAsync();
								recaptchaRef.current.reset();
								setErrors([]);

								axiosConfig.post(
									`/identity/auth/token`,
									{
										username: values.username,
										password: values.password
									},
									{
										headers: {
											'g-recaptcha-response': token
										},
										withCredentials: true
									}
								)
									.then((response) => {
										let statusCode = response.status;
										switch (statusCode) {
											case 200:
												setToken(true);
												setSession(response.data.token, response.data.refreshTokenExpiration);
												setRefreshToken(response.data.refreshToken, response.data.refreshTokenExpiration)

												// axiosConfig.get(`/bookmarks`)
												// 	.then((response) => {
												// 		setBookmarks(response.data.returnedObject);
												// 	}, (error) => {
												// 		console.error('Unable to load bookmarks');
												// 	});

												// axiosConfig.get(`/identity/users/my-profile`)
												// 	.then((response) => {
												// 		setProfile(response.data.returnedObject);
												// 		history.push("/");
												// 	}, (error) => {
												// 		console.error('Unable to load profile');
												// 	});


												axiosConfig.get(`/my-user-info`)
													.then((response) => {
														const { returnedObject } = response?.data;
														const { profile, bookmarks, starRatings } = returnedObject;

														console.log('response', response);
														console.log('response.data', response.data);
														console.log('starRatings', starRatings);

														setProfile(profile);
														setBookmarks(bookmarks);
														history.push("/");
													}, (error) => {
														console.error('Unable to load data');
													});
												break;
											default:
												setErrors(response.data.errorMessages);
										}

									})
									.catch((error) => {
										const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'Invalid Login';
										setErrors([`${message}`])
									});
							}}
						>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
								<form onSubmit={handleSubmit} className="form-setup">
									<h2 className="form-header">Please sign in</h2>
									<p className="form-intro">New users? <a className="form-link" href="/register">Create an account</a></p>
									<div className="form-input__wrapper">
										<TextInput
											type="text"
											value={values.username}
											label="Username"
											placeholder="Username"
											name="username"
											onChange={handleChange}
											onBlur={handleBlur}
											className="text-field"
										/>
										<TextInput
											type="password"
											value={values.password}
											label="Password"
											placeholder="Password"
											name="password"
											onChange={handleChange}
											onBlur={handleBlur}
											className="text-field"
										/>
									</div>
									<div className="form__footer btn-center">
										<button type="submit" className="form-btn">
											Sign in
										</button>
									</div>
									<ReCAPTCHA
										ref={recaptchaRef}
										size="invisible"
										sitekey={CONFIG.RECAPTCHA_SITE_KEY}
										onChange={handleSubmit}
									/>
									<div className="form-link__wrapper">
										<a href="/forgot-password" className="form-link">Reset password</a>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
