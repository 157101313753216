import styled from 'styled-components';
import Background from './assets/Fake_Merchant.jpg';


export const Wrapper = styled.div`

  position: relative;
  z-index: 1;
  .hero-section {
    &:after {
      background-image: url(${Background});
      background-size: cover;
      content: "";
      display: block;
      left: 0;
      top: 0;
      opacity: .3;
      position: absolute;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      }

      .hero__content {
        z-index: 2;
      }
    }

  .scene {
  	position: relative;
  	width: 100%;
  	height: 100vh;
  }

  .teamCarousel__member {
  	display: flex;
  	align-items: center;
    position: absolute;
    top: 0;
    left: 20px;
    right 20px;
    height: 100%;
  }

  .teamCarousel__member--1{
    .teamCarousel__photo {
      position: relative;
      left: 3%;
    }
  }

  .teamCarousel__member--2{
    .teamCarousel__photo {
      position: relative;
      left: -1vw;
    }
  }

  .teamCarousel__photo {

  	width: 25%;

  	img {
  		max-height: 90vh;
  	}
  }

  .teamCarousel__details {
  	width: 30%;
  }

  .teamCarousel__quote {
  	width: 40%;
  	color: #F7B600;
  	font-size: calc( 20px + (28 - 20) * (100vw - 400px) / (1400 - 400));
    padding-left: 8%;
    text-align: right;
    position: relative;

    &:before {
      content: '"';
      position: absolute;
      top: 0;
      left: 18%;
      font-size: 2em;
      line-height: 0;
      font-style: italic;
      font-weight: 600;
    }
  }


  .welcome-to-secure {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    padding-left: 20px;

    > img {
      width: 300px;
      margin-bottom: -2%;
    }

  }

`;
export const VisaSecure = styled.div`
h4 {
  font-size: calc( 24px + (32 - 24) * (100vw - 400px) / (1400 - 400));
  font-weight: 300;
  line-height: 1.2;
  color: #F7B600;
  margin-bottom: 10px;
}

.subtitle {
  font-size: calc( 16px + (22 - 16) * (100vw - 400px) / (1400 - 400));
  margin-bottom: 10px;
  font-weight: 300;
}
}
`;

export const TabletWrapper = styled.div`

  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  height: 100%;
`;


export const Tablet = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
`;



/*

  Width: 886px;
  Height: 523px;

*/
export const TabletDevice = styled.img`
  position: relative;
  z-index: 2;
`;

export const TabletShadow = styled.img`
  position: absolute;
  top: 100%;
  left: 0;
`;


/*

  Width: 843px;
  Height: 476px;
  Top: 25px;
  Left: 20px;

*/
export const TabletContent = styled.div`
  position: absolute;
  top: 4.780114722753346%;
  left: 2.2573363431151243%;
  width: 95.14672686230247%;
  height: 91.01338432122371%;
  z-index: 0;
  background-color: #000;

  .tablet-embed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    padding-bottom: 56.25%;
    width: 100%;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

`;