import React from 'react';
import styled from 'styled-components';
import { appState } from '../../Stores/App';

const ContentContainer = styled.div`
  transition: opacity 0.25s ease;
  ${props => props.hasOverlay && `
    background-color: rgba(4,11,48, 0.85);
  `}
`;

function Content(props) {
  const {
    overlay: hasOverlay,
  } = appState();

  return (
    <ContentContainer hasOverlay={hasOverlay}>
      {props.children}
    </ContentContainer>
  );
}

export default Content;
