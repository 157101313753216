import React, { useRef, useEffect } from 'react';
import { Section, SectionBG, Scene } from 'Components/Product/styles';
import { gsap } from 'gsap';
import { TimelineMax } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from 'react-router-dom';
import vau from './assets/vau.png';
import chair from './assets/chair.png';
import StarRating from '../../../../StarRating';
import { animationPause } from '../../../animations';
import { useHistory } from 'react-router-dom'
import Theme from '../../../../../Theme';

import {
  Wrapper
} from './styles';

const STAR_RATING_PAYLOAD = {
  objectId: "Smarter Personal Finance",
  objectType: "section",
  category: "",
  comments: "",
}

function SlideOne() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ID = 'whernext__slide-1__animation';
  const history = useHistory();


  useEffect(() => {
    const element = ref.current;

    gsap.to(
      element.querySelector(`.${ID}-scene`), {
      scrollTrigger: {
        trigger: element.querySelector(`.${ID}-scene`),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: true,
        anticipatePin: 1,
      },
    }
    );

    const timeline = new TimelineMax({
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        anticipatePin: 1,
      }
    })

    timeline
      .add(animationPause());
  }, []);

  const returnToModule = (e) => {
    history.push("/module/digital-account");
  }

  return (
    <Theme onEnter="light" onEnterBack="light" onLeaveBack="light">
      <Wrapper ref={ref}>
        <Section>
          <SectionBG />
          <Scene Height="100vh" className={`${ID}-scene`}>
            <div className="container">
              <div className="scene">
                <div className="scene-container ">
                  <div className="where_heading">Where can we take you next?</div>
                  <div className="row">
                    <div className="col">
                      <img src={chair} alt="" />
                      <p className="title">Explore the Lobby</p>
                      <Link to="/" className="btn">Explore content </Link>
                    </div>
                    <div className="col">
                      <StarRating payloadObject={STAR_RATING_PAYLOAD} />
                    </div>
                    <div className="col">
                      <img src={vau} alt="" />
                      <p className="title">Return to the start of Smarter Personal Finance</p>
                      <button onClick={(e) => returnToModule(e)} className="btn">Let's go</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scene>
        </Section>
      </Wrapper>
    </Theme>
  );
}
 
export default SlideOne;
