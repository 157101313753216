import styled from 'styled-components';

export const MoreButton = styled.span`
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  font-size: calc( 13px + (13 - 13) * (100vw - 400px) / (1400 - 400));
  display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
  > svg {
    margin-left: 0.5em;
  }
`;

export const MorePanel = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  min-height: 149px;
  color: #ffffff;
  padding: 18px 120px 18px 18px;

  ${props => props.size === "small" && `
    height: 133px;
    min-height: 133px;
  `}
`;

export const MoreTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.5em;

  ${props => props.size === "small" && `
    margin-bottom: 0.5em;
  `}
`;

export const MoreText = styled.div`
  color: #ffffff;
`;
