import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CloseButton = styled.div`
	position: absolute;
	top: 30px;
	right: 30px;
	fill: #fff;
	width: 40px;
	height: 40px;
	cursor: pointer;
  z-index: 1;
`;

export const NewsroomTitle = styled.h1`
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  font-weight: 300;
  color: #FFFFFF;
  font-size: calc( 40px + (74 - 40) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  text-align: center;
  line-height: 1;
`;

export const NewsroomIntro = styled.p`
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  font-weight: 200;
  color: #FFFFFF;
  font-size: calc( 18px + (20 - 18) * (100vw - 400px) / (1400 - 400));
  margin: 0;
  text-align: center;
  line-height: 1;
`;

export const NewsroomContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`;

export const NewsroomContent = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  width: 70%;
  transform: translateX(-50%);
  display: flex;
`;

export const NewsroomTextWrapper = styled.div`
  width: 40%;
  padding-left: 2em;
  padding-bottom: 2em;
`;

export const NewsroomText = styled.div`
  color: #F7B600;
  text-align: left;
  font-weight: 300;
  font-size: calc( 20px + (28 - 20) * (100vw - 400px) / (1400 - 400));
  line-height: 1.3;
  margin-bottom: 0.5em;
`;

export const NewsroomLink = styled(Link)`
  color: #FFFFFF;
  text-align: left;
  font-size: calc( 14px + (16 - 14) * (100vw - 400px) / (1400 - 400));
  line-height: 1.3;
  font-weight: 200;
  text-decoration: none;
  width: fit-content;
  padding-right: 1.1em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::after {
    content: '';
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #F7B600;
    display: block;
  }
`;

export const NewsroomImage = styled.img`
  width: 50%;
  color: #FFFFFF;
  text-align: left;
  font-size: calc( 20px + (28 - 20) * (100vw - 400px) / (1400 - 400));
  line-height: 1.3;
  display: block;
  padding-right: 1em;
  padding-top: 0.25em;
`;

export const ProgressIndicator = styled.div`
  color: #fff;
  display: flex;
  position: absolute;
  bottom: 0;
`;

export const ProgressIndicatorItem = styled.span`
  background-color: #fff;
  width: 0.813em;
  height: 0.813em;
  border-radius: 50%;
  margin: 0 0.25em;
  cursor: pointer;

  ${props => props.active && `
    background-color: #F7B600;
  `}
`;
