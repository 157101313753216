import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { appState } from '../../../Stores/App';
import { Helmet } from "react-helmet";
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { productState } from 'Stores/Product';
import pushDataLayer from '../../../helpers/gtm';
import { PAGE_TITLES } from '../../../constants';

import { ProductNav, ProductNavIndicator } from './styles';

function Navigation() {
	gsap.registerPlugin(ScrollToPlugin);
	const [id, setId] = React.useState();
	const location = useLocation();
	const {
		theme
	} = appState();

	const {
		progress
	} = productState();

	let macroContentProgress = 0;
	let audienceProgress = 0;
	let createProgress = 0;
	let payProgress = 0;
	let manageProgress = 0;
	let engageProgress = 0;
	let thankyouProgress = 0;
	let benefitsProgress = 0;
	let whereNextProgress = 0;

	useEffect(() => {
		if (!id) return;
		pushDataLayer({ pagePath: `${location.pathname}/${id}`, pageName: `${document.title} ${id}` });
	}, [id, location]);

	if (progress) {

		let macroContent = progress.find(section => section.id === 'macro-content');
		if (macroContent) {
			macroContentProgress = macroContent.progress
		}

		let audience = progress.find(section => section.id === 'audience');
		if (audience) {
			audienceProgress = audience.progress
		}

		let create = progress.find(section => section.id === 'create');
		if (create) {
			createProgress = create.progress
		}

		let pay = progress.find(section => section.id === 'pay');
		if (pay) {
			payProgress = pay.progress
		}

		let manage = progress.find(section => section.id === 'manage');
		if (manage) {
			manageProgress = manage.progress
		}

		let engage = progress.find(section => section.id === 'engage');
		if (engage) {
			engageProgress = engage.progress
		}

		let thankyou = progress.find(section => section.id === 'thankyou');
		if (thankyou) {
			thankyouProgress = thankyou.progress
		}

		let benefits = progress.find(section => section.id === 'benefits');
		if (benefits) {
			benefitsProgress = benefits.progress
		}

		let whereNext = progress.find(section => section.id === 'where-next');
		if (whereNext) {
			whereNextProgress = whereNext.progress
		}

	}

	const jumpTo = (id) => {
		if (id) {
			gsap.to(window, 0.5, { scrollTo: { y: id, offsetY: -1 } });
			setId(id);
		}
	}

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLES.SMARTER_PERSONAL_FINANCE}</title>
			</Helmet>
			<ProductNav className="ProductNav">

				<span className={`ProductNav__title ${theme ? `ProductNav__title--${theme}` : ""}`}>Smarter Personal Finance</span>

				<ul className={`ProductNav__list ${theme ? `ProductNav__list--${theme}` : ""}`}>

					<li onClick={() => { jumpTo('#macro-content') }} className={`ProductNav__item ${macroContentProgress > 0 && macroContentProgress < 100 ? "is-active" : ""}`}>
						<span style={{ textAlign: 'right' }} className="ProductNav__label">Macro<br />Content</span>
						{macroContentProgress > 0 && macroContentProgress < 100 ?
							<ProductNavIndicator Progress={macroContentProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#audience') }} className={`ProductNav__item ${audienceProgress > 0 && audienceProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Audience</span>
						{audienceProgress > 0 && audienceProgress < 100 ?
							<ProductNavIndicator Progress={audienceProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#create') }} className={`ProductNav__item ${createProgress > 0 && createProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Create</span>
						{createProgress > 0 && createProgress < 100 ?
							<ProductNavIndicator Progress={createProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#pay') }} className={`ProductNav__item ${payProgress > 0 && payProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Pay</span>
						{payProgress > 0 && payProgress < 100 ?
							<ProductNavIndicator Progress={payProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#manage') }} className={`ProductNav__item ${manageProgress > 0 && manageProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Manage</span>
						{manageProgress > 0 && manageProgress < 100 ?
							<ProductNavIndicator Progress={manageProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#engage') }} className={`ProductNav__item ${engageProgress > 0 && engageProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Engage</span>
						{engageProgress > 0 && engageProgress < 100 ?
							<ProductNavIndicator Progress={engageProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#thankyou') }} className={`ProductNav__item ${thankyouProgress > 0 && thankyouProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Thank you</span>
						{thankyouProgress > 0 && thankyouProgress < 100 ?
							<ProductNavIndicator Progress={thankyouProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#benefits') }} className={`ProductNav__item ${benefitsProgress > 0 && benefitsProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Next steps</span>
						{benefitsProgress > 0 && benefitsProgress < 100 ?
							<ProductNavIndicator Progress={benefitsProgress} />
							: ""}
					</li>

					<li onClick={() => { jumpTo('#where-next') }} className={`ProductNav__item ${whereNextProgress > 0 && whereNextProgress < 100 ? "is-active" : ""}`}>
						<span className="ProductNav__label">Where next</span>
						{whereNextProgress > 0 && whereNextProgress < 100 ?
							<ProductNavIndicator Progress={whereNextProgress} />
							: ""}
					</li>

				</ul>
			</ProductNav>
		</>
	);
}

export default Navigation;