import React, { useState, useEffect, useRef } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

import { TextInput } from 'Components/Auth/Fields';
import CONFIG from '../../../config';
import Marquee from 'Components/Auth/Marquee';
import ReCAPTCHA from "react-google-recaptcha";
import { appState } from '../../../Stores/App';
import ErrorMessages from '../ErrorMessages';
import { getApiErrorMessage } from '../helper';
import axiosConfig from '../../../Services/axiosConfig';

function Login() {
	const [errors, setErrors] = useState([]);
	const [emailSent, setEmailSent] = useState(false);
	const recaptchaRef = useRef(null);

	const {
		navigation,
		hideNavigation,
	} = appState();

	useEffect(() => {
		if (navigation === true) {
			hideNavigation();
		}
	}, [navigation, hideNavigation]);

	const validationSchema = Yup.object({
		email: Yup.string()
			.email("Invalid email address")
			.required("Required")
	})

	return (

		<>
			<Marquee />
			<div className="auth">
				<div className="authWrapper">
					<div className="formWrapper form-setup">
						<ErrorMessages errors={errors} />

						<Formik
							initialValues={{ username: '', password: '' }}
							validationSchema={validationSchema}
							onSubmit={async (values) => {
								const token = await recaptchaRef.current.executeAsync();
								recaptchaRef.current.reset();
								setErrors([]);

								axiosConfig.post(
									`/identity/users/forgotten-password`,
									{
										email: values.email
									},
									{
										headers: {
											'g-recaptcha-response': token
										}
									}
								)
									.then((response) => {
										let statusCode = response.status;
										switch (statusCode) {
											case 200:
												setEmailSent(true);
												break;
											case 400:
												setErrors(response.data.errorMessages);
												break;
											default:
												console.error('API error')
										}

									})
									.catch((error) => {
										const message = error?.response?.status ? getApiErrorMessage(error.response.status) : 'Unable to confirm account';
										setErrors([`${message}`])
									});
							}
							}
						>
							{({ values, handleChange, handleBlur, handleSubmit }) => (
								<>
									{emailSent ? (
										<>
											<h2 className="form-header">Thank you</h2>
											<p className="form-intro">Please check your inbox- we have just sent you a password reset email with instruction on how to change your password.</p></>
									) : (
										<form onSubmit={handleSubmit} className="form-input__single">
											<h2 className="form-header">Reset password</h2>
											<p className="form-intro">Please confirm your email address</p>
											<div className="form-input__wrapper">
												<TextInput
													type="email"
													value={values.email}
													label="Email"
													placeholder="Email address"
													name="email"
													onChange={handleChange}
													onBlur={handleBlur}
													className="text-field"
												/>
											</div>
											<div className="form__footer btn-center">
												<button type="submit" className="form-btn">
													Reset now
												</button>
											</div>
											<ReCAPTCHA
												ref={recaptchaRef}
												size="invisible"
												sitekey={CONFIG.RECAPTCHA_SITE_KEY}
												onChange={handleSubmit}
											/>
										</form>
									)}
								</>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;