/**

 REVEAL
 ======================

 Expanding content section, toggle more content on and off

*/

import React, { useRef, useState } from 'react';
import TooltipHelper from 'Components/TooltipHelper';

import {
  Revealer,
  RevealTitle,
  RevealContent,
  RevealDivider,
  RevealButton,
  Divider
} from './styles';

function Reveal(props) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { title, preview, content, tooltipID, tooltipTitle, x, y, styles = {} } = props;
  const { title: stylesTitle = {}, content: stylesContent = {} } = styles;

  const toggle = () => {
    setOpen(open => !open);
  }

  return (
    <Revealer className="reveal" ref={ref}>

      { title ?
        <RevealTitle
          minFontSize={stylesTitle.minFontSize}
          maxFontSize={stylesTitle.maxFontSize}
          mb={stylesTitle.mb}
        >
          {title}
        </RevealTitle>
        : ""}

      { preview ?
        <RevealContent
          minFontSize={stylesContent.minFontSize}
          maxFontSize={stylesContent.maxFontSize}
          color={stylesContent.color}
          pt={stylesContent.pt}
          pb={stylesContent.pb}
          width={stylesContent.width}
        >
          {preview} {content && open ? <div dangerouslySetInnerHTML={{ __html: content }} /> : ""}
        </RevealContent>
        : ""}

      { !preview && content && open ?
        <RevealContent
          minFontSize={stylesContent.minFontSize}
          maxFontSize={stylesContent.maxFontSize}
          color={stylesContent.color}
          pt={stylesContent.pt}
          pb={stylesContent.pb}
          width={stylesContent.width}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {tooltipID
            ?
            <TooltipHelper
              id={tooltipID}
              index="4"
              title={tooltipTitle}
              x={x}
              y={y}
              position="bottom"
            />
            :
            null
          }
        </RevealContent>
        : ""}

      <RevealDivider
        onClick={() => { toggle() }}
        width={stylesContent.width}
      >
        <Divider />
        <RevealButton
          isOpen={open}
        >
          {open ? <>Less</> : <>More</>}
        </RevealButton>

      </RevealDivider>

    </Revealer>
  );
}

export default Reveal;